import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

interface BulkOrderConsignInterface {
  bulkConsignmentId?: string
  type?: string
  productCode?: string
}

export const useBulkOrderConsign = () => {
  const mutate = useMutation(
    ({ bulkConsignmentId, type, productCode }: BulkOrderConsignInterface) => {
      return post(`/BulkConsignments/${bulkConsignmentId}/Consign`, {
        type: type,
        productCode: productCode,
      })
    },
  )

  return mutate
}

export default useBulkOrderConsign
