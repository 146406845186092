import { FC } from 'react'

import { ShippingLineSelection } from 'components/molecules/ShippingLineSelection'

export interface TabMachShipProps {
  className?: string | undefined
  shipment: any
  searchForSavedQuotes: any
  selectedOrderId?: any
  notify?: any
  callOrderShipmentsById?: any
  setMainLoadingState?: any
}

export const TabMachShip: FC<TabMachShipProps> = ({
  className,
  shipment,
  searchForSavedQuotes,
  selectedOrderId,
  notify,
  callOrderShipmentsById,
  setMainLoadingState,
}: TabMachShipProps) => {
  return (
    <div className='w-full pt-6 border-t border-t-N-200'>
      {shipment.shipmentConsignmentQuotes &&
        shipment.shipmentConsignmentQuotes.length > 0 &&
        shipment.shipmentConsignmentQuotes.map((quote: any, index: any) => {
          const obj = {
            type: quote.type,
            productName: quote.productName,
            shipmentId: shipment.id,
            productId: quote.productId,
            cost: quote.cost,
            carrierCode: quote?.carrierCode,
            carrierService: quote?.carrierService,
            originCharges: [
              {
                type: 'Base Total',
                price: quote.baseTotal,
              },
              {
                type: 'Fuel Level',
                price: quote.fuelLevy,
              },
              {
                type: 'GST Price',
                price: quote.gstPrice,
              },
              {
                type: 'Total Price',
                price: quote.total,
              },
            ],
            SameDayPickup: quote.sameDayPickupAvailable,
            SameDayPickupCutOffTime: quote.sameDayPickupCutOffTime,
            TransitDescription: quote.transitDescription,
          }
          if (quote.type === 'MACHSHIP') {
            return (
              <ShippingLineSelection
                key={index}
                isViewOnly={
                  shipment?.status === 'CLOSED' ||
                  shipment?.status === 'CANCELLED'
                }
                ShippingLineObject={obj}
                selectedOrderId={selectedOrderId}
                dateWanted={shipment?.dateWanted}
                notify={notify}
                callOrderShipmentsById={callOrderShipmentsById}
                setMainLoadingState={setMainLoadingState}
              />
            )
          }
        })}
      {shipment.shipmentConsignmentQuotes &&
        searchForSavedQuotes(shipment.shipmentConsignmentQuotes, 'MACHSHIP')}
    </div>
  )
}

TabMachShip.defaultProps = {
  className: undefined,
}

export default TabMachShip
