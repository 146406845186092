/* eslint-disable jsx-a11y/no-autofocus */
import { FC } from 'react'
import CN from 'classnames'

export interface InputProps {
  className?: string | undefined
  inputClassName?: string
  isAutoFocus?: boolean
  isDisabled?: boolean
  isError?: boolean
  labelValue?: string | undefined
  onChange?: (e: any) => void | undefined
  onKeyPress?: (e: any) => void | undefined
  placeholder?: string
  type?: string
  value?: any
  isBulkFilter?: boolean
  isRequired?: boolean
}

export const Input: FC<InputProps> = ({
  className,
  inputClassName,
  isAutoFocus,
  isDisabled,
  isError,
  labelValue,
  onChange,
  onKeyPress,
  placeholder,
  type,
  value,
  isBulkFilter,
  isRequired,
}: InputProps) => {
  return (
    <div className={CN('flex flex-col w-full', {}, className)}>
      {labelValue && (
        <span
          className={CN(
            {
              'text-N-700 pb-2': !isError,
              'text-R-500 pb-2': isError,
            },
            {
              'text-sm font-medium': isBulkFilter,
            },
          )}>
          {labelValue}

          {isRequired && <span className='text-R-500 ml-[2px]'>*</span>}
        </span>
      )}
      <input
        placeholder={placeholder}
        type={type}
        autoFocus={isAutoFocus}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        className={CN(
          'w-full outline-none border border-N-200 rounded-md text-sm',
          { 'border-R-200': isError },
          {
            'px-3 py-[6px]': isBulkFilter,
            'py-[9px] px-4': !isBulkFilter,
          },
          inputClassName,
        )}
        disabled={isDisabled}
      />
    </div>
  )
}

Input.defaultProps = {
  className: undefined,
  inputClassName: undefined,
  isAutoFocus: false,
  isDisabled: false,
  isError: false,
  labelValue: undefined,
  onChange: undefined,
  onKeyPress: undefined,
  placeholder: undefined,
  type: 'text',
  value: undefined,
  isBulkFilter: false,
  isRequired: false,
}

export default Input
