/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CN from 'classnames'
import { useGetCutoffTime } from 'framework/api/methods'

import { DatePicker } from 'components/atoms'
import { Button } from 'components/atoms/Button'

export interface PickUpDateModalProps {
  className?: string | undefined
  shipments?: any
  handleConsignConfirmClick?: any
  isActive?: boolean | undefined
  isCustomStyles?: boolean | undefined
  notify?: any
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  pickUpdate?: any
  isLoading?: any
  setPickUpdate?: any
  ShippingLineObject?: any
}

export const PickUpDateModal: FC<PickUpDateModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  handleConsignConfirmClick,
  pickUpdate,
  isLoading,
  setPickUpdate,
  ShippingLineObject,
}: PickUpDateModalProps) => {
  const [cutoffTime, setCutoffTime] = useState<any>()
  const { shippingSummeryWarehouseCode }: any = useSelector<any>(
    (state) => state.order,
  )

  /** API Call for get cutoff time using react-query */
  const { mutate: getCutOffTimeMutate, isLoading: getCutOffTimeIsLoading } =
    useGetCutoffTime()

  /** Process the get cutoff time */
  async function getCutoffTime() {
    getCutOffTimeMutate(
      {
        warehouseCode: shippingSummeryWarehouseCode,
        courierCode: ShippingLineObject?.type,
        subCourierCode:
          ShippingLineObject?.type === 'STARSHIPIT'
            ? ShippingLineObject?.carrierCode
            : null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          successData?.cutoffTime?.status
            ? setCutoffTime(successData?.cutoffTime?.cutofffTime)
            : setCutoffTime(null)
        },
        onError: ({ response: { data: errData } }: any) => {
          setCutoffTime(null)
        },
      },
    )
  }

  useEffect(() => {
    getCutoffTime()
  }, [shippingSummeryWarehouseCode, ShippingLineObject?.type])

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleConfirmClick = (event: any) => {
    event.stopPropagation()
    handleConsignConfirmClick()
  }

  /** set pick up date */
  useEffect(() => {
    const [time, modifier]: any = cutoffTime?.split(' ') || []

    let hours = time?.split(':')[0]
    const minutes = time?.split(':')[1]

    if (hours === '12') {
      hours = '00'
    }

    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12
    }

    const formattedTime = `${hours}:${minutes}`

    /** compare formattedTime and real time and return is greater than or less that  */
    const isCutoffTimePassed = () => {
      const [hours, minutes] = formattedTime.split(':')
      const now = new Date()
      const cutoff = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        parseInt(hours, 10),
        parseInt(minutes, 10),
      )
      return now > cutoff
    }

    /** set default pickup date */
    const defaultPickDate = () => {
      return defaultDate
    }

    /** set default date */
    const defaultDate = new Date()

    if (cutoffTime && isCutoffTimePassed()) {
      if (defaultDate.getDay() === 5) {
        defaultDate.setDate(defaultDate.getDate() + 3)
      } else if (defaultDate.getDay() === 6) {
        defaultDate.setDate(defaultDate.getDate() + 2)
      } else {
        defaultDate.setDate(defaultDate.getDate() + 1)
      }
    } else {
      defaultDate.setDate(defaultDate.getDate())
    }

    setPickUpdate(defaultPickDate())
  }, [cutoffTime, isActive])

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-[10] top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[502px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Select Pickup Date</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>

        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 pl-4 pr-[18px]'>
          <div className='flex w-full items-center bg-N-100 pr-[18px] py-3 pl-4 rounded'>
            <i className='ri-error-warning-fill text-xl text-[#0066FF]'></i>
            <span className='text-N-700 font-normal pl-[14px]'>
              Select a pickup date for the shipment
            </span>
          </div>

          <div className='select-service-type-drop-down flex flex-col pt-8'>
            <span className='text-xs font-medium text-N-700 pb-2'>
              Pick Up Date
            </span>
            <DatePicker
              enabled={true}
              onChange={(e) => setPickUpdate(e.target.value)}
              value={pickUpdate}
              allowEdit={false}
              minDate={new Date()}
              disableWeekends={true}
            />
          </div>
        </div>

        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 px-5 py-4 mt-11'>
          <Button
            onClick={handleConfirmClick}
            isLoading={isLoading}
            disabled={getCutOffTimeIsLoading}>
            Consign
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

PickUpDateModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
  handleConsignConfirmClick: undefined,
  ShippingLineObject: undefined,
}

export default PickUpDateModal
