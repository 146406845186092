import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetCutoffTimeInterface {
  warehouseCode?: string
  courierCode?: string
  subCourierCode?: string | null
}

export const useGetCutoffTime = () => {
  const mutate = useMutation(
    ({
      warehouseCode,
      courierCode,
      subCourierCode,
    }: GetCutoffTimeInterface) => {
      return postAPI('/GetCutoffTime', {
        warehouseCode,
        courierCode,
        subCourierCode,
      })
    },
  )

  return mutate
}

export default useGetCutoffTime
