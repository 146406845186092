import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetOrdersExportCsvInterface {
  from?: string
  principle?: any
  to?: string
}

export const useGetFileVsOrderExportCSV = () => {
  const mutate = useMutation(
    ({ from, principle, to }: GetOrdersExportCsvInterface) => {
      return get(
        `/FileVsOrder/CsvList?&Principle=${
          principle?.value === 'All' ? '' : principle?.value
        }&To=${to}&From=${from}`,
      )
    },
  )

  return mutate
}

export default GetOrdersExportCsvInterface
