export const columns = [
  {
    customWidth: 218,
    Header: () => 'Privilege Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 268,
    Header: () => 'Privilege Description',
    accessor: 'description',
    Cell: ({ row }) => <div>{row.original.description}</div>,
  },
]

export default columns
