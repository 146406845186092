/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useCreatePartner,
  useDeletePartner,
  useDeletePartnerIntegrationById,
  useGetCountryList,
  useGetPartnerById,
  useGetPartnerIntegrationById,
  useGetPartnerTable,
  useGetWarehouseList,
  useUpdateIntegration,
  useUpdatePartner,
} from 'framework/api/methods'

import { Button, PopUpAlert } from 'components/atoms'
import {
  AddPartner,
  PartnerIntegration,
  UpdatePartner,
  ViewPartner,
} from 'components/DrawerModals/Partners'

import { DataTable } from './PartnerDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface PartnersProps {
  [x: string]: any
}

/** This is Partners */

export const Partners: FC<PartnersProps> = ({ className }: PartnersProps) => {
  const [searchKeyword, setSearchKeyword] = useState('')
  const [rows, setRows] = useState({})
  const [countryList, setCountryList] = useState([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [deletePartnerPopOutIsOpen, setDeletePartnerPopOutIsOpen] =
    useState(false)
  const [viewPartnerDrawerIsOpen, setViewPartnerDrawerIsOpen] = useState(false)
  const [addPartnerDrawerIsOpen, setAddPartnerDrawerIsOpen] = useState(false)
  const [updatePartnerDrawerIsOpen, setUpdatePartnerDrawerIsOpen] =
    useState(false)
  const [partnerIntegrationDrawerIsOpen, setPartnerIntegrationDrawerIsOpen] =
    useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    Search: '',
  })

  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })

  /** API Call for Login function using react-query */
  const { mutate: getPartnerTableMutate, isLoading: getPartnerTableIsLoading } =
    useGetPartnerTable()
  const { mutate: deletePartnerMutate } = useDeletePartner()
  const { mutate: getCountryListMutate } = useGetCountryList()

  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'warning') {
      toast.warning(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** Process the Get Country Data List */
  async function getCountryList() {
    getCountryListMutate(
      { id: 1 },
      {
        onSuccess: ({ data: getCountryListData }: any) => {
          const countries = getCountryListData.countries.map((country: any) => {
            return {
              value: country.code,
              label: country.name,
            }
          })
          setCountryList(countries)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** Process the get Partner Table Data */
  async function getPartnerTableList() {
    getPartnerTableMutate(tableVariables, {
      onSuccess: ({ data: successData }: any) => {
        setRows(successData.partners)
        setTotalRowCount(successData.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** Process the Delete Partner */
  async function deletePartner(partnerId: string) {
    deletePartnerMutate(
      { partnerId },
      {
        onSuccess: ({ data: successData }: any) => {
          setViewPartnerDrawerIsOpen(false)
          setDeletePartnerPopOutIsOpen(false)
          notify(successData.message, 'success')
          getPartnerTableList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          setViewPartnerDrawerIsOpen(false)
          setDeletePartnerPopOutIsOpen(false)
        },
      },
    )
  }

  useEffect(() => {
    getCountryList()
  }, [])

  useEffect(() => {
    getPartnerTableList()
  }, [tableVariables])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        Search: searchKeyword,
      })
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Partner List</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            <div className='w-1/2'>
              <Button onClick={() => setAddPartnerDrawerIsOpen(true)}>
                <i className='ri-add-circle-line pr-2'></i>Add New Partner
              </Button>
            </div>
            <div className='flex flex-col w-[400px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  setSearchKeyword(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by Partner code, Partner name'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm mt-2'
              />
              <i
                className='ri-search-2-line absolute top-[15px] left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    Search: searchKeyword,
                  })
                }}></i>
            </div>
          </div>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdatePartnerDrawerIsOpen={setUpdatePartnerDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getPartnerTableIsLoading={getPartnerTableIsLoading}
              setViewPartnerDrawerIsOpen={setViewPartnerDrawerIsOpen}
              setDeletePartnerPopOutIsOpen={setDeletePartnerPopOutIsOpen}
              setPartnerIntegrationDrawerIsOpen={
                setPartnerIntegrationDrawerIsOpen
              }
            />
          </div>
          {updatePartnerDrawerIsOpen && (
            <UpdatePartner
              isActive={updatePartnerDrawerIsOpen}
              onCloseClick={() => setUpdatePartnerDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              useGetPartnerById={useGetPartnerById}
              useUpdatePartner={useUpdatePartner}
              getPartnerTableList={getPartnerTableList}
              notify={notify}
              countryList={countryList}
            />
          )}
          {addPartnerDrawerIsOpen && (
            <AddPartner
              isActive={addPartnerDrawerIsOpen}
              onCloseClick={() => setAddPartnerDrawerIsOpen(false)}
              notify={notify}
              useCreatePartner={useCreatePartner}
              countryList={countryList}
              getPartnerTableList={getPartnerTableList}
            />
          )}
          {viewPartnerDrawerIsOpen && (
            <ViewPartner
              isActive={viewPartnerDrawerIsOpen}
              onCloseClick={() => setViewPartnerDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              setUpdatePartnerDrawerIsOpen={setUpdatePartnerDrawerIsOpen}
              useGetPartnerById={useGetPartnerById}
              setDeletePartnerPopOutIsOpen={setDeletePartnerPopOutIsOpen}
              useGetPartnerIntegrationById={useGetPartnerIntegrationById}
              setPartnerIntegrationDrawerIsOpen={
                setPartnerIntegrationDrawerIsOpen
              }
            />
          )}
          {partnerIntegrationDrawerIsOpen && (
            <PartnerIntegration
              isActive={partnerIntegrationDrawerIsOpen}
              onCloseClick={() => setPartnerIntegrationDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              useGetPartnerIntegrationById={useGetPartnerIntegrationById}
              notify={notify}
              useDeletePartnerIntegrationById={useDeletePartnerIntegrationById}
              useUpdateIntegration={useUpdateIntegration}
              getPartnerTableList={getPartnerTableList}
              useGetWarehouseList={useGetWarehouseList}
            />
          )}

          {deletePartnerPopOutIsOpen && (
            <PopUpAlert
              isActive={deletePartnerPopOutIsOpen}
              title='Delete Partner?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setDeletePartnerPopOutIsOpen(false)}
              onOverlayClick={() => setDeletePartnerPopOutIsOpen(false)}
              onConfirmClick={() => {
                deletePartner(selectedRowByButtonAction.id)
              }}
            />
          )}

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

Partners.defaultProps = {}

export default Partners
