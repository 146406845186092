import { FC, useEffect, useState } from 'react'
import Parcel from 'assets/images/parcel.svg'
import CN from 'classnames'

import { dateToAEST } from 'utils'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export interface StatusTimeLineProps {
  className?: string | undefined
  currentStatus?:
    | 'Received'
    | 'Confirmed'
    | 'Open'
    | 'Tobeconsigned'
    | 'Closed'
    | 'Delivered'
    | 'Failed'
    | 'Cancelled'
    | 'Partial-consigned'

  [x: string]: any
}

export const StatusTimeLine: FC<StatusTimeLineProps> = ({
  currentStatus,
  partnerOrderHistory,
}: StatusTimeLineProps) => {
  const [dateList, setDateList] = useState({
    Received: '',
    Confirmed: '',
    Open: '',
    ToBeConsigned: '',
    Closed: '',
    Delivered: '',
    PartialConsigned: '',
    CancelConsigned: '',
  })

  const containerClassnames = CN(
    'flex items-center mt-10 flex justify-center items-center w-full',
    {
      'mb-[62px]': currentStatus === 'Failed',
      'mb-[64px]': currentStatus !== 'Failed',
    },
  )

  const ReceivedClassnames = {
    '!bg-P-500': currentStatus === 'Received',
    '!bg-G-500': currentStatus === 'Confirmed',
    '!bg-[#CF46FF]': currentStatus === 'Open',
    '!bg-[#14AFF1]': currentStatus === 'Tobeconsigned',
    '!bg-[#64748B]': currentStatus === 'Closed',
    '!bg-P-300': currentStatus === 'Partial-consigned',
  }

  const ConfirmedClassnames = {
    '!bg-G-500': currentStatus === 'Confirmed',
    '!bg-[#CF46FF]': currentStatus === 'Open',
    '!bg-[#14AFF1]': currentStatus === 'Tobeconsigned',
    '!bg-[#64748B]': currentStatus === 'Closed',
    '!bg-P-300': currentStatus === 'Partial-consigned',
  }

  const OpenClassnames = {
    '!bg-[#CF46FF]': currentStatus === 'Open',
    '!bg-[#14AFF1]': currentStatus === 'Tobeconsigned',
    '!bg-[#64748B]': currentStatus === 'Closed',
    '!bg-P-300': currentStatus === 'Partial-consigned',
  }

  const ToBeConsignedClassnames = {
    '!bg-[#14AFF1]': currentStatus === 'Tobeconsigned',
    '!bg-[#64748B]': currentStatus === 'Closed',
    '!bg-P-300': currentStatus === 'Partial-consigned',
  }

  const ConsignedClassnames = {
    '!bg-[#64748B]': currentStatus === 'Closed',
    '!bg-P-300': currentStatus === 'Partial-consigned',
  }

  useEffect(() => {
    const latestReceived = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Received' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const initialConfirmed = partnerOrderHistory?.find(
      (item: any) => capitalizeFirstLetter(item?.status) === 'Confirmed',
    )

    const latestOpen = partnerOrderHistory?.reduce((acc: any, item: any) => {
      if (
        capitalizeFirstLetter(item?.status) === 'Open' &&
        (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
      ) {
        return item
      }
      return acc
    }, null)

    const latestToBeConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Tobeconsigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Closed' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestDelivered = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Delivered' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestPartialConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          capitalizeFirstLetter(item?.status) === 'Partial-consigned' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    const latestCancelConsigned = partnerOrderHistory?.reduce(
      (acc: any, item: any) => {
        if (
          item?.status === 'CANCELLED-CONSIGNED' &&
          (!acc || new Date(item?.updatedOn) > new Date(acc?.updatedOn))
        ) {
          return item
        }
        return acc
      },
      null,
    )

    setDateList({
      Received: latestReceived
        ? dateToAEST(latestReceived?.updatedOn || '', true)
        : '',
      Confirmed: initialConfirmed
        ? dateToAEST(initialConfirmed?.updatedOn || '', true)
        : '',
      Open: latestOpen ? dateToAEST(latestOpen?.updatedOn || '', true) : '',
      ToBeConsigned: latestToBeConsigned
        ? dateToAEST(latestToBeConsigned?.updatedOn || '', true)
        : '',
      Closed: latestConsigned
        ? dateToAEST(latestConsigned?.updatedOn || '', true)
        : '',
      Delivered: latestDelivered
        ? dateToAEST(latestDelivered?.updatedOn || '', true)
        : '',
      PartialConsigned: latestPartialConsigned
        ? dateToAEST(latestPartialConsigned?.updatedOn || '', true)
        : '',
      CancelConsigned: latestCancelConsigned
        ? dateToAEST(latestCancelConsigned?.updatedOn || '', true)
        : '',
    })
  }, [partnerOrderHistory])

  return (
    <div className={containerClassnames}>
      {/* Received Section */}
      <div
        className={CN(
          'relative w-[16px] h-[16px] rounded-full bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10': currentStatus === 'Received',
          },
          ReceivedClassnames,
        )}>
        {currentStatus === 'Received' && <img src={Parcel} alt='Parcel' />}

        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
            {
              'pt-12': currentStatus === 'Received',
              'pt-8': currentStatus !== 'Received',
            },
          )}>
          <div
            className={CN('text-base', {
              'font-bold': currentStatus === 'Received',
            })}>
            Received
          </div>
          <div className='text-xs text-N-500'>{dateList?.Received}</div>
        </div>
      </div>

      {/* Confirmed Section */}
      <div
        className={CN('h-[5px] w-[14%] bg-N-300', ConfirmedClassnames)}></div>

      <div
        className={CN(
          'relative w-[16px] h-[16px] rounded-full bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10': currentStatus === 'Confirmed',
          },
          ConfirmedClassnames,
        )}>
        {currentStatus === 'Confirmed' && <img src={Parcel} alt='Parcel' />}

        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
            {
              'pt-11': currentStatus === 'Confirmed',
              'pt-8': currentStatus !== 'Confirmed',
            },
          )}>
          <div
            className={CN('text-base', {
              'font-bold': currentStatus === 'Confirmed',
            })}>
            Confirmed
          </div>
          <div className='text-xs text-N-500'>{dateList?.Confirmed}</div>
        </div>
      </div>

      {/* Open Section */}
      <div className={CN('h-[5px] w-[14%] bg-N-300', OpenClassnames)}></div>

      <div
        className={CN(
          'w-[16px] h-[16px] rounded-full relative bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10': currentStatus === 'Open',
          },
          OpenClassnames,
        )}>
        {currentStatus === 'Open' && <img src={Parcel} alt='Parcel' />}
        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
            {
              'pt-11': currentStatus === 'Open',
              'pt-8': currentStatus !== 'Open',
            },
          )}>
          <div
            className={CN('text-base', {
              'font-bold': currentStatus === 'Open',
            })}>
            Open
          </div>
          <div className='text-xs text-N-500'>{dateList?.Open}</div>
        </div>
      </div>

      {/* In Transit Section */}
      <div
        className={CN(
          'h-[5px] w-[14%] bg-N-300',
          ToBeConsignedClassnames,
        )}></div>

      <div
        className={CN(
          'w-[16px] h-[16px] rounded-full relative bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10': currentStatus === 'Tobeconsigned',
          },
          ToBeConsignedClassnames,
        )}>
        {currentStatus === 'Tobeconsigned' && <img src={Parcel} alt='Parcel' />}
        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
            {
              'pt-11': currentStatus === 'Tobeconsigned',
              'pt-8': currentStatus !== 'Tobeconsigned',
            },
          )}>
          <div
            className={CN('text-base text-center', {
              'font-bold': currentStatus === 'Tobeconsigned',
            })}>
            To be Consigned
          </div>
          <div className='text-xs text-N-500'>{dateList?.ToBeConsigned}</div>
        </div>
      </div>

      {/* Closed Section */}
      <div
        className={CN('h-[5px] w-[14%] bg-N-300', ConsignedClassnames)}></div>

      <div
        className={CN(
          'w-[16px] h-[16px] rounded-full relative bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10':
              currentStatus === 'Closed' ||
              currentStatus === 'Partial-consigned',
          },
          ConsignedClassnames,
        )}>
        {(currentStatus === 'Closed' ||
          currentStatus === 'Partial-consigned') && (
          <img src={Parcel} alt='Parcel' />
        )}
        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[160px]',
            {
              'pt-11':
                currentStatus === 'Closed' ||
                currentStatus === 'Partial-consigned',
              'pt-8':
                currentStatus !== 'Closed' &&
                currentStatus !== 'Partial-consigned',
            },
          )}>
          <div
            className={CN('text-base', {
              'font-bold': currentStatus === 'Closed',
            })}>
            {currentStatus == 'Partial-consigned'
              ? 'Partial Consigned'
              : 'Closed'}
          </div>
          <div className='text-xs text-N-500'>
            {currentStatus == 'Closed' && dateList?.Closed}
            {currentStatus == 'Partial-consigned' && dateList?.PartialConsigned}
            {currentStatus == 'Confirmed' && dateList?.CancelConsigned}
          </div>
        </div>
      </div>

      {/* Delivered Section */}
      <div className={CN('h-[5px] w-[14%] bg-N-300')}></div>

      <div
        className={CN(
          'w-[16px] h-[16px] rounded-full relative bg-N-300 flex justify-center items-center',
          {
            'w-10 h-10': currentStatus === 'Delivered',
          },
        )}>
        {currentStatus === 'Delivered' && <img src={Parcel} alt='Parcel' />}
        <div
          className={CN(
            'absolute flex flex-col justify-start items-center top-0 bottom-0 w-[120px]',
            {
              'pt-11': currentStatus === 'Delivered',
              'pt-8': currentStatus !== 'Delivered',
            },
          )}>
          <div
            className={CN('text-base', {
              'font-bold': currentStatus === 'Delivered',
            })}>
            Delivered
          </div>
          {/* <div className='text-xs text-N-500'>{dateList?.Delivered}</div> */}
        </div>
      </div>
    </div>
  )
}

StatusTimeLine.defaultProps = {
  className: undefined,
  currentStatus: undefined,
}

export default StatusTimeLine
