import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetInvoiceTableInterface {
  take?: number
  skip?: number
  search?: string
  partner?: any
  month?: any
  year?: any
  invoiceStatus?: any
  dueDateFrom?: any
  dueDateTo?: any
  dateRaisedFrom?: any
  dateRaisedTo?: any
}
export const useGetInvoiceTable = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      search,
      partner,
      year,
      month,
      invoiceStatus,
      dueDateFrom,
      dueDateTo,
      dateRaisedFrom,
      dateRaisedTo,
    }: GetInvoiceTableInterface) => {
      return get(
        `/InvoiceList?take=${take}&skip=${skip}&search=${search}&partner=${
          partner === '' ? '' : partner
        }&month=${month === 'All' || month === '' ? '' : month}&year=${
          year === 'All' || year === '' ? '' : year
        }&invoiceStatus=${
          invoiceStatus === '' ? '' : invoiceStatus
        }&dueDateFrom=${dueDateFrom}&dueDateTo=${dueDateTo}&dateRaisedFrom=${dateRaisedFrom}&dateRaisedTo=${dateRaisedTo}`,
      )
    },
  )
  return mutate
}
export default useGetInvoiceTable
