import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateStockOrderStatusCancelInterface {
  stockOrderId?: string
}

export const useUpdateStockOrderStatusCancel = () => {
  const mutate = useMutation(
    ({ stockOrderId }: UpdateStockOrderStatusCancelInterface) => {
      return postAPI(`/StockOrder/${stockOrderId}/Cancel`, {})
    },
  )

  return mutate
}

export default useUpdateStockOrderStatusCancel
