/* eslint-disable no-undef */
import moment from 'moment-timezone'

import { Badge } from 'components/atoms/Badge'
import { dateAndTimeInToReadableTimeOnlyFormat } from 'utils'

const formatDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // +1 because months are 0-indexed
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}
const getSydneyDate = () => {
  // eslint-disable-next-line no-undef
  return moment().tz('Australia/Sydney')
}
export const columns = [
  {
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (
    // ),
  },
  {
    Header: () => 'Partner',
    accessor: 'partnerCode',
    Cell: ({ row }) => <div>{row?.original?.partnerCode}</div>,
  },
  {
    Header: () => 'Year',
    accessor: 'yaer',
    Cell: ({ row }) => <div>{row?.original?.year}</div>,
  },
  {
    Header: () => 'Month',
    accessor: 'month',
    Cell: ({ row }) => <div>{row?.original?.month}</div>,
  },
  {
    Header: () => 'Invoice Id',
    accessor: 'invoiceId',
    Cell: ({ row }) => <div>{row?.original?.invoiceId}</div>,
  },
  {
    Header: () => 'Title',
    accessor: 'title',
    Cell: ({ row }) => <div>{row?.original?.title}</div>,
  },
  {
    Header: () => 'Amount',
    accessor: 'amount',
    Cell: ({ row }) => <div>{row?.original?.amount}</div>,
  },
  {
    Header: () => 'Date Raised',
    accessor: 'dateRaised',
    Cell: ({ row }) => <div>{formatDate(row?.original?.dateRaised)}</div>,
  },
  {
    Header: () => 'Invoice DueDate',
    accessor: 'dueDate',
    Cell: ({ row }) => <div>{formatDate(row?.original?.dueDate)}</div>,
  },
  {
    Header: () => 'Type',
    accessor: 'type',
    Cell: ({ row }) => <div>{row.original.type}</div>,
  },
  {
    Header: () => 'Invoice Status',
    accessor: 'invoiceStatus',
    Cell: ({ row }) => {
      const sydneyDate = getSydneyDate().startOf('day') // Set Sydney date to start of the day
      const due = moment(row.original.dueDate).startOf('day') // Set dueDate to start of the day
      const isOverdue = due.isBefore(sydneyDate) // Check if dueDate is before today's Sydney date
      const setColor = row.original.isPaid
        ? 'green'
        : isOverdue
        ? 'red'
        : 'orange'
      const invoiceStatus = row.original.isPaid
        ? 'Paid'
        : isOverdue
        ? 'Overdue'
        : 'Due'
      return <div style={{ color: setColor }}>{invoiceStatus}</div> // Set Sydney date to start of the day
    },
  },
  {
    Header: () => 'Status',
    accessor: 'status',
    Cell: ({ row }) => (
      <div>
        {row.original.status ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]
export default columns
