export const Couriers = [
  {
    value: 'ALLIED',
    label: 'Allied Express',
  },
  {
    value: 'AUSPOST',
    label: 'Australia Post',
  },
  {
    value: 'AUSPOST_INTERNATIONAL',
    label: 'Australia Post International',
  },
  {
    value: 'DIRECT',
    label: 'Direct Express',
  },
  {
    value: 'DSE',
    label: 'DSE',
  },
  {
    value: 'SMART/SEND',
    label: 'Smart Send',
  },
  {
    value: 'STARSHIPIT',
    label: 'Starshipit',
  },
]
