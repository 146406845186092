import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface GetOrdersExportCsvInterface {
  status?: any
  pId?: string
  starDate?: string
  endDate?: string
  isInternational?: boolean
  searchKeyword?: string
}

export const useGetOrdersExportCSV = () => {
  const mutate = useMutation(
    ({
      status,
      pId,
      starDate,
      endDate,
      isInternational,
      searchKeyword,
    }: GetOrdersExportCsvInterface) => {
      return postAPI(`/PartnerOrders/CsvList?From=${starDate}&To=${endDate}`, {
        SearchKeyword: searchKeyword === '' ? null : searchKeyword,
        Status: status[0] === 'ALL' ? null : status,
        PartnerId: pId === undefined || pId === '' ? null : pId,
        isInternational,
      })
    },
  )

  return mutate
}

export default GetOrdersExportCsvInterface
