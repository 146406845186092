export const dateAndTImeToReadableString = (incomingDate: any) => {
  const date = new Date(incomingDate)
  const dateString =
    ('0' + date.getDate()).slice(-2) +
    '/' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    date.getFullYear()
  const timeString =
    ('0' + date.getHours()).slice(-2) +
    ':' +
    ('0' + date.getMinutes()).slice(-2) +
    (date.getHours() < 12 ? 'AM' : 'PM')
  return dateString + ' ' + timeString
}
export default dateAndTImeToReadableString
