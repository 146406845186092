import alliedExpressLogo from 'assets/images/alliedExpressLogo.png'
import ausPostInternationalLogo from 'assets/images/ausPostInternationalLogo.png'
import ausPostLogo from 'assets/images/ausPostLogo.png'
import directExpressLogo from 'assets/images/directExpressLogo.png'
import dseLogo from 'assets/images/dseLogo.png'
import smartEndLogo from 'assets/images/smartEndLogo.png'
import starshipitLogo from 'assets/images/starshipitLogo.svg'

import { Badge } from 'components/atoms/Badge'
import { dateToAEST, timeIntoDateTimeFormat } from 'utils'

export const columns = [
  {
    customWidth: 70,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 130,
    Header: () => 'Warehouse Code',
    accessor: 'warehouseCode',
    Cell: ({ row }) => <div>{row?.original?.warehouseCode}</div>,
  },
  {
    customWidth: 220,
    Header: () => 'Warehouse Name',
    accessor: 'warehouseName',
    Cell: ({ row }) => <div>{row?.original?.warehouseName}</div>,
  },
  {
    customWidth: 200,
    Header: () => 'Courier Name',
    accessor: 'courierName',
    Cell: ({ row }) => (
      <div className='flex gap-x-2 items-center'>
        {row?.original?.courierCode === 'AUSPOST' && (
          <img
            src={ausPostLogo}
            alt='australia-post-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'DIRECT' && (
          <img
            src={directExpressLogo}
            alt='direct-post-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'SMART/SEND' && (
          <img
            src={smartEndLogo}
            alt='smart-end-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'STARSHIPIT' && (
          <img
            src={starshipitLogo}
            alt='star-ship-it-aus-post'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'AUSPOST_INTERNATIONAL' && (
          <img
            src={ausPostInternationalLogo}
            alt='australia-post-international-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'DSE' && (
          <img
            src={dseLogo}
            alt='dse-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierCode === 'ALLIED' && (
          <img
            src={alliedExpressLogo}
            alt='allied-express-icon'
            className='h-6 w-6 object-contain'
          />
        )}

        {row?.original?.courierName === 'Starshipit'
          ? row?.original?.courierName + ' - ' + row?.original?.subCourierName
          : row?.original?.courierName}
      </div>
    ),
  },
  {
    id: 'cutofffTime',
    customWidth: 125,
    Header: () => 'Cut-off Time',
    accessor: (row) => {
      return timeIntoDateTimeFormat(row?.cutofffTime)
    },
    Cell: ({ row }) => <div>{row?.original?.cutofffTime}</div>,
  },
  {
    id: 'status',
    customWidth: 100,
    Header: () => 'Status',
    accessor: (row) => {
      return row?.status ? 'ACTIVE' : 'INACTIVE'
    },
    Cell: ({ row }) => (
      <div>
        {row.original.status ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
  {
    customWidth: 165,
    Header: 'Created on',
    accessor: 'createdOn',
    Cell: ({ row }) => (
      <div className='flex items-center flex-wrap'>
        {dateToAEST(row?.original?.createdOn)}
      </div>
    ),
  },
]

export default columns
