import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

const userAdd = async (reqBody: any) => {
  const res = await post('/UserProfile', reqBody)
  return res.data
}

export const useUserAdd = (onSettled: any, onError: any) => {
  return useMutation(userAdd, {
    onSettled,
    onError,
  })
}
