import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

/* Get all sample stock on hand */
export const getStockOnHandList = async (queryData: any) => {
  const { skip, take, Search } = queryData.queryKey[1]

  const res = await get(
    `StockOnHand?take=${take}&skip=${skip}&Search=${Search}`,
  )

  return res.data
}

/* Use Query as a Hook */
export const useGetStockOnHandList = (queryData: any) => {
  return useQuery(['stockOnHand', queryData], getStockOnHandList, {
    refetchOnWindowFocus: true,
  })
}
