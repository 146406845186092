import { FC } from 'react'
import Select from 'react-select'

export interface MultiSelectProps {
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  options?: any
  label?: string | undefined
  placeholder?: string | undefined
  [x: string]: any
  value?: any
  defaultValue?: any
  disabled?: boolean
  isError?: boolean
  size?: 'sm' | 'md'
  isMulti?: boolean
}

export const MultiSelect: FC<MultiSelectProps> = ({
  options,
  onChange,
  onBlur,
  value,
  defaultValue,
  placeholder,
  restProps,
  disabled,
  isError,
  size,
  isMulti,
}: MultiSelectProps) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      // height: size == 'md' ? 40 : 30,
      fontSize: size == 'md' ? 16 : 14,
      borderRadius: 6,
      border: isError && '1px solid red',
    }),
    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        color: isSelected ? 'black' : isDisabled ? 'gray' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
      }
    },
  }
  return (
    <Select
      styles={customStyles}
      options={options}
      className='sd-classes-select'
      placeholder={placeholder}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: 'red',
          primary25: '#F1F5F9', // option Hover colour
          neutral80: '#334155', // typing Inside the select
          // neutral60: '#334155', // Arrow inside the select
          // neutral40: 'red', // No Option Colour
          neutral20: '#E2E8F0', // Outline colour
          // neutral0: 'red', // Background colour
          primary75: 'red',
          primary: '#E2E8F0',
          neutral90: 'red',
        },
      })}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      defaultValue={defaultValue}
      isDisabled={disabled}
      isMulti={isMulti}
      isOptionDisabled={(option: any) => option.disabled}
      {...restProps}
    />
  )
}

MultiSelect.defaultProps = {
  className: undefined,
  options: undefined,
  onChange: undefined,
  onBlur: undefined,
  label: undefined,
  value: undefined,
  defaultValue: undefined,
  disabled: false,
  placeholder: 'select...',
  isError: false,
  size: 'md',
  isMulti: false,
}

export default MultiSelect
