import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface gtIcsWMSWarehouseIntegrationByIdInterface {
  id?: string
}

export const useGetIcsWMSWarehouseIntegrationById = () => {
  const mutate = useMutation(
    ({ id }: gtIcsWMSWarehouseIntegrationByIdInterface) => {
      return get(`/Warehouse/${id}/IcsWms`)
    },
  )

  return mutate
}

export default useGetIcsWMSWarehouseIntegrationById
