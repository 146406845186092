import React, { FC } from 'react'
import CN from 'classnames'

import dateToReadableString from 'utils/dateToReadableString'

export interface ShippingDetailViewSectionProps {
  [x: string]: any
}

/** This is ShippingDetailViewSection */

export const ShippingDetailViewSection: FC<ShippingDetailViewSectionProps> = ({
  orderDetails,
}: ShippingDetailViewSectionProps) => {
  const ViewSection = (
    heading: string,
    value: string,
    viewAlignment: string,
  ) => {
    return (
      <div
        className={CN('flex flex-col', {
          'w-1/2 pr-2': viewAlignment === 'left',
          'w-1/2 pl-2': viewAlignment === 'right',
          'w-full': viewAlignment === 'full',
        })}>
        <span className='text-N-500 text-sm'>{heading}</span>
        <span className='text-N-700 font-semibold pt-[2px]'>{value}</span>
      </div>
    )
  }

  return (
    <div className='flex w-full flex-col px-4 pt-[10px] mb-7'>
      <div className='flex w-full'>
        {ViewSection('Warehouse:', `${orderDetails?.warehouseCode}`, 'left')}
        {ViewSection(
          'Customer Reference',
          `${orderDetails?.customerReferance || '-'}`,
          'right',
        )}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'Supplier Invoice Number',
          orderDetails?.invoiceNo,
          'left',
        )}
        {ViewSection('Job Reference', orderDetails?.jobReference, 'right')}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'ETA',
          `${
            orderDetails?.eta
              ? dateToReadableString(orderDetails?.eta || '')
              : '-'
          }`,
          'left',
        )}
        {ViewSection(
          'Receipt Date',
          `${
            orderDetails?.receiptDate
              ? dateToReadableString(orderDetails?.receiptDate || '')
              : '-'
          }`,
          'right',
        )}
      </div>
      <div className='flex w-full pt-4'>
        {ViewSection(
          'Order Notes',
          `${orderDetails?.stockNote || '-'}`,
          'left',
        )}
        {ViewSection(
          'Instructions',
          `${orderDetails?.instructions || '-'}`,
          'right',
        )}
      </div>
    </div>
  )
}

ShippingDetailViewSection.defaultProps = {}

export default ShippingDetailViewSection
