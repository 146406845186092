/* eslint-disable no-param-reassign */
import React, { forwardRef, MutableRefObject, useEffect, useRef } from 'react'
import CN from 'classnames'

import './CheckBox.scss'

interface CheckboxProps {
  checked?: boolean
  children?: any
  className?: string | undefined
  id?: string | undefined
  indeterminate?: boolean
  isCustomStyles?: boolean
  onChange?: any
  type?: 'checkbox' | undefined
  disabled?: boolean
}

const useCombinedRefs = (...refs: any[]): MutableRefObject<any> => {
  const targetRef = useRef()

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

export const CheckBox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      checked,
      children,
      className,
      id,
      indeterminate,
      isCustomStyles,
      onChange,
      type,
      disabled,
      ...rest
    }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const CheckboxClasses = !isCustomStyles
      ? CN(
          'checkbox',
          className,
          {
            'checkbox--is-indeterminate': indeterminate,
          },
          {
            'checkbox--disabled': disabled,
          },
        )
      : className

    const defaultRef = React.useRef(null)
    const combinedRef = useCombinedRefs(ref, defaultRef)

    useEffect(() => {
      if (combinedRef?.current) {
        combinedRef.current.indeterminate = indeterminate || false
      }
    }, [combinedRef, indeterminate])

    return (
      <label className={CheckboxClasses} htmlFor={id}>
        <input
          id={id}
          type={type}
          ref={combinedRef}
          onChange={(e) => {
            !disabled && onChange(e)
          }}
          checked={checked}
          {...rest}
          className={CN({
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          })}
        />
        <span className='checkbox__checkmark' />
        {children && <span className='checkbox__label'>{children}</span>}
      </label>
    )
  },
)

export default CheckBox

CheckBox.defaultProps = {
  checked: false,
  children: null,
  className: undefined,
  id: undefined,
  indeterminate: false,
  isCustomStyles: false,
  onChange: undefined,
  type: 'checkbox',
  disabled: false,
}
