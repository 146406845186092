import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getFileVsOrderListInterface {
  take?: number
  skip?: number
  search?: string
  principle?: any
  from?: string
  to?: string
}
export const useGetFileVsCustomerOrderList = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      search,
      principle,
      to,
      from,
    }: getFileVsOrderListInterface) => {
      return get(
        `/FileVsCustomerOrder?Take=${take}&Skip=${skip}&Search=${search}&Principle=${
          principle?.value === 'All' ? '' : principle?.value
        }&To=${to}&From=${from}`,
      )
    },
  )
  return mutate
}
export default useGetFileVsCustomerOrderList
