/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { Logo } from 'components/atoms/Logo'
import { PopOut } from 'components/atoms/PopOut'

export interface LoginFormProps {
  [x: string]: any
}

export const LoginForm: FC<LoginFormProps> = ({
  handleLogInSubmit,
  loginError,
  loginIsLoading,
  setLogInComponent,
  setUserData,
  userData,
  ...restProps
}: LoginFormProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isLoginButtonDisable, setIsLoginButtonDisable] = useState(true)

  /** Handling LogIn Button status */
  useEffect(() => {
    userData.password && userData.username
      ? setIsLoginButtonDisable(false)
      : setIsLoginButtonDisable(true)
  }, [userData])

  return (
    <div
      className='w-[520px] bg-white py-10 px-9 rounded-[20px] shadow-2xl'
      {...restProps}>
      <div className='w-full h-full'>
        <Logo />

        <div className='flex flex-col w-full pt-[34px]'>
          <span className='text-3xl text-N-700 font-bold'>Welcome</span>
          <span className='text-gray-800 pt-2'>
            Please login to your account to get started.
          </span>
        </div>

        <div className='pt-8'>
          <label
            htmlFor='email'
            className='text-base text-N-900 bg-white font-semibold'>
            Username
          </label>
          <input
            type='email'
            id='email'
            value={userData.username}
            onChange={(e) => {
              setUserData({ ...userData, username: e.target.value })
            }}
            className={CN(
              'mt-4 text-N-900 text-sm rounded-[10px] w-full p-[13px] outline-none focus:ring-1 focus:ring-P-500',
              {
                'ring-1 ring-P-500 bg-white': userData?.username.length > 0,
                'bg-N-100':
                  userData?.username?.length === 0 ||
                  userData?.username?.length === undefined,
              },
            )}
            placeholder='name@bdl.com'
            required
          />
        </div>

        <div className='mt-6 relative'>
          <label
            htmlFor='password'
            className='text-base text-N-900 bg-white font-semibold'>
            Password
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            id='password'
            value={userData.password}
            onChange={(e) => {
              setUserData({ ...userData, password: e.target.value })
            }}
            className={CN(
              'z-10 mt-4 text-N-900 text-sm rounded-[10px] w-full p-[13px] outline-none focus:ring-1 focus:ring-P-500',
              {
                'ring-1 ring-P-500 bg-white': userData?.password.length > 0,
                'bg-N-100':
                  userData?.password?.length === 0 ||
                  userData?.password?.length === undefined,
              },
            )}
            placeholder='Password'
            required
          />
          <i
            className={
              showPassword
                ? 'ri-eye-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
                : 'ri-eye-off-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
            }
            onClick={() => {
              setShowPassword(!showPassword)
            }}></i>
        </div>

        {loginError?.isError && (
          <PopOut
            className='mt-6'
            size='small'
            isShowCloseButton={false}
            appearance='error'>
            {loginError.message}
          </PopOut>
        )}

        <div className='flex pt-7 items-center justify-end'>
          <p
            onClick={() => {
              setLogInComponent('ForgotPassword')
            }}
            className='text-P-700 text-sm cursor-pointer'>
            Forgot Password?
          </p>
        </div>

        <div className='pt-7'>
          <Button
            onClick={(e: any) => {
              handleLogInSubmit(e)
            }}
            disabled={loginIsLoading || isLoginButtonDisable}
            isLoading={loginIsLoading}
            size='lg'
            isFullWidth>
            Login
          </Button>
        </div>
      </div>
    </div>
  )
}

LoginForm.defaultProps = {}

export default LoginForm
