import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateShipmentQuotesInterface {
  wereHouseOrderId?: string
  shipmentId?: string
  quoteType?: any
  WhCode?: any
}

export const useCreateShipmentQuotes = () => {
  const mutate = useMutation(
    ({
      wereHouseOrderId,
      shipmentId,
      quoteType,
      WhCode,
    }: CreateShipmentQuotesInterface) => {
      let apiQuery = ''
      if (quoteType === 'ALLIED') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/ALLIED`
      } else if (quoteType === 'AUSPOST') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/AusPost`
      } else if (quoteType === 'AUSPOST_INTERNATIONAL') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/AusPostInternational`
      } else if (quoteType === 'DIRECT') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/DirectExpress`
      } else if (quoteType === 'MACHSHIP') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/MachShip/${WhCode}`
      } else if (quoteType === 'SMART/SEND') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/SmartSend`
      } else if (quoteType === 'STARSHIPIT') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/StarShipit`
      } else if (quoteType === 'All Services') {
        apiQuery = `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentQuotes/All`
      }

      return postAPI(apiQuery || '', {})
    },
  )

  return mutate
}

export default useCreateShipmentQuotes
