import { FC } from 'react'

import { Button } from 'components/atoms/Button'

export interface FooterWithPaginationProps {
  [x: string]: any
}

/** This is OrderFooter */

export const FooterWithPagination: FC<FooterWithPaginationProps> = ({
  partnerOrderVariables,
  setPageNumber,
  setPartnerOrderVariables,
  paginationVariables,
  totalRowCount,
  pageNumber,
}: FooterWithPaginationProps) => {
  return (
    <div className='flex w-full items-center justify-between px-3 py-[10px] rounded-b-lg border-t border-t-N-100'>
      <div className='flex items-center w-[120px]'>
        <span className='text-N-700 text-sm'>Records</span>
        <select
          className='ml-2 outline-none border border-N-200 rounded-md w-20 h-7'
          value={partnerOrderVariables.take}
          onChange={(e) => {
            setPageNumber(1)
            setPartnerOrderVariables({
              ...partnerOrderVariables,
              take: e.target.value,
            })
          }}>
          {[10, 20, 30, 50].map((pageSizeValue) => (
            <option key={pageSizeValue} value={pageSizeValue}>
              {pageSizeValue}
            </option>
          ))}
        </select>
      </div>

      <div className='flex items-center'>
        <div className='flex items-center text-N-700 text-sm mr-5'>
          <p>
            {paginationVariables.start} - {paginationVariables.end} of{' '}
            {totalRowCount}
          </p>
        </div>
        <div>
          <Button
            className='mr-1'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            disabled={pageNumber <= 1}>
            <i className='ri-arrow-left-s-line' />
          </Button>
        </div>
        <div>
          <Button
            className='mr-2'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            disabled={
              Number(pageNumber) * Number(partnerOrderVariables.take) >=
              Number(totalRowCount)
            }>
            <i className='ri-arrow-right-s-line' />
          </Button>
        </div>
      </div>
    </div>
  )
}

FooterWithPagination.defaultProps = {}

export default FooterWithPagination
