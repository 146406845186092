import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateCutoffTimeInterface {
  id?: string
  warehouseCode?: string
  warehouseName?: string
  courierCode?: string
  courierName?: string
  cutofffTime?: string
  status?: boolean
  subCourierName?: string
  subCourierCode?: string
}

export const useUpdateCutoffTime = () => {
  const mutate = useMutation(
    ({
      id,
      warehouseCode,
      warehouseName,
      courierCode,
      courierName,
      cutofffTime,
      status,
      subCourierName,
      subCourierCode,
    }: UpdateCutoffTimeInterface) => {
      return putAPI(`/CutoffTime`, {
        id,
        warehouseCode,
        warehouseName: warehouseName?.split('- ')[1],
        courierCode,
        courierName,
        cutofffTime,
        status,
        subCourierName,
        subCourierCode,
      })
    },
  )

  return mutate
}

export default useUpdateCutoffTime
