import { Badge } from 'components/atoms/Badge'

export const columns = [
  {
    customWidth: 80,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 94,
    Header: () => 'Code',
    accessor: 'code',
    Cell: ({ row }) => <div>{row?.original?.code}</div>,
  },
  {
    customWidth: 240,
    Header: () => 'Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row?.original?.name}</div>,
  },
  {
    customWidth: 230,
    Header: () => 'Address',
    accessor: 'address',
    Cell: ({ row }) => (
      <div>
        {row?.original?.address?.lineOne ||
        row?.original?.address?.suburb ||
        row?.original?.address?.state ||
        row?.original?.address?.postCode ||
        row?.original?.address?.country
          ? `${
              row?.original?.address?.lineOne &&
              row?.original?.address?.lineOne + ', '
            } ${
              row?.original?.address?.suburb &&
              row?.original?.address?.suburb + ', '
            }
            ${
              row?.original?.address?.state &&
              row?.original?.address?.state + ', '
            }
            ${
              row?.original?.address?.postCode &&
              row?.original?.address?.postCode + ', '
            }
            ${
              row?.original?.address?.country &&
              row?.original?.address?.country + '.'
            }`
          : `-`}
      </div>
    ),
  },
  {
    customWidth: 185,
    Header: () => 'Phone No',
    accessor: 'phoneNo',
    Cell: ({ row }) => <div>{row?.original?.phoneNo}</div>,
  },
  {
    customWidth: 125,
    Header: 'Integration',
    accessor: 'warehouseIntegration',
    Cell: ({ row }) => (
      <div className='flex items-center flex-wrap'>
        {/* {row.original.warehouseIntegration.map((integration) => {
          return `${integration.name}${
            row.original.warehouseIntegration?.length > 1 && `,`
          }`
        })} */}
        {row?.original?.warehouseIntegration?.name}
      </div>
    ),
  },
  {
    id: 'isActive',
    customWidth: 100,
    Header: () => 'Status',
    accessor: (row) => {
      return row?.isActive ? 'ACTIVE' : 'INACTIVE'
    },
    Cell: ({ row }) => (
      <div>
        {row.original.isActive ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]

export default columns
