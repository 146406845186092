import { FC } from 'react'

import { Input } from 'components/atoms/Input'
import { TextArea } from 'components/atoms/TextArea'

export interface ShippingDetailEditSectionProps {
  [x: string]: any
}

/** This is ShippingDetailViewSection */

export const ShippingDetailEditSection: FC<ShippingDetailEditSectionProps> = ({
  editViewShippingDetails,
  setEditViewShippingDetails,
}: ShippingDetailEditSectionProps) => {
  return (
    <div className='flex w-full flex-col px-4 pt-[10px] mb-7'>
      <div className='flex w-full items-center'>
        <Input
          className='pr-2'
          labelValue='Customer First Name:'
          type='text'
          value={editViewShippingDetails.customerFirstName}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerFirstName: e.target.value,
            })
          }}
        />

        <Input
          className='pl-2'
          labelValue='Customer Last Name:'
          type='text'
          value={editViewShippingDetails.customerLastName}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerLastName: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <Input
          className='pr-2'
          labelValue='Customer Email:'
          type='text'
          value={editViewShippingDetails.customerEmail}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerEmail: e.target.value,
            })
          }}
        />

        <Input
          className='pl-2'
          labelValue='Customer Phone:'
          type='text'
          value={editViewShippingDetails.customerPhoneNo}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerPhoneNo: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-8'>
        <Input
          labelValue='Address Line 01:'
          type='text'
          value={editViewShippingDetails.customerAddressLine01}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerAddressLine01: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <Input
          labelValue='Address Line 02:'
          type='text'
          value={editViewShippingDetails.customerAddressLine02}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerAddressLine02: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <Input
          className='w-1/2 pr-2'
          labelValue='Suburb:'
          type='text'
          value={editViewShippingDetails.customerSuburb}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerSuburb: e.target.value,
            })
          }}
        />

        <div className='flex w-1/2 items-center'>
          <Input
            className='w-3/5 pl-2 pr-2'
            labelValue='State:'
            type='text'
            value={editViewShippingDetails.customerState}
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                customerState: e.target.value,
              })
            }}
          />

          <Input
            labelValue='State Code:'
            className='w-2/5 pl-2'
            type='text'
            value={editViewShippingDetails.customerStateCode}
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                customerStateCode: e.target.value,
              })
            }}
          />
        </div>
      </div>
      <div className='flex w-full items-center pt-4'>
        <Input
          className='w-1/2 pr-2'
          labelValue='Country:'
          type='text'
          value={editViewShippingDetails.customerCountry}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerCountry: e.target.value,
            })
          }}
        />

        <div className='flex w-1/2 items-center'>
          <Input
            className='w-3/5 pl-2 pr-2'
            labelValue='Country Code:'
            type='text'
            value={editViewShippingDetails.customerCountryCode}
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                customerCountryCode: e.target.value,
              })
            }}
          />
          <Input
            className='w-2/5 pl-2 pr-2'
            labelValue='Postal Code:'
            type='text'
            value={editViewShippingDetails.customerPostalCode}
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                customerPostalCode: e.target.value,
              })
            }}
          />
        </div>
      </div>

      <div className='flex w-full items-center pt-4'>
        <TextArea
          labelValue='Customer Notes:'
          rows={3}
          value={editViewShippingDetails.shippingNote}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              shippingNote: e.target.value,
            })
          }}
          isDisabled={true}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <TextArea
          labelValue='Internal Notes:'
          rows={3}
          value={editViewShippingDetails.warehouseNote}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              warehouseNote: e.target.value,
            })
          }}
        />
      </div>
    </div>
  )
}

ShippingDetailEditSection.defaultProps = {}

export default ShippingDetailEditSection
