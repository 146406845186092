import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateShipmentInterface {
  wereHouseOrderId?: string
  shipmentLines?: any
}

export const useCreateShipment = () => {
  const mutate = useMutation(
    ({ shipmentLines, wereHouseOrderId }: CreateShipmentInterface) => {
      return postAPI(`/WarehouseOrder/${wereHouseOrderId}/Shipments`, {
        shipmentLines: shipmentLines,
      })
    },
  )

  return mutate
}

export default useCreateShipment
