import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UseLoginInterface {
  resetEmail: string
}

export const useForgotPassword = () => {
  const mutate = useMutation(({ resetEmail }: UseLoginInterface) => {
    return postAPI('/Identity/ResetToken', {
      username: resetEmail,
    })
  })

  return mutate
}

export default useForgotPassword
