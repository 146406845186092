import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteOrderShipmentLineInterface {
  orderId?: string
  shipmentId?: string
  shipmentLineId?: string
}

export const useDeleteOrderShipmentLine = () => {
  const mutate = useMutation(
    ({
      orderId,
      shipmentId,
      shipmentLineId,
    }: DeleteOrderShipmentLineInterface) => {
      return deleteAPI(
        `/WarehouseOrder/${orderId}/Shipments/${shipmentId}/ShipmentLine/${shipmentLineId}`,
      )
    },
  )

  return mutate
}

export default useDeleteOrderShipmentLine
