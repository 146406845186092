/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useCreateAgencyTasks,
  useGetGenericTasksList,
  useGetPartnerTable,
} from 'framework/api/methods'

import {
  Button,
  DatePicker,
  Input,
  SimpleSelect,
  TextArea,
} from 'components/atoms'
import { dateToReadableString } from 'utils'

export interface AgencyTaskCreationProps {
  [x: string]: any
}

export const AgencyTaskCreation: FC<AgencyTaskCreationProps> = ({
  className,
  ...restProps
}: AgencyTaskCreationProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [formDetails, setFormDetails] = useState<any>({
    genericTask: null,
    partner: null,
    title: '',
    description: '',
    estimatedHours: '',
    dueDate: '',
  })

  const [partnerList, setPartnerList] = useState<any>([])
  const [genericTasksList, setGenericTasksList] = useState<any>([])

  const resetForm = () => {
    setFormDetails({
      genericTask: null,
      partner: null,
      title: '',
      description: '',
      estimatedHours: '',
      dueDate: '',
    })
  }

  /** API Call for get partner list function using react-query */
  const { mutate: getPartnerTableMutate, isLoading: getPartnerTableIsLoading } =
    useGetPartnerTable()

  /** Process to get partner List for integration */
  async function getPartnerList(): Promise<void> {
    getPartnerTableMutate(
      { take: 999, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []

          successData.partners.forEach((item: any) => {
            if (item.isActive) {
              result.push({ label: item.code, value: item?.code, id: item?.id })
            }
          })
          setPartnerList(result)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** API for get generic tasks List */
  const {
    mutate: getGenericTasksListMutate,
    isLoading: getGenericTasksListIsLoading,
  } = useGetGenericTasksList()

  /** Process the Get  generic tasks  List */
  async function getGenericTasksList() {
    getGenericTasksListMutate(
      { id: 1 },
      {
        onSuccess: ({ data: successData }: any) => {
          const formattedGenericTasksList = successData.genericTasks.map(
            (gt: any) => {
              return {
                label: gt.title,
                value: gt.id,
              }
            },
          )
          setGenericTasksList(formattedGenericTasksList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  useEffect(() => {
    getPartnerList()
    getGenericTasksList()
  }, [])

  /** API Call for create agency tasks using react-query */
  const {
    mutate: createGenericTasksMutate,
    isLoading: createGenericTasksIsLoading,
  } = useCreateAgencyTasks()

  /** Process the create  generic tasks */
  async function createGenericTasks() {
    createGenericTasksMutate(
      {
        formDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          resetForm()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  return (
    <div
      className={CN(
        'flex items-start justify-center bg-N-50 h-full w-full px-8 py-5 overflow-y-auto',
        {},
        className,
      )}>
      <div className='flex flex-col w-full h-auto bg-white pt-6 rounded-md shadow-md'>
        <div className='flex flex-col w-full px-4 pb-12'>
          <h2 className='text-lg font-semibold'>Agency Task Creation</h2>

          <div className='w-5/12 flex flex-col mt-8'>
            <div className='flex flex-col w-full'>
              <span className='pb-2 text-N-700'>
                Select Generic Task
                <span className='text-R-500 ml-[2px]'>*</span>
              </span>

              <SimpleSelect
                value={formDetails?.genericTask}
                placeholder='Select'
                size='sm'
                onChange={(e: any) => {
                  setFormDetails({
                    ...formDetails,
                    genericTask: e,
                    title:
                      (formDetails?.partner
                        ? formDetails?.partner?.label
                        : '') +
                      (formDetails?.partner ? ' | ' : '') +
                      e?.label,
                  })
                }}
                options={genericTasksList}
              />
            </div>

            <div className='flex flex-col w-full mt-6'>
              <span className='pb-2 text-N-700'>
                Partner
                <span className='text-R-500 ml-[2px]'>*</span>
              </span>

              <SimpleSelect
                value={formDetails?.partner}
                placeholder='Select'
                size='sm'
                onChange={(e: any) => {
                  setFormDetails({
                    ...formDetails,
                    partner: e,
                    title:
                      e?.label +
                      (formDetails?.genericTask ? ' | ' : '') +
                      (formDetails?.genericTask
                        ? formDetails?.genericTask?.label
                        : ''),
                  })
                }}
                options={partnerList}
              />
            </div>

            <Input
              className='w-full mt-6'
              labelValue='Title '
              isRequired
              value={formDetails?.title}
              placeholder='Air B&B | Order Picking'
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  title: e.target.value,
                })
              }}
            />

            <TextArea
              labelValue='Description'
              className='w-full mt-6'
              rows={3}
              value={formDetails?.description}
              placeholder='Ex: Order picking from warehouse 001 etc.'
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  description: e.target.value,
                })
              }}
            />

            <div className='flex flex-col w-full mt-6'>
              <span className='pb-2 text-N-700'>Estimated hours</span>

              <SimpleSelect
                value={formDetails?.estimatedHours}
                placeholder='Select'
                onChange={(e: any) => {
                  setFormDetails({
                    ...formDetails,
                    estimatedHours: e,
                  })
                }}
                options={[
                  { label: '1h', value: '1h' },
                  { label: '2h', value: '2h' },
                  { label: '3h', value: '3h' },
                  { label: '4h', value: '4h' },
                  { label: '5h', value: '5h' },
                  { label: '6h', value: '6h' },
                  { label: '7h', value: '7h' },
                  { label: '8h', value: '8h' },
                ]}
                menuPlacement='top'
              />
            </div>

            <div className='mt-6 flex flex-col'>
              <span className='pb-2 text-N-700'>Expected Due Date</span>
              <DatePicker
                enabled={true}
                onChange={(e: any) => {
                  setFormDetails({
                    ...formDetails,
                    dueDate: e.target.value,
                  })
                }}
                value={formDetails?.dueDate}
                placeholder={dateToReadableString(new Date())}
                allowEdit={true}
              />
            </div>
          </div>
        </div>
        <div className='border-t border-t-N-200 px-[19px] py-[14px]'>
          <Button
            isDisabled={
              createGenericTasksIsLoading ||
              getPartnerTableIsLoading ||
              getGenericTasksListIsLoading ||
              !formDetails?.partner ||
              !formDetails?.genericTask ||
              !formDetails?.title
            }
            isLoading={createGenericTasksIsLoading}
            onClick={() => {
              createGenericTasks()
            }}>
            Create
          </Button>
        </div>
      </div>

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

AgencyTaskCreation.defaultProps = {}

export default AgencyTaskCreation
