/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getMachShipCarriers = async () => {
  const res = await get(`/MachShipCarriers`)
  return res?.data
}

export const useGetMachShipCarriers = ({}, onSuccess: any, onError: any) => {
  return useQuery(['reqData-machship-carriers'], getMachShipCarriers, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetMachShipCarriers
