/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { JsonLoader } from 'components/atoms/JsonLoader'

const Table = ({
  columns,
  data,
  selectedTableRows,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setUpdateInvoiceDrawerIsOpen,
  setSelectedRowByButtonAction,
  getInvoiceListIsLoading,
  setViewInvoiceDrawerIsOpen,
  invoiceDeleteAccess,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        selectedRowIds: selectedTableRows || [],
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((cols) => [
    //     {
    //       id: 'selection',
    //       customWidth: 32,
    //       Cell: ({ row }) => <div {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...cols,
    //   ])
    // },
  )
  /** Pagination States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })
  const [pageNumber, setPageNumber] = useState(1)

  const { userDetails } = useSelector((state) => state.user)
  const billingModuleEditAccess =
    userDetails?.userPermissions?.includes('InvoiceEditAccess')
  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(tableVariables.take) * Number(pageNumber) -
        Number(tableVariables.take) +
        1,
      end:
        Number(tableVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(tableVariables.take) * Number(pageNumber),
    })
  }, [tableVariables, totalRowCount, pageNumber])
  /** Pagination Functions */
  useEffect(() => {
    setTableVariables({
      ...tableVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(tableVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])
  useEffect(() => {
    if (tableVariables?.name) {
      setPageNumber(1)
    }
  }, [tableVariables?.name])
  const slicedPageData = page
  return (
    <>
      <div className='styled-scroll h-[calc(100%-54px)] relative rounded-t-md border border-N-200 overflow-x-auto '>
        {getInvoiceListIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] bg-opacity-10 cursor-not-allowed'>
            <JsonLoader size='xxs' />
          </div>
        )}
        <div className='table-container '>
          <table
            className='w-full overflow-x-auto styled-scroll '
            {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={CN(
                        'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left px-1  z-[1] ',
                        'column.accessor',
                        { 'bg-N-200': column.isSorted },
                      )}
                      style={{
                        width: column.customWidth || 'auto',
                        height: 45,
                      }}>
                      <div
                        className='inline-flex items-center'
                        style={{ minHeight: 20 }}>
                        {column.render('Header')}
                        <span className='flex ml-1' style={{ width: 14 }}>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <i className='ri-arrow-up-s-line' />
                            ) : (
                              <i className='ri-arrow-down-s-line' />
                            )
                          ) : null}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              {...getTableBodyProps()}
              className='white-space: nowrap !important
                         border: 1px solid #ddd
                         padding: 8px
                         text-align: left'>
              {page.map((row) => {
                // Changed from slicedPageData to page to match typical react-table naming
                prepareRow(row)
                return (
                  <tr
                    className='h-11 hover:bg-N-200 cursor-pointer'
                    {...row.getRowProps()}
                    onClick={() => {
                      toggleAllRowsSelected(false)
                      row.toggleRowSelected()
                    }}>
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps()}
                        className={CN(
                          'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-2 ',
                          {
                            'bg-N-300 border-b-N-400 text-overflow-ellipsis':
                              cell.isSelected,
                          },
                        )}>
                        {cell.column.isProductImage ? (
                          <div className='flex items-center justify-between w-fit'>
                            <Button
                              className='ml-4'
                              onClick={(e) => {
                                e.stopPropagation()
                                setViewInvoiceDrawerIsOpen(true)
                                setSelectedRowByButtonAction(row.original)
                              }}
                              appearance='dull'
                              size='xs'>
                              <i className='ri-eye-line'></i>
                            </Button>
                            {billingModuleEditAccess && (
                              <Button
                                className='ml-4'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setUpdateInvoiceDrawerIsOpen(true)
                                  setSelectedRowByButtonAction(row.original)
                                }}
                                appearance='dull'
                                size='xs'>
                                <i className='ri-edit-line'></i>
                              </Button>
                            )}
                          </div>
                        ) : (
                          cell.render('Cell')
                        )}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex items-center justify-between px-3 py-[10px] border border-N-200 rounded-b-md'>
        <div className='flex items-center'>
          <span className='text-N-700 text-sm'>Show rows per page</span>
          <select
            className='ml-2 outline-none border border-N-200 rounded-md w-20 h-7'
            value={tableVariables.take}
            onChange={(e) => {
              setPageNumber(1)
              setTableVariables({ ...tableVariables, take: e.target.value })
            }}>
            {[10, 20, 30, 50].map((pageSizeValue) => (
              <option key={pageSizeValue} value={pageSizeValue}>
                {pageSizeValue}
              </option>
            ))}
          </select>
        </div>
        <div className='flex'>
          <div className='flex items-center text-N-700 text-sm mr-9'>
            <p>
              {paginationVariables.start} - {paginationVariables.end} of{' '}
              {totalRowCount}
            </p>
          </div>
          <Button
            className='mr-8'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber - 1)
            }}
            disabled={pageNumber <= 1}>
            <i className='ri-arrow-left-s-line' />
          </Button>
          <Button
            className='mr-2'
            appearance='dull'
            size='xs'
            onClick={() => {
              setPageNumber(pageNumber + 1)
            }}
            disabled={
              Number(pageNumber) * Number(tableVariables.take) >=
              Number(totalRowCount)
            }>
            <i className='ri-arrow-right-s-line' />
          </Button>
        </div>
      </div>
    </>
  )
}
Table.propTypes = {}
export default Table
