import ReactTooltip from 'react-tooltip'

import { dateToAEST } from 'utils'

export const columns = [
  {
    customWidth: 100,
    Header: () => '',
    accessor: 'status',
    Cell: ({ row }) => (
      <div className='flex items-center' data-tip={row.original.errorReason}>
        {row.original.errorReason && (
          <>
            <i className='ri-error-warning-fill text-sm text-R-500 ml-1'></i>
            <p className='pl-2'>Error</p>
            <ReactTooltip
              place='top'
              effect='solid'
              delayShow={200}
              textColor='#E2E8F0'
              backgroundColor='#334155'
            />
          </>
        )}
        {row.original.errorReason === null && (
          <i className='ri-checkbox-circle-fill text-sm text-G-500 ml-1'></i>
        )}
      </div>
    ),
  },
  {
    customWidth: 160,
    Header: () => 'Order #',
    accessor: 'customerOrderNo',
    Cell: ({ row }) => (
      <div data-tip={row.original.customerOrderNo} className='line-clamp-2'>
        {row.original.customerOrderNo}
        <ReactTooltip
          place='top'
          effect='solid'
          delayShow={200}
          textColor='#E2E8F0'
          backgroundColor='#334155'
        />
      </div>
    ),
  },
  {
    customWidth: 128,
    Header: () => 'WMS Order #',
    accessor: 'orderNo',
    Cell: ({ row }) => <div>{row.original.orderNo}</div>,
  },
  {
    customWidth: 376,
    Header: 'Delivery Address',
    accessor: 'shippingAddress.lineOne',
    Cell: ({
      row: {
        original: { shippingAddress },
      },
    }) => (
      <div className='line-clamp-2'>{`${shippingAddress?.lineOne}, ${shippingAddress?.city}, ${shippingAddress?.postCode}`}</div>
    ),
  },
  {
    customWidth: 212,
    Header: () => 'Suburb',
    accessor: 'shippingAddress.suburb',
    Cell: ({
      row: {
        original: { shippingAddress },
      },
    }) => <div>{shippingAddress?.suburb}</div>,
  },
  {
    customWidth: 212,
    Header: () => 'Date Created',
    accessor: 'createdOn',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>{dateToAEST(row.original.createdOn)}</div>
    ),
  },
]

// export const columns = [
//   {
//     customWidth: 100,
//     Header: () => '',
//     accessor: 'status',
//     Cell: ({ row }) => (
//       <div className='flex items-center' data-tip={row.original.errorReason}>
//         {row.original.errorReason && (
//           <>
//             <i className='ri-error-warning-fill text-sm text-R-500 ml-1'></i>
//             <p className='pl-2'>Error</p>
//             <ReactTooltip
//               place='top'
//               effect='solid'
//               delayShow={200}
//               textColor='#E2E8F0'
//               backgroundColor='#334155'
//             />
//           </>
//         )}
//         {row.original.errorReason === null && (
//           <i className='ri-checkbox-circle-fill text-sm text-G-500 ml-1'></i>
//         )}
//       </div>
//     ),
//   },
//   {
//     customWidth: 160,
//     Header: () => 'Order #',
//     accessor: 'id',
//     Cell: ({
//       row: {
//         original: {
//           warehouseOrder: { partnerOrder: data },
//         },
//       },
//     }) => (
//       <div data-tip={data.customerOrderNo} className='line-clamp-2'>
//         {data.customerOrderNo}
//         <ReactTooltip
//           place='top'
//           effect='solid'
//           delayShow={200}
//           textColor='#E2E8F0'
//           backgroundColor='#334155'
//         />
//       </div>
//     ),
//   },
//   {
//     customWidth: 128,
//     Header: () => 'WMS Order #',
//     accessor: 'orderNo',
//     Cell: ({ row }) => <div>{row.original.warehouseOrder.orderNo}</div>,
//   },
//   {
//     customWidth: 376,
//     Header: 'Delivery Address',
//     accessor: 'availableQty',
//     Cell: ({
//       row: {
//         original: {
//           warehouseOrder: {
//             partnerOrder: { shippingAddress },
//           },
//         },
//       },
//     }) => (
//       <div className='line-clamp-2'>{`${shippingAddress?.lineOne}, ${shippingAddress?.city}, ${shippingAddress?.postCode}`}</div>
//     ),
//   },
//   {
//     customWidth: 212,
//     Header: () => 'Suburb',
//     accessor: 'suburb',
//     Cell: ({
//       row: {
//         original: {
//           warehouseOrder: {
//             partnerOrder: { shippingAddress },
//           },
//         },
//       },
//     }) => <div>{shippingAddress.suburb}</div>,
//   },
//   {
//     customWidth: 212,
//     Header: () => 'Date Created',
//     accessor: 'createdOn',
//     Cell: ({ row }) => (
//       <div className='line-clamp-2'>
//         {dateToReadableString(row.original.warehouseOrder.createdOn)}
//       </div>
//     ),
//   },
// ]

export default columns
