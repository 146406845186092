import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateWarehouseIntegrationInterface {
  apiKey?: string
  baseUrl?: string
  id?: string
  name?: string
  token?: string
  warehouseId?: string
}

export const useUpdateWarehouseIntegration = () => {
  const mutate = useMutation(
    ({
      baseUrl,
      apiKey,
      id,
      name,
      token,
      warehouseId,
    }: UpdateWarehouseIntegrationInterface) => {
      return putAPI(`/Warehouse/${warehouseId}/Integration/${name}/${id}`, {
        baseUrl,
        apiKey: name === 'IcsWMS' ? apiKey : undefined,
        token: name === 'PaperlessWMS' ? token : undefined,
      })
    },
  )

  return mutate
}

export default useUpdateWarehouseIntegration
