import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdatePartnerInterface {
  partnerId?: string
  partnerCode?: string
  partnerName?: string
  contactPerson?: string
  contactEmail?: string
  supportEmail?: string
  phoneNo?: string
  streetAddress?: string
  suburb?: string
  state?: any
  country?: any
  postalCode?: string
  status?: boolean
}

export const useUpdatePartner = () => {
  const mutate = useMutation(
    ({
      partnerId,
      partnerCode,
      partnerName,
      contactPerson,
      contactEmail,
      supportEmail,
      phoneNo,
      streetAddress,
      suburb,
      state,
      country,
      postalCode,
      status,
    }: UpdatePartnerInterface) => {
      return putAPI('/partner', {
        id: partnerId,
        code: partnerCode,
        name: partnerName,
        contactName: contactPerson,
        contactEmail: contactEmail,
        supportEmail: supportEmail,
        contactPhone: phoneNo,
        address: {
          lineOne: streetAddress,
          lineTwo: '',
          city: '',
          suburb: suburb,
          state: state?.label,
          stateCode: state?.value,
          country: country.label,
          countryCode: country.value,
          postCode: postalCode,
        },
        isActive: status,
      })
    },
  )

  return mutate
}

export default useUpdatePartner
