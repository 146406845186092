import { FC, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FaDownload,
  FaFile,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa'
import { colors } from 'react-select/dist/declarations/src/theme'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { useUpdateInvoice } from 'framework/api/methods'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

import { Input, PopUpAlert, SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'

import './billing.css'

export interface Document {
  invoiceId: string
  fileName: string
  type: string
  url: string
  isDeleted: boolean
}
export interface ViewInvoiceProps {
  className?: string | undefined
  [x: string]: any
}
export const ViewInvoice: FC<ViewInvoiceProps> = ({
  className,
  notify,
  isActive,
  onCloseClick,
  partnerList,
  selectedRowByButtonAction,
  getInvoiceList,
}: ViewInvoiceProps) => {
  const InvoiceClasses = CN(
    'UpdateInvoice flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [InvoiceDetails, setInvoiceDetails] = useState<any>({
    partnerCode: '',
    partnerName: '',
    invoiceId: '',
    title: '',
    type: '',
    year: '',
    month: '',
    dateRaised: '',
    dueDate: '',
    amount: 0,
    invoiceFiles: '',
    isPaid: '',
    status: '',
    newFiles: '',
  })
  const [isErrorField, setIsErrorField] = useState<any>({
    partnerCode: false,
    partnerName: false,
    invoiceId: false,
    title: false,
    year: false,
    month: false,
    dateRaised: false,
    dueDate: false,
    amount: false,
    type: false,
  })
  const getFileIcon = (mimeType: string) => {
    if (mimeType.includes('image')) {
      return <FaFileImage style={{ color: 'orange' }} />
    } else if (mimeType.includes('png')) {
      return <FaFileImage style={{ color: 'orange' }} />
    } else if (mimeType.includes('jpg')) {
      return <FaFileImage style={{ color: 'orange' }} />
    } else if (mimeType.includes('pdf')) {
      return <FaFilePdf style={{ color: 'red' }} />
    } else if (mimeType === 'application/pdf') {
      return <FaFilePdf style={{ color: 'red' }} />
    } else if (mimeType.includes('word')) {
      return <FaFileWord style={{ color: 'blue' }} />
    } else if (mimeType.includes('docx')) {
      return <FaFileWord style={{ color: 'blue' }} />
    } else if (mimeType.includes('spreadsheetml')) {
      return <FaFileExcel style={{ color: 'green' }} />
    } else if (mimeType.includes('csv')) {
      return <FaFileCsv style={{ color: '#90ee90' }} />
    } else if (mimeType.includes('xlsx')) {
      return <FaFileExcel style={{ color: 'green' }} />
    } else {
      return <FaFile style={{ color: 'blue' }} />
    }
  }
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [selectedNewFiles, setNewFiles] = useState<File[]>([])
  const [removedDocs, setRemovedDocs] = useState<Document[]>([])
  const handleFiles = (files: FileList | null) => {
    if (files) {
      // Check if 'files' is not null
      const fileArray = Array.from(files) // Convert FileList to File[]
      const updatedFiles = [...selectedFiles, ...fileArray]
      setSelectedFiles(updatedFiles)
      const newFiles = [...selectedNewFiles, ...fileArray]
      setNewFiles(newFiles)
    }
  }
  const handleDownloadFile = (file: any) => {
    // Create a URL for the file
    const url = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    a.href = url
    a.download = file.name || 'download'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  const handleDownloadDocument = (doc: any) => {
    // Check if the URL is provided
    if (!doc.url) {
      console.error('No URL provided for download')
      return
    }
    // Create a link element for downloading
    const a = document.createElement('a')
    a.href = doc.url // Ensure the URL is decoded
    a.setAttribute('download', doc.fileName || 'download') // Use the decoded file name or a default
    // Append the link to the body, trigger download, and remove the lin
    window.open(doc.url, '_blank')
  }
  const months = moment.months().map((name, index) => ({
    value: index + 1, // moment.js month index starts at 0
    name: name,
  }))
  const years = Array.from(
    new Array(30),
    (val, index) => new Date().getFullYear() - index,
  )
  const statusOptions = [
    { value: 'Due', label: 'Due' },
    { value: 'Overdue', label: 'Overdue' },
    { value: 'Paid', label: 'Paid' },
  ]
  useEffect(() => {
    const {
      id,
      partnerCode,
      partnerName,
      invoiceId,
      title,
      year,
      month,
      dateRaised,
      dueDate,
      amount,
      type,
      isPaid,
      status,
      documents,
    } = selectedRowByButtonAction || {}
    setInvoiceDetails({
      ...InvoiceDetails,
      id,
      partnerCode,
      partnerName,
      invoiceId,
      title,
      year,
      month,
      dateRaised,
      dueDate,
      amount,
      type,
      isPaid,
      status,
      documents,
    })
  }, [selectedRowByButtonAction])
  const { mutate: updateInvoiceMutate, isLoading: updateInvoiceIsLoading } =
    useUpdateInvoice()
  const [documents, setDocuments] = useState(
    selectedRowByButtonAction.documents || [],
  )
  const fileInputRef = useRef<HTMLInputElement>(null as any)
  const [selectedYear, setYear] = useState(() => {
    return selectedRowByButtonAction.year
      ? parseInt(selectedRowByButtonAction.year, 10)
      : undefined
  })
  const [selectedMonth, setMonth] = useState(() => {
    // Find the option that matches the month name from InvoiceDetails
    const matchingOption = months.find(
      (option) => option.name === selectedRowByButtonAction.month,
    )
    return matchingOption ? matchingOption.value : undefined
  }) // Default to current month
  function getMonthName(monthNumber: any) {
    const monthIndex = parseInt(monthNumber, 10) - 1 // Convert string to number and adjust for zero-based index
    const date = new Date(0, monthIndex)
    return date.toLocaleString('default', { month: 'long' })
  }
  function formatDateToISO(dateString: string) {
    if (!dateString) return '' // Return empty string if date is undefined or null
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-indexed, add 1
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const [selectedDueDate, setDueDate] = useState(
    new Date(selectedRowByButtonAction.dueDate),
  )
  const [selectedDateRaised, setDateRaised] = useState(
    new Date(selectedRowByButtonAction.dateRaised),
  )
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState(() => {
    if (selectedRowByButtonAction.isPaid) {
      return statusOptions.find((option) => option.value === 'Paid')
    } else {
      // Assuming selectedDueDate is a Date object or a comparable value
      return new Date(selectedDueDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
        ? statusOptions.find((option) => option.value === 'Overdue')
        : statusOptions.find((option) => option.value === 'Due')
    }
  })
  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={700}
      title='View Invoice'>
      <div className={InvoiceClasses}>
        <div
          className='add-invoice-container'
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='add-invoice-form' style={{ width: '300px' }}>
            <div className='UpdateInvoice flex flex-col py-4 px-5 relative gap-y-5'>
              <div className='flex flex-col w-full mt-1'>
                <span className='pb-2 text-N-700'>
                  Partner Name
                  <span className='text-R-500 ml-[2px]'>*</span>
                </span>
                <SimpleSelect
                  placeholder='Select Partner'
                  value={
                    InvoiceDetails?.partnerCode
                      ? {
                          label: InvoiceDetails?.partnerName,
                          value: InvoiceDetails?.partnerCode,
                        }
                      : null
                  }
                  disabled={true}
                  options={partnerList}
                  isError={isErrorField?.partnerCode}
                />
              </div>
              <Input
                labelValue='Invoice Id'
                isRequired
                value={InvoiceDetails.invoiceId}
                isDisabled={true}
                isError={isErrorField.invoiceId}
              />
              <Input
                labelValue='Title'
                value={InvoiceDetails.title}
                isDisabled={true}
                isError={isErrorField.title}
              />
              <Input
                labelValue='Type'
                value={InvoiceDetails.type}
                isDisabled={true}
                onChange={(e: any) => {
                  setInvoiceDetails({
                    ...InvoiceDetails,
                    type: e.target.value,
                  })
                }}
                isError={isErrorField.type}
              />
              <div className='date-select-container'>
                <div className='flex flex-col w-1/2 z-[2]'>
                  <span className='pb-2 text-N-700'>
                    Year
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <select
                    style={{ fontSize: 15, color: 'grayText' }}
                    placeholder='Year'
                    value={selectedYear}
                    disabled={true}
                    className='year-selector'>
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='flex flex-col w-1/2 z-[2]'>
                  {/* Month Selector */}
                  <span className='pb-2 text-N-700 ml-2'>
                    Month
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <select
                    style={{ fontSize: 15, color: 'grayText' }}
                    placeholder='Month'
                    value={selectedMonth}
                    disabled={true}
                    className='month-selector'>
                    {months.map((m) => (
                      <option key={m.value} value={m.value}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className='flex flex-col z-[2]'>
                  <span className='pb-2 text-N-700'>Date Raised</span>
                  <div className='date-picker mt-0'>
                    <DatePickerComponent
                      disabled={true}
                      value={selectedDateRaised}
                      allowEdit={false}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className='flex flex-col z-[2]'>
                  <span className='pb-2 text-N-700'>Due Date</span>
                  <div className='date-picker mt-0'>
                    <DatePickerComponent
                      disabled={true}
                      onChange={(e: any) => setDueDate(e.target.value)}
                      value={selectedDueDate}
                      allowEdit={false}
                    />
                  </div>
                </div>
              </div>
              <Input
                labelValue='Amount'
                isRequired
                value={InvoiceDetails.amount}
                isDisabled={true}
                isError={isErrorField.amount}
              />
              <div className='InvoiceDetails'>
                <div className='flex flex-col w-full mt-1'>
                  <span className='pb-2 text-N-700'>
                    Invoice Status
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <SimpleSelect
                    value={selectedInvoiceStatus}
                    disabled={true}
                    required
                    options={statusOptions}></SimpleSelect>
                </div>
              </div>
              <div className='InvoiceDetails'>
                <div className='flex flex-col w-full'>
                  <span className='pb-2  text-N-700'>Status</span>
                  <ToggleSwitch
                    text={InvoiceDetails?.status ? 'Active' : 'Inactive'}
                    setValue={InvoiceDetails?.status}
                    isDisabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='add-invoice-upload' style={{ width: '300px' }}>
            <div className='UpdateInvoice flex flex-col py-4 px-5 relative gap-y-5'>
              <label htmlFor='file-upload' className='block text-N-700 mb-0'>
                Invoice Documents
              </label>
              <div
                className='border-2 border-dashed border-N-200 rounded p-8 text-center relative'
                hidden={true}>
                <input
                  id='file-upload'
                  type='file'
                  multiple
                  aria-disabled={true}
                  className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                  ref={fileInputRef}
                />
                <div className='text-N-600'>
                  <span style={{ color: 'orange' }}>Choose file</span> or drop
                  here
                </div>
              </div>
              {/* Existing documents */}
              <ul className='mt-2'>
                {documents.map((doc: any, index: any) => (
                  <li
                    key={doc.id}
                    className='flex items-center border p-2 rounded mb-2 justify-between'>
                    <div className='flex items-center'>
                      <i className='file-icon mr-2'>{getFileIcon(doc.type)}</i>{' '}
                      <span
                        style={{
                          color: 'gray',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '150px',
                        }}
                        title={doc.fileName}>
                        {doc.fileName}
                      </span>
                    </div>
                    <div className='flex items-center'>
                      <FaDownload
                        style={{
                          color: 'green',
                          fontSize: '1.00rem',
                          cursor: 'pointer',
                          marginLeft: '0.5rem',
                        }}
                        onClick={() => handleDownloadDocument(doc)} // Implement download logic
                      />
                    </div>
                  </li>
                ))}
                {selectedFiles.map((file, index) => (
                  <li
                    key={index}
                    className='flex items-center border p-2 rounded mb-2 justify-between'>
                    <div className='flex items-center'>
                      <i className='file-icon mr-2'>{getFileIcon(file.type)}</i>{' '}
                      <span
                        style={{
                          color: 'gray',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '150px', // Adjust the maxWidth to fit your layout
                        }}
                        title={file.name} // Shows the full name on hover
                      >
                        {file.name}
                      </span>
                    </div>
                    <div className='flex items-center'>
                      <FaDownload
                        style={{
                          color: 'green',
                          fontSize: '1.00rem', // Size can be adjusted if needed
                          cursor: 'pointer',
                          marginLeft: '0.5rem',
                        }}
                        onClick={() => handleDownloadFile(file)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
ViewInvoice.defaultProps = {
  className: undefined,
}
export default ViewInvoice
