/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { useGetFileVsOrderExportCSV } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'

import './AdvanceSearchModal.scss'

export interface AdvanceSearchModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  partnerList?: any
  setFilterVariables?: any
  filterVariables?: any
  searchKeyword?: any
  setPageNumber?: any
  principleList?: any
  getFileVsOrderList?: any
  tableVariables?: any
}

export const AdvanceSearchModal: FC<AdvanceSearchModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  setFilterVariables,
  filterVariables,
  setPageNumber,
  principleList,
  getFileVsOrderList,
  tableVariables,
}: AdvanceSearchModalProps) => {
  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [selectedPartner, setSelectedPartner] = useState<any>(
    filterVariables.principle,
  )
  const [startDate, setStartDate] = useState<any>(filterVariables.from)
  const [endDate, setEndDate] = useState<any>(filterVariables.to)

  const [csvOrderData, setCsvOrderData] = useState([])

  /** Handling Click overlay */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleApplyFilter = (event: any) => {
    setPageNumber(1)
    const stDate = startDate ? new Date(startDate) : ''
    const enDate = endDate ? new Date(endDate) : ''
    event.stopPropagation()
    setFilterVariables({
      ...filterVariables,
      principle: selectedPartner,
      from: stDate,
      to: enDate,
    })
    getFileVsOrderList(
      tableVariables.take,
      tableVariables.skip,
      tableVariables.search,
      selectedPartner,
      stDate,
      enDate,
    )
    if (onCloseClick) {
      onCloseClick()
    }
  }

  const onResetClick = () => {
    // setPageNumber(1)
    // setFilterVariables({
    //   ...filterVariables,
    //   principle: { label: 'All', value: 'All' },
    //   from: '',
    //   to: '',
    // })

    // getFileVsOrderList(
    //   tableVariables.take,
    //   tableVariables.skip,
    //   tableVariables.search,
    //   { label: 'All', value: 'All' },
    //   '',
    //   '',
    // )

    setStartDate('')
    setEndDate('')
    setSelectedPartner({ label: 'All', value: 'All' })

    // if (onCloseClick) {
    //   onCloseClick()
    // }
  }

  /** Handling filtering orders Export as Csv */
  const handleExportCSV = () => {
    const stDate = startDate ? new Date(startDate) : ''
    const enDate = endDate ? new Date(endDate) : ''

    const toDate = stDate
      ? stDate?.getFullYear() +
        '-' +
        ('0' + (stDate.getMonth() + 1)).slice(-2) +
        '-' +
        stDate.getDate()
      : ''

    const fromDate = enDate
      ? enDate.getFullYear() +
        '-' +
        ('0' + (enDate.getMonth() + 1)).slice(-2) +
        '-' +
        enDate.getDate()
      : ''

    /** call process the orders Export as CSV */
    getOrdersExportCSV(selectedPartner, toDate, fromDate)
  }

  /** Process the orders Export as CSV */
  async function getOrdersExportCSV(
    principle: string,
    from: string,
    to: string,
  ) {
    getFileVsOrderExportCSVMutate(
      { principle, from, to },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set data to csv document */
          setCsvOrderData(successData)

          /** trigger csv element */
          csvLinkEl?.current?.link.click()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for filtering file vs order export as CSV using react-query */
  const { mutate: getFileVsOrderExportCSVMutate } = useGetFileVsOrderExportCSV()

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** notification styles */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-fit bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>File vs Order Filter</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full pt-6 px-4'>
          <div className='flex w-2/3 flex-col'>
            <div className='flex flex-col'>
              <p className='text-sm font-medium text-N-700 pb-2'>
                By Principle
              </p>
              <SimpleSelect
                options={[{ label: 'All', value: 'All' }, ...principleList]}
                value={selectedPartner}
                onChange={(e: any) => {
                  setSelectedPartner(e)
                }}
              />
            </div>

            <div className='flex flex-col pt-10'>
              <p className='text-sm font-medium text-N-700'>Select Date</p>
              <DateRangePickerComponent
                format='dd/MM/yyyy'
                delayUpdate={true}
                placeholder='Select a range'
                startDate={startDate}
                endDate={endDate}
                onChange={(e: any) => {
                  setStartDate(e.target.startDate)
                  setEndDate(e.target.endDate)
                }}
              />
            </div>
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 mt-[116px] px-5 py-4'>
          <Button
            className='w-[152px]'
            onClick={(e) => handleApplyFilter(e)}
            disabled={false}
            isLoading={false}>
            Apply
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onResetClick}>
            Reset Filters
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={() => {
              handleExportCSV()
            }}>
            Export CSV
          </Button>

          {/* for csv export */}
          <CSVLink
            filename='file_vs_orders.csv'
            data={csvOrderData}
            ref={csvLinkEl}
          />
        </div>
      </div>
    </div>
  )
}

AdvanceSearchModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AdvanceSearchModal
