import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import profilePic from 'assets/images/profile-image.svg'
import CN from 'classnames'
import { setLogoutModalShow } from 'store/reducers/user/userSlice'

export interface HeaderProps {
  [x: string]: any
  isFullMenu?: boolean
  onToggleButtonClick?: (e: any) => void | undefined
  userDetails?: any
}

export const Header: FC<HeaderProps> = ({
  className,
  isFullMenu,
  onToggleButtonClick,
  userDetails,
  ...restProps
}: HeaderProps) => {
  const dispatch = useDispatch()
  const { breadcrumb }: any = useSelector<any>((state) => state.header)

  const HeaderClasses = CN(
    'main-layout__header z-10 fixed bg-N-50 h-[76px] px-8 py-[14px] flex justify-between items-center shadow-sm border-b border-N-200',
    {
      'left-[280px] w-[calc(100%-280px)]': isFullMenu,
      'left-[60px] w-[calc(100%-60px)]': !isFullMenu,
    },
    className,
  )

  const handleLogoutClick = () => {
    dispatch(setLogoutModalShow())
  }

  return (
    <header className={HeaderClasses} {...restProps}>
      <div className='header__left flex items-center py-[6px]'>
        <button
          onClick={onToggleButtonClick}
          className='py-1 px-[9px] border border-N-200 rounded-md bg-white'>
          <i className='ri-menu-2-line'></i>
        </button>
        <h2 className='text-2xl font-semibold leading-8 pl-8 text-N-800'>
          {breadcrumb || ''}
        </h2>
      </div>

      <div className='header__left flex items-center'>
        {/* <button className='py-[9px] px-[14px] border border-N-200 rounded-md bg-white'>
          <i className='ri-notification-2-line'></i>
        </button> */}
        <div className='h-12 flex items-center rounded ml-6 pr-3 border border-N-200'>
          <div className='h-[46px] w-[46px] bg-N-200 p-[10px]'>
            <img
              src={profilePic}
              className='h-full w-full rounded-l'
              alt='profile'
            />
          </div>

          <div className='flex flex-col px-[10px] py-2'>
            <p className='text-xs font-semibold'>
              {userDetails?.firstName} {userDetails?.lastName}
            </p>
            <p className='text-xs'>{userDetails?.roleName}</p>
          </div>
          {/* <i className='ri-arrow-drop-down-line text-xl'></i> */}
        </div>

        <button
          className='logout-btn h-12 w-12 flex justify-center items-center p-[14px] ml-3 border border-N-200 rounded-md bg-white'
          onClick={handleLogoutClick}>
          <i className='ri-logout-circle-r-line text-xl text-R-500'></i>
        </button>
      </div>
    </header>
  )
}

Header.defaultProps = {
  onClick: undefined,
  userDetails: undefined,
}

export default Header
