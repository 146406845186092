/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getStarshipITCarriers = async () => {
  const res = await get(`/StarshipITCarriers`)
  return res?.data
}

export const useGetStarshipITCarriers = ({}, onSuccess: any, onError: any) => {
  return useQuery(['reqData-starshipit'], getStarshipITCarriers, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetStarshipITCarriers
