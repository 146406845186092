/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC } from 'react'
import CN from 'classnames'

export interface FilterChipProps {
  className?: string | undefined
  onClick?: (e: any) => void
  labelValue?: string | undefined
  filterValue?: string | undefined
}

export const FilterChip: FC<FilterChipProps> = ({
  className,
  onClick,
  labelValue,
  filterValue,
  ...restProps
}: FilterChipProps) => {
  const FilterChipClasses = CN(
    'FilterChipClasses flex items-center text-xs text-N-700 border border-S-200 rounded py-1 px-2 bg-white mr-[9px] mb-4',
    className,
    {},
  )
  return (
    <div className={FilterChipClasses} {...restProps}>
      <p className='font-normal'>{labelValue}:&nbsp;</p>
      <p className='font-medium'>{filterValue}</p>
      <i
        className='ri-close-line pl-3 cursor-pointer active:text-N-400'
        onClick={onClick}></i>
    </div>
  )
}

FilterChip.defaultProps = {
  className: undefined,
  onClick: undefined,
  labelValue: undefined,
  filterValue: undefined,
}

export default FilterChip
