import React from 'react'

import { dateToReadableString } from 'utils/dateToReadableString'

export const columns = [
  {
    customWidth: 120,
    Header: () => 'Principle',
    accessor: 'principle',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.principle}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'File Name',
    accessor: 'fileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.fileName?.includes('/')
          ? row.original.fileName?.split('/')[1]
          : row.original.fileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'WMS Order',
    accessor: 'whOrderNo',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.whOrderNo}</div>
    ),
  },
  {
    customWidth: 180,
    Header: 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.customerReference}</div>
    ),
  },
  {
    customWidth: 180,
    Header: 'File Receipt Date',
    accessor: 'fileReceiptDate',
    Cell: ({ row }) => (
      <div className='min-w-[140px]'>
        {dateToReadableString(row.original.fileReceiptDate)}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'ORDRSP File',
    accessor: 'ordrspFileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.ordrspFileName?.includes('/')
          ? row.original.ordrspFileName?.split('/')[1]
          : row.original.ordrspFileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'ORDRSP Date',
    accessor: 'ordrspFileDate',
    Cell: ({ row }) => (
      <div className='min-w-[140px]'>
        {row.original.ordrspFileDate
          ? dateToReadableString(row.original.ordrspFileDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'DESADV File',
    accessor: 'desadvFileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.desadvFileName?.includes('/')
          ? row.original.desadvFileName?.split('/')[1]
          : row.original.desadvFileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'DESADV Date',
    accessor: 'desadvFileDate',
    Cell: ({ row }) => (
      <div className='w-[140px]'>
        {row.original.desadvFileDate
          ? dateToReadableString(row.original.desadvFileDate)
          : ''}
      </div>
    ),
  },
]
export default columns
