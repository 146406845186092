import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteWarehouseIntegrationByIdInterface {
  warehouseId?: string
  integrationId?: string
}

export const useDeleteWarehouseIntegrationById = () => {
  const mutate = useMutation(
    ({
      warehouseId,
      integrationId,
    }: DeleteWarehouseIntegrationByIdInterface) => {
      return deleteAPI(`/Warehouse/${warehouseId}/Integration/${integrationId}`)
    },
  )

  return mutate
}

export default useDeleteWarehouseIntegrationById
