import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetSimilarOrdersInterface {
  bulkConsignmentId?: string
  filterDetails?: any
}

export const useGetSimilarOrders = () => {
  const mutate = useMutation(
    ({ bulkConsignmentId, filterDetails }: GetSimilarOrdersInterface) => {
      let formattedDate = ''

      if (filterDetails?.date) {
        const stDate = new Date(filterDetails.date)
        formattedDate =
          stDate.getFullYear() +
          '-' +
          ('0' + (stDate.getMonth() + 1)).slice(-2) +
          '-' +
          stDate.getDate()
      }

      return get(
        `/BulkConsignments/${bulkConsignmentId}/SimilarOrders?Suburb=${filterDetails?.suburb}&Date=${formattedDate}`,
      )
    },
  )

  return mutate
}

export default useGetSimilarOrders
