import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface DeactivateWarehouseIntegrationInterface {
  integrationId?: string
  warehouseId?: string
}

export const useDeactivateWarehouseIntegration = () => {
  const mutate = useMutation(
    ({
      integrationId,
      warehouseId,
    }: DeactivateWarehouseIntegrationInterface) => {
      return postAPI(
        `/warehouse/${warehouseId}/Integration/${integrationId}/Deactivate`,
        {},
      )
    },
  )

  return mutate
}

export default useDeactivateWarehouseIntegration
