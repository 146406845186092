import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import './QuantityPicker.scss'

export interface QuantityPickerProps {
  className?: string | undefined
  clearOnFocus?: boolean
  defaultValue?: any
  disabled?: boolean
  isMinusEnabled?: boolean
  isWide?: boolean
  onChange?: (...args: any[]) => void
  maxValue?: any
}

export const QuantityPicker: FC<QuantityPickerProps> = ({
  defaultValue,
  disabled,
  isMinusEnabled,
  onChange,
  maxValue,
}: QuantityPickerProps) => {
  const [value, setValue] = useState(defaultValue)
  const [prevValue, setPrevValue] = useState(defaultValue)
  const [disabledMinus, setDisabledMinus] = useState(false)
  const [disabledPlus, setDisabledPlus] = useState(false)
  const [inputWidth, setInputWidth] = useState(0)

  const handleOnBlurInput = (e: any) => {
    if (onChange) {
      if (value !== '') {
        onChange(e, Number(value))
      } else {
        setValue(Number(prevValue))
        onChange(e, Number(prevValue))
      }
    }
  }

  const handleOnFocus = (e: any) => {
    setValue(() => {
      setPrevValue(Number(e.target.value))
      return ''
    })
  }

  const onChangeHandler = (e: any) => {
    setInputWidth(e.target.value.length)
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/
    const currentValue = e.target.value
    if (!isMinusEnabled) {
      if (currentValue === '' || regex.test(currentValue)) {
        setValue(Number(currentValue))
      }
    } else {
      setValue(Number(currentValue))
    }
    if (maxValue) {
      if (Number(currentValue) > maxValue) {
        setValue(maxValue)
      }
    }
  }

  useEffect(() => {
    setValue(Number(defaultValue))
    setPrevValue(Number(defaultValue))
    setInputWidth(defaultValue.toString().length)
  }, [defaultValue])

  useEffect(() => {
    if (!isMinusEnabled && value === 0) {
      setDisabledMinus(true)
    } else {
      setDisabledMinus(false)
    }

    if (value === maxValue) {
      setDisabledPlus(true)
    } else {
      setDisabledPlus(false)
    }
  }, [value])

  return (
    <div className='quantitypicker__content flex items-center justify-center w-full'>
      <button
        disabled={disabled || disabledMinus}
        className={CN(
          'flex items-center justify-center w-8 h-8 hover:bg-N-50 active:bg-N-100 rounded',
          {
            'cursor-not-allowed': disabled || disabledMinus,
          },
        )}
        onClick={(e: any) => {
          e.stopPropagation()
          setValue(value - 1)
          if (onChange) onChange(e, Number(value - 1))
        }}>
        <i className='ri-subtract-line'></i>
      </button>

      <input
        disabled={disabled}
        onBlur={handleOnBlurInput}
        onChange={onChangeHandler}
        onClick={(e: any) => e.stopPropagation()}
        type='number'
        value={value}
        onFocus={handleOnFocus}
        onKeyPress={(e: any) => {
          const regex = /^[0-9]*\.?[0-9]*$/
          if (!isMinusEnabled && !regex.test(e.key)) {
            e.preventDefault()
          }
        }}
        style={{ width: `${inputWidth + 5}ch` }}
        className='text-sm text-N-700 pt-[6px] pb-2 px-3 border border-N-200 rounded-md outline-none mx-1 text-center'
      />

      <button
        disabled={disabled || disabledPlus}
        className={CN(
          'flex items-center justify-center w-8 h-8 hover:bg-N-50 active:bg-N-100 rounded',
          {
            'cursor-not-allowed': disabled || disabledPlus,
          },
        )}
        onClick={(e: any) => {
          e.stopPropagation()
          setValue(value + 1)
          if (onChange) {
            onChange(e, Number(value + 1))
          }
        }}>
        <i className='ri-add-line'></i>
      </button>
    </div>
  )
}

QuantityPicker.defaultProps = {
  className: undefined,
  defaultValue: 0,
  disabled: false,
  onChange: undefined,
  isMinusEnabled: true,
  maxValue: undefined,
}

export default QuantityPicker
