/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useCreateGenericTasks,
  useGetWarehouseTable,
} from 'framework/api/methods'

import { Button, Input, SimpleSelect, TextArea } from 'components/atoms'

export interface GenericTaskCreationProps {
  [x: string]: any
}

export const GenericTaskCreation: FC<GenericTaskCreationProps> = ({
  className,
  ...restProps
}: GenericTaskCreationProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [wareHouseList, setWareHouseList] = useState([])

  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()

  const [formDetails, setFormDetails] = useState({
    warehouse: null,
    title: '',
    description: '',
  })

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                ...item,
                value: item?.code,
                label: item?.code + ' - ' + item?.name,
              }
            },
          )
          setWareHouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  useEffect(() => {
    getWarehouseList()
  }, [])

  const resetForm = () => {
    setFormDetails({
      warehouse: null,
      title: '',
      description: '',
    })
  }

  /** API Call for create generic tasks using react-query */
  const {
    mutate: createGenericTasksMutate,
    isLoading: createGenericTasksIsLoading,
  } = useCreateGenericTasks()

  /** Process the create  generic tasks */
  async function createGenericTasks() {
    createGenericTasksMutate(
      {
        formDetails,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          resetForm()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  return (
    <div
      className={CN(
        'flex items-start justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col w-full h-fit bg-white pt-6 rounded-md shadow-md'>
        <div className='flex flex-col w-full px-4 pb-12'>
          <h2 className='text-lg font-semibold'>Generic Task Creation</h2>

          <div className='w-5/12 flex flex-col'>
            <div className='flex flex-col w-full mt-8'>
              <span className='pb-2 text-N-700'>
                Select Warehouse
                <span className='text-R-500 ml-[2px]'>*</span>
              </span>

              <SimpleSelect
                value={formDetails?.warehouse}
                placeholder='Select Warehouse'
                onChange={(e: any) => {
                  setFormDetails({
                    ...formDetails,
                    warehouse: e,
                  })
                }}
                options={wareHouseList}
              />
            </div>

            <Input
              className='w-full mt-6'
              labelValue='Title '
              isRequired
              value={formDetails?.title}
              placeholder='Ex: Order Picking'
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  title: e.target.value,
                })
              }}
            />

            <TextArea
              labelValue='Description'
              className='w-full mt-6'
              rows={3}
              value={formDetails?.description}
              placeholder='Ex: Order picking from warehouse 001 etc.'
              onChange={(e: any) => {
                setFormDetails({
                  ...formDetails,
                  description: e.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className='border-t border-t-N-200 px-[19px] py-[14px]'>
          <Button
            disabled={
              createGenericTasksIsLoading ||
              !formDetails?.warehouse ||
              !formDetails?.title
            }
            isLoading={createGenericTasksIsLoading}
            onClick={() => {
              createGenericTasks()
            }}>
            Create
          </Button>
        </div>

        <ToastContainer
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
          className='toast-container'
        />
      </div>
    </div>
  )
}

GenericTaskCreation.defaultProps = {}

export default GenericTaskCreation
