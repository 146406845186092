import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface CancelConsignmentOrdersInterface {
  partnerOrderId?: string
}

export const useCancelConsignmentOrders = () => {
  const mutate = useMutation(
    ({ partnerOrderId }: CancelConsignmentOrdersInterface) => {
      return putAPI(`/PartnerOrder/${partnerOrderId}/CancelConsignment`, {})
    },
  )

  return mutate
}

export default useCancelConsignmentOrders
