/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getStockOrderDetailsById = async (reqData: any) => {
  const data = reqData.queryKey[1]
  const res = await get(`/StockOrder/${data.orderId}`)
  return res.data
}

export const useGetStockOrderDetailsById = (
  { ...reqData },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['stockData', reqData], getStockOrderDetailsById, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetStockOrderDetailsById
