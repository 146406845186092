import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface PushOrderInterface {
  partnerOrderId?: string
}

export const usePushOrder = () => {
  const mutate = useMutation(({ partnerOrderId }: PushOrderInterface) => {
    return postAPI(`/PartnerOrders/${partnerOrderId}/PushOrder`, {})
  })

  return mutate
}

export default usePushOrder
