import { FC } from 'react'
import CN from 'classnames'

import './FullSpinner.scss'

export interface FullSpinnerProps {
  className?: string | undefined
  appearance?: 'default' | 'mini' | undefined
}

export const FullSpinner: FC<FullSpinnerProps> = ({
  className,
  appearance,
}: FullSpinnerProps) => {
  return (
    <div className='loadingio-spinner-spinner-hcntay4vmpr'>
      <div className='ldio-tajnsdh1mvf'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

FullSpinner.defaultProps = {
  className: undefined,
  appearance: 'default',
}

export default FullSpinner
