/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  partnerOrderId,
  isEditOrderLineDrawerOpen,
  setIsEditOrderLineDrawerOpen,
  deleteOrderLinePopOut,
  setDeleteOrderLinePopOut,
  selectedOrderStatus,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData?.length > 0) {
      const toBeUpdated = itemData.map((item) => ({
        ...item,
      }))
      setData([...toBeUpdated])
    }
  }, [itemData])

  return (
    <div className='data-table w-full text-sm h-full' {...restProps}>
      <Table
        data={data}
        columns={columns}
        isEditOrderLineDrawerOpen={isEditOrderLineDrawerOpen}
        setIsEditOrderLineDrawerOpen={setIsEditOrderLineDrawerOpen}
        deleteOrderLinePopOut={deleteOrderLinePopOut}
        setDeleteOrderLinePopOut={setDeleteOrderLinePopOut}
        selectedOrderStatus={selectedOrderStatus || ''}
      />
    </div>
  )
}

export default DataTable
