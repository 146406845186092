import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePartnerOrderOrderLinesInterface {
  orderId?: string
  orderLineId?: string
}

export const useDeletePartnerOrderOrderLines = () => {
  const mutate = useMutation(
    ({ orderId, orderLineId }: DeletePartnerOrderOrderLinesInterface) => {
      return deleteAPI(
        `/PartnerOrder/${orderId}/PartnerOrderLine/${orderLineId}`,
      )
    },
  )

  return mutate
}

export default useDeletePartnerOrderOrderLines
