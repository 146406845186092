import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateShipmentPackageInterface {
  wereHouseOrderId?: string
  shipmentId?: string
  packageData?: any
}

export const useCreateShipmentPackage = () => {
  const mutate = useMutation(
    ({
      shipmentId,
      wereHouseOrderId,
      packageData,
    }: CreateShipmentPackageInterface) => {
      return postAPI(
        `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/Package`,
        {
          type: packageData?.packageType?.value,
          qty: Number(packageData?.packageQty),
          width: Number(packageData?.width),
          height: Number(packageData?.height),
          length: Number(packageData?.length),
          weight: Number(packageData?.weight),
        },
      )
    },
  )

  return mutate
}

export default useCreateShipmentPackage
