import { FC } from 'react'
import empty from 'assets/animations/empty.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface JsonSearchEmptyProps {
  className?: string | undefined
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | undefined
}

export const JsonSearchEmpty: FC<JsonSearchEmptyProps> = ({
  size,
}: JsonSearchEmptyProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: empty,
      loop: true,
      autoplay: true,
    }

    const { View } = useLottie(options)

    return View
  }
  return (
    <div
      className={CN('opacity-90', {
        'w-full': size === 'lg',
        'w-1/2': size === 'md',
        'w-1/4': size === 'sm',
        'w-1/6': size === 'xs',
        'w-1/12': size === 'xxs',
      })}>
      <AnimatedView />
    </div>
  )
}

JsonSearchEmpty.defaultProps = {
  className: undefined,
  size: 'xs',
}

export default JsonSearchEmpty
