import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateSimilarOrdersInterface {
  bulkConsignmentId?: string
  rowIds?: any
}

export const useUpdateSimilarOrders = () => {
  const mutate = useMutation(
    ({ bulkConsignmentId, rowIds }: UpdateSimilarOrdersInterface) => {
      return putAPI(
        `/BulkConsignments/${bulkConsignmentId}/WarehouseOrders`,
        rowIds,
      )
    },
  )

  return mutate
}

export default useUpdateSimilarOrders
