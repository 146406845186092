import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetUserPermissionByRoleIdInterface {
  Id?: any
}

export const useGetUserPermissionByRoleId = () => {
  const mutate = useMutation(({ Id }: GetUserPermissionByRoleIdInterface) => {
    return get(`/Identity/Role/${Id}`)
  })

  return mutate
}

export default useGetUserPermissionByRoleId
