import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreatePermissionInRoleInterface {
  Id?: string
  Permission?: string
}

export const useCreatePermissionInRole = () => {
  const mutate = useMutation(
    ({ Id, Permission }: CreatePermissionInRoleInterface) => {
      return postAPI(
        `/Identity/Role/${Id}/Permission?permission=${Permission}`,
        {},
      )
    },
  )

  return mutate
}

export default useCreatePermissionInRole
