import React from 'react'

import { Badge } from 'components/atoms/Badge'

export const columns = [
  {
    customWidth: 120,
    Header: () => '',
    accessor: 'action',
    isActionTrue: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 160,
    Header: () => 'First Name',
    accessor: 'firstName',
    Cell: ({ cell: { value } }) => <div>{value}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Last Name',
    accessor: 'lastName',
    Cell: ({ cell: { value } }) => <div>{value}</div>,
  },
  {
    customWidth: 200,
    Header: 'Email Address',
    accessor: 'email',
    Cell: ({ cell: { value } }) => <div>{value}</div>,
  },
  {
    customWidth: 180,
    Header: 'Phone No',
    accessor: 'phoneNumber',
    Cell: ({ cell: { value } }) => <div>{value}</div>,
  },
  {
    customWidth: 180,
    Header: 'Role',
    accessor: 'roleName',
    Cell: ({ cell: { value } }) => <div>{value}</div>,
  },
  {
    id: 'isActive',
    customWidth: 124,
    Header: () => 'Status',
    accessor: (row) => {
      return row?.isActive ? 'ACTIVE' : 'INACTIVE'
    },
    Cell: ({ row }) => (
      <div>
        {row.original.isActive ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]

export default columns
