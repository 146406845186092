import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import {
  useCreateCutoffTime,
  useGetStarshipITCarriers,
} from 'framework/api/methods'
import { Couriers } from 'static-data/couriers'

import { SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'

export interface AddCutOffTimeProps {
  className?: string | undefined
  [x: string]: any
}

export const AddCutOffTime: FC<AddCutOffTimeProps> = ({
  className,
  isActive,
  onCloseClick,
  wareHouseList,
  notify,
  getCutOffTimeList,
}: AddCutOffTimeProps) => {
  const CutOffTimeClasses = CN(
    'cutoff-time flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )

  const [starshipitCourierList, setStarshipitCourierList] = useState<any>([])

  const [cutoffTimeDetails, setCutoffTimeDetails] = useState<any>({
    warehouseCode: '',
    warehouseName: '',
    carrierName: '',
    carrierCode: '',
    minutes: null,
    seconds: null,
    meridian: { label: 'AM', value: 'AM' },
    status: false,
    subCarrier: null,
  })

  const [isErrorField, setIsErrorField] = useState<any>({
    warehouseName: false,
    carrierName: false,
    minutes: false,
    seconds: false,
    meridian: false,
    subCarrier: false,
  })

  /** API Call for create cutoff time using react-query */
  const {
    mutate: createCutoffTimeMutate,
    isLoading: createCutoffTimeIsLoading,
  } = useCreateCutoffTime()

  /** Process the create cutoff time */
  async function addCutoffTime() {
    createCutoffTimeMutate(
      {
        warehouseCode: cutoffTimeDetails?.warehouseCode,
        warehouseName: cutoffTimeDetails?.warehouseName,
        courierCode: cutoffTimeDetails?.carrierCode,
        courierName: cutoffTimeDetails?.carrierName,
        cutoffTime: `${cutoffTimeDetails?.minutes?.value}:${cutoffTimeDetails?.seconds?.value} ${cutoffTimeDetails?.meridian?.value}`,
        subCourierName: cutoffTimeDetails?.subCarrier?.label || null,
        subCourierCode: cutoffTimeDetails?.subCarrier?.value || null,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          onCloseClick()
          getCutOffTimeList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  const validateInputs = () => {
    const {
      warehouseName,
      carrierName,
      minutes,
      seconds,
      meridian,
      subCarrier,
    } = cutoffTimeDetails

    setIsErrorField({
      ...isErrorField,
      warehouseName: warehouseName ? false : true,
      carrierName: carrierName ? false : true,
      minutes: minutes ? false : true,
      seconds: seconds ? false : true,
      meridian: meridian ? false : true,
      subCarrier: carrierName === 'Starshipit' && !subCarrier ? true : false,
    })

    const isAllValidFields =
      warehouseName &&
      carrierName &&
      minutes &&
      seconds &&
      meridian &&
      !(carrierName === 'Starshipit' && !subCarrier)
    return isAllValidFields
  }

  const handleAddClick = () => {
    const valid = validateInputs()

    if (valid) {
      addCutoffTime()
    }
  }

  const getStarshipITCarriersOnSuccess = (data: any) => {
    setStarshipitCourierList(data?.starshipitCarrierCodes || [])
  }

  /** Function On-Error Get  Curriers of starshipit */
  const getStarshipITCarriersOnError = (error: any) => {
    notify(error.message || 'Error...!', 'error')
  }

  /** API Call for Get Curriers of starshipit */
  const { refetch: getStarshipITCarriers } = useGetStarshipITCarriers(
    {},
    getStarshipITCarriersOnSuccess,
    getStarshipITCarriersOnError,
  )

  useEffect(() => {
    isActive && getStarshipITCarriers()
  }, [isActive])

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Add New Cut-off Time'>
      <div className={CutOffTimeClasses}>
        <div className='flex flex-col w-full mt-1'>
          <span className='pb-2 text-N-700'>
            Warehouse Name
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>

          <SimpleSelect
            value={
              cutoffTimeDetails?.warehouseName
                ? {
                    label: cutoffTimeDetails?.warehouseName,
                    value: cutoffTimeDetails?.warehouseCode,
                  }
                : null
            }
            onChange={(e: any) => {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                warehouseName: e.label,
                warehouseCode: e.code,
              })
            }}
            options={wareHouseList}
            isError={isErrorField?.warehouseName}
          />
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Carrier Name
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>

          <SimpleSelect
            value={
              cutoffTimeDetails?.carrierName
                ? {
                    label: cutoffTimeDetails?.carrierName,
                    value: cutoffTimeDetails?.carrierCode,
                  }
                : null
            }
            onChange={(e: any) => {
              setCutoffTimeDetails({
                ...cutoffTimeDetails,
                carrierName: e.label,
                carrierCode: e.value,
                subCarrier: null,
              })
            }}
            options={Couriers}
            isError={isErrorField?.carrierName}
          />
        </div>

        {cutoffTimeDetails?.carrierName === 'Starshipit' && (
          <div className='flex flex-col w-full'>
            <span className='pb-2 text-N-700'>
              Starshipit Carrier Name
              <span className='text-R-500 ml-[2px]'>*</span>
            </span>

            <SimpleSelect
              value={
                cutoffTimeDetails?.subCarrier
                  ? cutoffTimeDetails?.subCarrier
                  : null
              }
              onChange={(e: any) => {
                setCutoffTimeDetails({
                  ...cutoffTimeDetails,
                  subCarrier: e,
                })
              }}
              options={starshipitCourierList}
              isError={isErrorField?.subCarrier}
            />
          </div>
        )}

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>
            Select Cut-off Time
            <span className='text-R-500 ml-[2px]'>*</span>
          </span>

          <div className='flex gap-x-2 w-full justify-start items-center'>
            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.minutes}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    minutes: e,
                  })
                }}
                options={Array.from({ length: 12 }, (_, index) => {
                  const value = (index + 1)?.toString()?.padStart(2, '0')
                  return { label: value, value: value }
                })}
                isError={isErrorField?.minutes}
                placeholder='--'
              />
            </div>

            <div className='text-sm'>:</div>

            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.seconds}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    seconds: e,
                  })
                }}
                options={Array?.from({ length: 60 }, (_, index) => {
                  const value = index?.toString()?.padStart(2, '0')
                  return { label: value, value: value }
                })}
                isError={isErrorField?.seconds}
                placeholder='--'
              />
            </div>

            <div className='w-1/3'>
              <SimpleSelect
                value={cutoffTimeDetails?.meridian}
                onChange={(e: any) => {
                  setCutoffTimeDetails({
                    ...cutoffTimeDetails,
                    meridian: e,
                  })
                }}
                options={[
                  { label: 'AM', value: 'AM' },
                  { label: 'PM', value: 'PM' },
                ]}
                // placeholder='--'
                isError={isErrorField?.meridian}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-2 text-N-700'>Status</span>
          <ToggleSwitch
            text={'Active'}
            setValue={true}
            isDisabled
            onToggleClick={(e: any) => {
              //
            }}
          />
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={createCutoffTimeIsLoading}
          disabled={createCutoffTimeIsLoading}
          isFullWidth
          onClick={() => {
            handleAddClick()
          }}>
          Save
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

AddCutOffTime.defaultProps = {
  className: undefined,
}

export default AddCutOffTime
