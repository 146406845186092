import React, { FC } from 'react'
import CN from 'classnames'

export interface Error404Props {
  [x: string]: any
}

export const Error404: FC<Error404Props> = ({
  className,
  ...restProps
}: Error404Props) => {
  const Error404Classes = CN(
    `error404 flex items-center justify-center w-full h-screen bg-N-100 font-bold`,
    className,
    {},
  )

  return (
    <div className={Error404Classes} {...restProps}>
      error404 is working...
    </div>
  )
}

Error404.defaultProps = {}

export default Error404
