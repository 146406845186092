import { FC } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components/atoms/Button'

export interface OrderFooterProps {
  [x: string]: any
  onClickCancelConsignmentButton?: any
  isCancelConsignmentOrderBtn?: boolean | undefined
  orderDetails?: any
}

/** This is OrderFooter */

export const OrderFooter: FC<OrderFooterProps> = ({
  onClick,
  isLoading,
  isCancelOrderBtn,
  isRetryOrderBtn,
  isCancelConsignmentOrderBtn,
  onClickCancelButton,
  onClickCancelConsignmentButton,
  orderDetails,
}: OrderFooterProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const permissionForOrderRetry = userDetails?.userPermissions?.includes(
    'WarehouseOrderRetryAccess',
  )

  const permissionForOrderCancel = userDetails?.userPermissions?.includes(
    'PartnerOrderCancelAccess',
  )

  const permissionForConsignOrderCancel =
    userDetails?.userPermissions?.includes('OrderConsignmentCancel')

  return (
    <div className='footer_container flex w-full pb-3 px-4 pt-4 border-t border-t-N-200 sticky bottom-0 bg-white z-[1]'>
      {isRetryOrderBtn && permissionForOrderRetry && (
        <Button
          className='mr-5'
          onClick={onClick}
          isLoading={isLoading}
          disabled={isLoading}>
          Retry Order
        </Button>
      )}

      {isCancelOrderBtn && permissionForOrderCancel && (
        <Button
          className='text-R-500 border-R-500 hover:bg-N-50'
          appearance='outline'
          isLoading={isLoading}
          disabled={isLoading}
          onClick={onClickCancelButton}>
          Cancel Order
        </Button>
      )}

      {isCancelConsignmentOrderBtn &&
        permissionForConsignOrderCancel &&
        orderDetails?.partnerOrderConsignment?.carrierName !== 'STARSHIPIT' && (
          <Button
            className='text-R-500 border-R-500 hover:bg-N-50'
            appearance='outline'
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onClickCancelConsignmentButton}>
            Cancel Consignment Order
          </Button>
        )}
    </div>
  )
}

OrderFooter.defaultProps = {
  isCancelOrderBtn: true,
  isRetryOrderBtn: false,
  isCancelConsignmentOrderBtn: false,
  onClickCancelConsignmentButton: undefined,
}

export default OrderFooter
