import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetUserPrivilegesTableInterface {
  id?: any
}

export const useGetUserPrivilegesTable = () => {
  const mutate = useMutation(({ id }: GetUserPrivilegesTableInterface) => {
    return get(`/identity/permissions`)
  })

  return mutate
}

export default useGetUserPrivilegesTable
