import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetUserRolesTableInterface {
  id?: any
}

export const useGetUserRolesTable = () => {
  const mutate = useMutation(({ id }: GetUserRolesTableInterface) => {
    return get(`/identity/role`)
  })

  return mutate
}

export default useGetUserRolesTable
