import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'

import { Badge } from 'components/atoms/Badge'

export const columns = [
  {
    customWidth: 120,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 150,
    Header: () => 'Partner Code',
    accessor: 'code',
    Cell: ({ row }) => <div>{row.original.code}</div>,
  },
  {
    customWidth: 340,
    Header: () => 'Partner Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 180,
    Header: 'Integration',
    accessor: 'Integration',
    Cell: ({ row }) => {
      return (
        <div className='flex items-center flex-wrap'>
          {row.original.partnerOrderIntegrations.map((integration, idx) => {
            if (integration.name === 'Shopify') {
              return (
                <img
                  key={idx}
                  src={IconShopify}
                  alt='Shopify'
                  className='w-6 h-6 mr-2'
                />
              )
            } else if (integration.name === 'Woocommerce') {
              return (
                <img
                  key={idx}
                  src={Iconwoocommerce}
                  alt='Woocommerce'
                  className='w-6 h-6 mr-2'
                />
              )
            } else if (integration.name === 'PushApi') {
              return (
                <img
                  key={idx}
                  src={IconPushAPI}
                  alt='IconPushAPI'
                  className='w-6 h-6 mr-2'
                />
              )
            }
          })}
        </div>
      )
    },
  },
  {
    customWidth: 180,
    Header: 'No of Integration',
    accessor: (row) => row.partnerOrderIntegrations.length,
    Cell: ({ row }) => (
      <div>{row.original.partnerOrderIntegrations?.length}</div>
    ),
  },
  {
    id: 'isActive',
    customWidth: 124,
    Header: () => 'Status',
    accessor: (row) => {
      return row?.isActive ? 'ACTIVE' : 'INACTIVE'
    },
    Cell: ({ row }) => (
      <div>
        {row.original.isActive ? (
          <Badge appearance='success' labelValue='ACTIVE' />
        ) : (
          <Badge appearance='error' labelValue='INACTIVE' />
        )}
      </div>
    ),
  },
]

export default columns
