import { FC } from 'react'
import { TabPanel } from 'react-tabs'
import CN from 'classnames'

import { ShippingLineSelection } from 'components/molecules/ShippingLineSelection'

export interface TabDirectProps {
  className?: string | undefined
  shipment: any
  searchForSavedQuotes: any
  selectedOrderId?: any
  notify?: any
  callOrderShipmentsById?: any
  setMainLoadingState?: any
}

export const TabDirect: FC<TabDirectProps> = ({
  className,
  shipment,
  searchForSavedQuotes,
  selectedOrderId,
  notify,
  callOrderShipmentsById,
  setMainLoadingState,
}: TabDirectProps) => {
  return (
    <div className='w-full pt-6 border-t border-t-N-200'>
      {shipment.shipmentConsignmentQuotes &&
        shipment.shipmentConsignmentQuotes.length > 0 &&
        shipment.shipmentConsignmentQuotes.map((quote: any, index: any) => {
          const obj = {
            type: quote.type,
            productName: quote.productName,
            shipmentId: shipment.id,
            productId: quote.productId,
            cost: quote.cost,
            originCharges: [
              {
                type: 'Standard Charge',
                price: quote.standardCharge,
              },
              {
                type: 'Total Freight Charge',
                price: quote.totalFreightCharge,
              },
              {
                type: 'Fuel Levy',
                price: quote.fuelLevy,
              },
              {
                type: 'Fuel Levy Charge',
                price: quote.fuelLevyCharge,
              },
              {
                type: 'On Forward Charge',
                price: quote.onForwardCharge,
              },
            ],
          }
          if (quote.type === 'DIRECT') {
            return (
              <ShippingLineSelection
                key={index}
                isViewOnly={
                  shipment?.status === 'CLOSED' ||
                  shipment?.status === 'CANCELLED'
                }
                ShippingLineObject={obj}
                selectedOrderId={selectedOrderId}
                dateWanted={shipment?.dateWanted}
                notify={notify}
                callOrderShipmentsById={callOrderShipmentsById}
                setMainLoadingState={setMainLoadingState}
              />
            )
          }
        })}
      {shipment.shipmentConsignmentQuotes &&
        searchForSavedQuotes(shipment.shipmentConsignmentQuotes, 'DIRECT')}
    </div>
  )
}

TabDirect.defaultProps = {
  className: undefined,
}

export default TabDirect
