import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateAgencyTasksInterface {
  formDetails?: any
}

export const useCreateAgencyTasks = () => {
  const mutate = useMutation(({ formDetails }: CreateAgencyTasksInterface) => {
    return postAPI(`/Tasks/AgencyTask/Create`, {
      genericTaskId: formDetails?.genericTask?.value,
      partnerId: formDetails?.partner?.id,
      title: formDetails?.title,
      description: formDetails?.description,
      estimatedHours: formDetails?.estimatedHours?.label,
      dueDate: formDetails?.dueDate,
    })
  })

  return mutate
}

export default useCreateAgencyTasks
