import { useMutation } from 'react-query'
import { put } from 'framework/api/http'

const userEdit = async (reqBody: any) => {
  const res = await put('/UserProfile', reqBody)
  return res.data
}

export const useUserEdit = (onSettled: any, onError: any) => {
  return useMutation(userEdit, {
    onSettled,
    onError,
  })
}
