/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface VerifyUserNameValidityInterface {
  Username: string
}

export const useVerifyUserNameValidity = () => {
  const mutate = useMutation(
    ({ Username }: VerifyUserNameValidityInterface) => {
      return get(`Identity/Username?UserName=${Username}`)
    },
  )

  return mutate
}

export default useVerifyUserNameValidity
