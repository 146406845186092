import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ConfirmEmailInterface {
  username: string
  token: string
}

export const useConfirmEmail = () => {
  const mutate = useMutation(({ username, token }: ConfirmEmailInterface) => {
    return postAPI('identity/VerifyEmailToken', {
      username: username,
      token: token,
    })
  })

  return mutate
}

export default useConfirmEmail
