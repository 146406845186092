/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useGetInvoiceTable, useGetPartnerTable } from 'framework/api/methods'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

import { Button, SimpleSelect } from 'components/atoms'
import {
  AddInvoice,
  UpdateInvoice,
} from 'components/DrawerModals/BillingModule'
import ViewInvoice from 'components/DrawerModals/BillingModule/ViewInvoice'

import { DataTable } from './BillingModuleDataTable'

import 'react-toastify/dist/ReactToastify.css'

export interface BillingModuleProps {
  [x: string]: any
}
export const BillingModule: FC<BillingModuleProps> = ({
  className,
}: BillingModuleProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)
  const billingModuleEditAccess =
    userDetails?.userPermissions?.includes('InvoiceEditAccess')
  const [rows, setRows] = useState({})
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [addInvoiceDrawerIsOpen, setAddInvoiceDrawerIsOpen] = useState(false)
  const [updateInvoiceDrawerIsOpen, setUpdateInvoiceDrawerIsOpen] =
    useState(false)
  const [viewInvoiceDrawerIsOpen, setViewInvoiceDrawerIsOpen] = useState(false)
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })
  const years = Array.from(
    new Array(30),
    (val, index) => new Date().getFullYear() - index,
  )
  const months = moment.months().map((name, index) => ({
    value: index + 1, // moment.js month index starts at 0
    name: name,
  }))
  const date = new Date()
  const [isFilterMenuOpen, setIsFilterModalOpen] = useState(false)
  const [filterVariables, setFilterVariables] = useState<any>({
    partner: { label: 'All', value: '', id: '' },
    month: { name: 'All', value: '' },
    year: { name: 'All', value: '' },
    dateRaisedFrom: '',
    dateRaisedTo: '',
    dueDateFrom: '',
    dueDateTo: '',
    invoiceStatus: { label: 'All', value: '' },
  })
  const [partnerList, setPartnerList] = useState([])
  const [filterActive, setFilterActive] = useState(false)
  const statusOptions = [
    { value: 'Due', label: 'Due' },
    { value: 'Overdue', label: 'Overdue' },
    { value: 'Paid', label: 'Paid' },
  ]
  const [searchInvoiceById, setSearchInvoiceById] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  function getMonthName(monthNumber: any) {
    const monthIndex = parseInt(monthNumber, 10) - 1 // Convert string to number and adjust for zero-based index
    const date = new Date(0, monthIndex)
    return date.toLocaleString('default', { month: 'long' })
  }
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  /** API Call for get invoice list using react-query */
  const { mutate: getInvoiceListMutate, isLoading: getInvoiceListIsLoading } =
    useGetInvoiceTable()
  /** Process the get invoice table data */
  async function getInvoiceList(
    take?: number,
    skip?: number,
    search?: string,
    partner?: any,
    year?: any,
    month?: any,
    invoiceStatus?: any,
    dueDateFrom?: any,
    dueDateTo?: any,
    dateRaisedFrom?: any,
    dateRaisedTo?: any,
  ) {
    getInvoiceListMutate(
      {
        take,
        skip,
        search,
        partner,
        year,
        month,
        invoiceStatus,
        dueDateFrom,
        dueDateTo,
        dateRaisedFrom,
        dateRaisedTo,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData?.invoices)
          setTotalRowCount(successData?.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  /** API Call for get partner list function using react-query */
  const { mutate: getPartnerTableMutate, isLoading: getPartnerTableIsLoading } =
    useGetPartnerTable()
  /** Process to get partner List for integration */
  async function getPartnerList(): Promise<void> {
    getPartnerTableMutate(
      { take: 100, skip: 0, Search: '' },
      {
        onSuccess: ({ data: successData }: any) => {
          const result: any = []
          successData.partners.forEach((item: any) => {
            if (item.isActive) {
              result.push({ label: item.name, value: item?.code, id: item?.id })
            }
          })
          setPartnerList(result)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  /** call warehouses list when table variables change */
  useEffect(() => {
    getInvoiceList(
      tableVariables.take,
      tableVariables.skip,
      tableVariables.search,
      filterVariables.partner.value != '' && filterVariables.partner != null
        ? filterVariables.partner.value
        : '',
      filterVariables.year.value != '' ? filterVariables.year.value : '',
      filterVariables.month.value != ''
        ? getMonthName(filterVariables.month.value)
        : '',
      filterVariables.invoiceStatus != null &&
        filterVariables.invoiceStatus.value != ''
        ? filterVariables.invoiceStatus.value
        : '',
      filterVariables.dueDateFrom,
      filterVariables.dueDateTo,
      filterVariables.dateRaisedFrom,
      filterVariables.dateRaisedTo,
    )
  }, [tableVariables])
  useEffect(() => {
    getPartnerList()
  }, [])
  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        search: searchInvoiceById,
      })
    }
  }
  function FormatDate(dateString: string) {
    // Assuming the input gives you a string like "Thu May 09 2024 00:00:00 GMT 1000 (Australian Eastern Standard Time)"
    // Create a new Date object from the string
    const dateObject = new Date(dateString)
    // Format the date as YYYY-MM-DD
    const year = dateObject.getFullYear()
    const month = dateObject.getMonth() + 1 // getMonth() returns month index (0-11), so add 1 for the actual month
    const day = dateObject.getDate()
    const formattedDate = `${year}/${month.toString().padStart(2, '0')}/${day
      .toString()
      .padStart(2, '0')}`
    return formattedDate
  }
  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7 overflow-auto'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>Billing & Invoices</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            {billingModuleEditAccess && (
              <div className='w-1/3'>
                <Button
                  onClick={() => {
                    setAddInvoiceDrawerIsOpen(true)
                  }}>
                  <i className='ri-add-circle-line pr-2'></i>Upload Invoice
                </Button>
              </div>
            )}
            <div className='flex flex-col w-[432px] pl-2 relative'>
              <input
                onChange={(e: any) => {
                  setSearchInvoiceById(e.target.value)
                }}
                onKeyPress={(e: any) => {
                  handleSearchKeyPress(e)
                }}
                type='text'
                placeholder='Search by Invoice Id'
                className='pr-4 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
              />
              <i
                className='ri-search-2-line absolute top-0 bottom-0 flex items-center left-[22px] text-1xl text-N-500 cursor-pointer'
                onClick={() => {
                  setTableVariables({
                    ...tableVariables,
                    search: searchInvoiceById,
                  })
                }}></i>
            </div>
          </div>
          <div className='flex w-full items-center justify-between pt-6'>
            <Button
              onClick={() => {
                setIsFilterModalOpen(!isFilterMenuOpen)
              }}
              appearance={isFilterMenuOpen ? 'default' : 'outline'}>
              <i className='ri-filter-3-line pr-3'></i> Filter
            </Button>
            {filterActive && (
              <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[0px] right-[-2px]'></div>
            )}
          </div>
          {/* expanding filter panel with open close animation */}
          <AnimatePresence initial={false}>
            {isFilterMenuOpen && (
              <motion.div
                className='flex flex-col bg-white w-full'
                initial='collapsed'
                animate='open'
                exit='collapsed'
                transition={{ type: 'tween', duration: 0.3 }}
                variants={{
                  open: { opacity: 1, y: 0, height: 'auto' },
                  collapsed: { opacity: -1, y: -35, height: 0 },
                }}>
                {/** Input field of filtering section */}
                <div className='flex flex-col w-full mb-4 py-2 z-30'>
                  <div className='flex flex-row w-full gap-x-5'>
                    <div className='flex flex-col w-1/3 z-[3]'>
                      <span className='pb-2 text-N-700'>by partner</span>
                      <SimpleSelect
                        value={filterVariables?.partner}
                        options={[{ label: 'All', value: '' }, ...partnerList]}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            partner: {
                              label: e.label,
                              value: e.value,
                            },
                          })
                        }}
                      />
                    </div>
                    <div className='flex flex-col w-1/3 z-[10]'>
                      <span className='pb-2 text-N-700'>by Month</span>
                      <select
                        placeholder='Month'
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            month: {
                              name: getMonthName(e.target.value),
                              value: e.target.value,
                            },
                          })
                        }}
                        value={filterVariables?.month.value}
                        className='month-selector'>
                        <option value=''>All</option>
                        {months.map((m) => (
                          <option key={m.value} value={m.value}>
                            {m.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex flex-col w-1/3 z-[2]'>
                      <span className='pb-2 text-N-700'>Date Raised</span>
                      <div className='date-picker mt-0'>
                        <DateRangePickerComponent
                          format='yyyy/MM/dd'
                          delayUpdate={true}
                          placeholder='Select a range'
                          onChange={(e: any) => {
                            setFilterVariables({
                              ...filterVariables,
                              dateRaisedFrom: FormatDate(e.target.startDate),
                              dateRaisedTo: FormatDate(e.target.endDate),
                            })
                          }}
                          startDate={filterVariables?.dateRaisedFrom}
                          endDate={filterVariables?.dateRaisedTo}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-row w-full gap-x-5'>
                    <div className='flex flex-col w-1/3 z-[2]'>
                      <span className='pb-2 text-N-700'>Invoice Status</span>
                      <SimpleSelect
                        value={filterVariables?.invoiceStatus}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            invoiceStatus: {
                              label: e.label,
                              value: e.value,
                            },
                          })
                        }}
                        options={[
                          { label: 'All', value: '' },
                          ...statusOptions,
                        ]}
                      />
                    </div>
                    <div className='flex flex-col w-1/3 z-[2]'>
                      <span className='pb-2 text-N-700'>by Year</span>
                      <select
                        placeholder='Year'
                        value={filterVariables?.year.value}
                        onChange={(e: any) => {
                          setFilterVariables({
                            ...filterVariables,
                            year: {
                              name: parseInt(e.target.value, 10),
                              value: e.target.value,
                            },
                          })
                        }}
                        className='year-selector'>
                        <option value=''>All</option>
                        {years.map((y) => (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='flex flex-col w-1/3 z-[2]'>
                      <span className='pb-2 text-N-700'>Due Date</span>
                      <div className='date-picker mt-0'>
                        <DateRangePickerComponent
                          format='yyyy/MM/dd'
                          delayUpdate={true}
                          placeholder='Select a range'
                          onChange={(e: any) => {
                            setFilterVariables({
                              ...filterVariables,
                              dueDateFrom: FormatDate(e.target.startDate),
                              dueDateTo: FormatDate(e.target.endDate),
                            })
                          }}
                          startDate={filterVariables?.dueDateFrom}
                          endDate={filterVariables?.dueDateTo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex gap-x-4 mt-2'>
                  <Button
                    onClick={() => {
                      filterVariables.partner.value === '' &&
                      filterVariables.month.value === '' &&
                      filterVariables.year === '' &&
                      filterVariables.invoiceStatus === '' &&
                      filterVariables.dueDateFrom === '' &&
                      filterVariables.dueDateTo === '' &&
                      filterVariables.dateRaisedFrom === '' &&
                      filterVariables.dateRaisedTo === ''
                        ? setFilterActive(false)
                        : setFilterActive(true)
                      getInvoiceList(
                        tableVariables.take,
                        tableVariables.skip,
                        tableVariables.search,
                        filterVariables.partner.value != ''
                          ? filterVariables.partner.value
                          : '',
                        filterVariables.year.value != ''
                          ? filterVariables.year.value
                          : '',
                        filterVariables.month.value != ''
                          ? getMonthName(filterVariables.month.value)
                          : '',
                        filterVariables.invoiceStatus != ''
                          ? filterVariables.invoiceStatus.value
                          : '',
                        filterVariables.dueDateFrom,
                        filterVariables.dueDateTo,
                        filterVariables.dateRaisedFrom,
                        filterVariables.dateRaisedTo,
                      )
                      setIsFilterModalOpen(false)
                      setPageNumber(1)
                    }}>
                    Apply
                  </Button>
                  <Button
                    appearance='dull'
                    onClick={() => {
                      const date = new Date()
                      setFilterVariables({
                        partner: { label: 'All', value: '' },
                        month: { label: 'All', value: '' },
                        year: { label: 'All', value: '' },
                        invoiceStatus: { label: 'All', value: '' },
                        dateRaisedFrom: '',
                        dateRaisedTo: '',
                        dueDateFrom: '',
                        dueDateTo: '',
                      })
                      setFilterActive(false)
                      setIsFilterModalOpen(false)
                      getInvoiceList(
                        tableVariables.take,
                        tableVariables.skip,
                        tableVariables.search,
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                        '',
                      )
                    }}>
                    Clear
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className='w-full h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setTableVariables={setTableVariables}
              tableVariables={tableVariables}
              totalRowCount={totalRowCount}
              setUpdateInvoiceDrawerIsOpen={setUpdateInvoiceDrawerIsOpen}
              setViewInvoiceDrawerIsOpen={setViewInvoiceDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getInvoiceListIsLoading={getInvoiceListIsLoading}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </div>
          {addInvoiceDrawerIsOpen && (
            <AddInvoice
              isActive={addInvoiceDrawerIsOpen}
              onCloseClick={() => setAddInvoiceDrawerIsOpen(false)}
              notify={notify}
              partnerList={partnerList}
              getInvoiceList={getInvoiceList}
            />
          )}
          {updateInvoiceDrawerIsOpen && (
            <UpdateInvoice
              isActive={updateInvoiceDrawerIsOpen}
              onCloseClick={() => setUpdateInvoiceDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              notify={notify}
              partnerList={partnerList}
              getInvoiceList={getInvoiceList}
            />
          )}
          {viewInvoiceDrawerIsOpen && (
            <ViewInvoice
              isActive={viewInvoiceDrawerIsOpen}
              onCloseClick={() => setViewInvoiceDrawerIsOpen(false)}
              selectedRowByButtonAction={selectedRowByButtonAction}
              notify={notify}
              partnerList={partnerList}
              getInvoiceList={getInvoiceList}
            />
          )}
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}
BillingModule.defaultProps = {}
export default BillingModule
