import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteStockOrderOrderLinesInterface {
  orderId?: string
  orderLineId?: string
}

export const useDeleteStockOrderOrderLines = () => {
  const mutate = useMutation(
    ({ orderId, orderLineId }: DeleteStockOrderOrderLinesInterface) => {
      return deleteAPI(`/StockOrder/${orderId}/StockOrderLine/${orderLineId}`)
    },
  )

  return mutate
}

export default useDeleteStockOrderOrderLines
