export const columns = [
  {
    customWidth: 163,
    Header: () => 'Product Code',
    accessor: 'warehouseSKU',
    Cell: ({ row }) => <div>{row.original.warehouseSKU}</div>,
  },
  {
    customWidth: 341,
    Header: () => 'Product Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 45,
    Header: 'Total Qty',
    accessor: 'qty',
    Cell: ({ row }) => <div>{row.original.qty}</div>,
  },
]

export default columns
