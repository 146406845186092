/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CN from 'classnames'
import { useResetPassword } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Logo } from 'components/atoms/Logo'
import { PopOut } from 'components/atoms/PopOut'

export interface ResetPasswordProps {
  [x: string]: any
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  setLogInComponent,
  setUserData,
  userData,
  ...restProps
}: ResetPasswordProps) => {
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isResetPasswordButtonDisable, setIsResetPasswordButtonDisable] =
    useState(true)
  const [resetPasswordError, setResetPasswordError] = useState<any>()
  const [resetPasswordData, setResetPasswordData] = useState<any>()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  /** API Call for resetting password using react-query */
  const { mutate: resetPasswordMutate, isLoading: resetPasswordIsLoading } =
    useResetPassword()

  /** set to the default value Reset-password errors & Messages */
  const setDefault = () => {
    setResetPasswordError({
      ...resetPasswordError,
      isResetPasswordError: false,
      message: '',
    })
    setResetPasswordData({
      ...resetPasswordData,
      isResetPasswordSuccess: false,
      data: {},
    })
  }

  /** Onload Functions */
  useEffect(() => {
    setUserData({
      ...userData,
      newPassword: '',
      confirmPassword: '',
    })
    setDefault()
  }, [])

  /** Process the Reset password submission */
  async function handleResetPasswordSubmit(event: any) {
    event.preventDefault()
    setDefault()
    resetPasswordMutate(
      {
        username: searchParams.get('user') || '',
        resetToken: searchParams.get('token') || '',
        password: userData.confirmPassword,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          setResetPasswordData({
            ...resetPasswordData,
            isResetPasswordSuccess: true,
            data: successData,
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          setResetPasswordError({
            ...resetPasswordError,
            isResetPasswordError: true,
            message: errData.message,
          })
        },
      },
    )
  }

  useEffect(() => {
    userData.newPassword &&
    userData.confirmPassword &&
    userData.newPassword === userData.confirmPassword
      ? setIsResetPasswordButtonDisable(false)
      : setIsResetPasswordButtonDisable(true)
  }, [userData])

  return (
    <div
      className='w-[520px] bg-white py-[71px] px-9 rounded-[20px] shadow-2xl'
      {...restProps}>
      <div className='w-full h-full'>
        <Logo />

        <div className='flex flex-col w-full pt-[38px]'>
          <span className='text-3xl text-N-700 font-bold'>Reset Password</span>
          <span className='text-gray-800 pt-3'>
            It’s time to change your password to a new one.
          </span>
        </div>

        {!resetPasswordData?.isResetPasswordSuccess && (
          <>
            <div className='mt-6 relative'>
              <label
                htmlFor='newPassword'
                className='text-base text-N-900 bg-white font-semibold'>
                New Password
              </label>
              <input
                type={showNewPassword ? 'text' : 'password'}
                id='newPassword'
                value={userData.newPassword}
                onChange={(e) => {
                  setUserData({ ...userData, newPassword: e.target.value })
                }}
                className={CN(
                  'z-10 mt-4 text-N-900 text-sm rounded-[10px] w-full p-[13px] outline-none focus:ring-1 focus:ring-P-500',
                  {
                    'ring-1 ring-P-500 bg-white':
                      userData?.newPassword?.length > 0,
                    'bg-N-100':
                      userData?.newPassword?.length === 0 ||
                      userData?.newPassword?.length === undefined,
                  },
                )}
                placeholder='New Password'
                required
              />
              <i
                className={
                  showNewPassword
                    ? 'ri-eye-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
                    : 'ri-eye-off-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
                }
                onClick={() => {
                  setShowNewPassword(!showNewPassword)
                }}></i>
            </div>

            <div className='mt-4 relative'>
              <label
                htmlFor='confirmPassword'
                className='text-base text-N-900 bg-white font-semibold'>
                Confirm New Password
              </label>
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                id='confirmPassword'
                value={userData.confirmPassword}
                onChange={(e) => {
                  setUserData({ ...userData, confirmPassword: e.target.value })
                }}
                className={CN(
                  'z-10 mt-4 text-N-900 text-sm rounded-[10px] w-full p-[13px] outline-none focus:ring-1 focus:ring-P-500',
                  {
                    'ring-1 ring-R-500 bg-R-50':
                      userData?.confirmPassword?.length > 0 &&
                      userData.newPassword !== userData.confirmPassword,
                    'ring-1 ring-P-500 bg-white':
                      userData?.confirmPassword?.length > 0 &&
                      userData.newPassword === userData.confirmPassword,
                    'bg-N-100':
                      userData?.confirmPassword?.length === 0 ||
                      userData?.confirmPassword?.length === undefined,
                  },
                )}
                placeholder='Confirm Password'
                required
              />
              <i
                className={
                  showConfirmPassword
                    ? 'ri-eye-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
                    : 'ri-eye-off-line hover:cursor-pointer absolute right-5 top-[57%] text-N-800 text-lg z-50'
                }
                onClick={() => {
                  setShowConfirmPassword(!showConfirmPassword)
                }}></i>
            </div>

            {resetPasswordError?.isResetPasswordError && (
              <PopOut
                className='mt-6'
                size='small'
                isShowCloseButton={false}
                appearance='error'>
                {resetPasswordError?.message}
              </PopOut>
            )}

            <div className='pt-7'>
              <Button
                onClick={(e: any) => {
                  handleResetPasswordSubmit(e)
                }}
                disabled={
                  resetPasswordIsLoading || isResetPasswordButtonDisable
                }
                isLoading={resetPasswordIsLoading}
                size='lg'
                isFullWidth>
                Reset My Password
              </Button>
            </div>
          </>
        )}

        {resetPasswordData?.isResetPasswordSuccess && (
          <div className='pt-7'>
            <PopOut size='small' isShowCloseButton={false} appearance='success'>
              {resetPasswordData?.data?.message}
            </PopOut>
            <div className='flex pt-[38px] items-center justify-center cursor-pointer'>
              <p
                onClick={() => {
                  navigate('/auth/login', { replace: true })
                  setLogInComponent('logIn')
                }}
                className='flex justify-center items-center text-P-700 text-sm'>
                <i className='ri-arrow-left-line pr-4'></i> Back to Login
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

ResetPassword.defaultProps = {}

export default ResetPassword
