/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetWarehouseListInterface {
  id: number
}

export const useGetWarehouseList = () => {
  const mutate = useMutation(({ id }: GetWarehouseListInterface) => {
    return get(`/Facade/Dropdown/ListWarehouse`)
  })

  return mutate
}

export default useGetWarehouseList
