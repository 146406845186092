const carriersList = [
  'Aceit Couriers',
  'Action Express',
  'Active',
  'Advance Transport',
  'Air Road Transport',
  'AirRoad Distribution',
  'Albury Freight Distribution',
  'All Purpose Transport',
  'Allied Express',
  'Alpha Freight',
  'ANC',
  'APD Couriers',
  'Aramex',
  'ATC Couriers',
  'Aussiefast Transport',
  'Austracold',
  'Australia Post',
  'Auz Country Carriers',
  'Bagtrans',
  'Bass Strait Transport',
  'Blacks Transport',
  'Bluestar Logistics',
  'Bohaul Express',
  'Bonds Transport',
  'Booth Transport',
  'Border Express',
  'Budget Couriers',
  'Buffalo Transport',
  'Burra Couriers',
  "Cameron's Transport",
  'Canberra Country',
  'Capital Transport National',
  'Casella Transport',
  'Centurion Transport',
  'Chill Logistics',
  'Cochranes Transport',
  'Collins Couriers',
  'Comet Couriers',
  'Concept Logistics',
  'Cope Sensitive Freight',
  'Courier Express',
  'Couriers Please',
  'CRL Express',
  'CTI Core Carrier',
  'CTI Couriers',
  'CTI Express',
  'CTI Logistics - Interstate',
  'CTI Logistics Regional Freight',
  'CTI Parcels',
  'CTI Taxi Trucks',
  'CTI Xpress',
  'Deliver In Person',
  'Delivered Pty Ltd',
  'Designer Transport',
  'DIRECT COURIERS',
  'Direct Freight Express',
  'Dobles Express',
  'Domestic Freight Express',
  'Doug Hayes Transport',
  'DSE Trucks',
  'Dupont Transport',
  'Eclipse Couriers',
  'Empire Transport',
  'Expressway Couriers',
  'Felix Transport',
  'First Express',
  'Fleurieu Logistics',
  'Followmont Transport',
  'Fortigo',
  'Foxline Couriers',
  'Foxline Parcels',
  'Freight Assist Australia',
  'Freight Specialists',
  'Fusion',
  'General Carrying Co',
  'GKR',
  'GMK Logistics',
  'Go Logistics',
  'Gold Tiger',
  'Grants Express',
  'Greater Geelong',
  'Greenlight Couriers',
  'Hi Trans',
  'Hunter Express',
  'Icumulus',
  'Intime Distribution',
  "Josie's",
  'Just Freight',
  'Kennerley Transport',
  'Kings Transport',
  'KTI Transport',
  'Kwickaz',
  'Land Transport',
  'Langfields',
  'LFT Transport',
  'Lightning Transport',
  'Lindsay Transport',
  'Local Freight',
  'LOGIWEST',
  'Lormac',
  'Medi-Direct',
  'Mercury Couriers',
  'Metro Express Couriers',
  'Metropolitan Express',
  'Metrostate Security Courier',
  'Morrows',
  'MP Freightlines',
  'MR EXPRESS',
  'NDST',
  'Niche Logistics',
  'Northline Distribution',
  'NP Distribution',
  'Oneil Transport',
  'Ozwide Freight',
  'Parton Logistics',
  'Pedemonts Transport',
  'PEP Transport',
  'Personalised Freight Management Corp',
  'Phoenix Transport',
  'Pickering Transport',
  'Plus Couriers',
  'Powers Country Transport',
  'Progress Couriers',
  'QF National',
  'Region Peak Transport',
  'Regional Road Express',
  'Road Chill',
  'S&R',
  'Sadleirs Logistics',
  'Sampson Express',
  'SCT',
  'Shaws Transport',
  'Skyway Express',
  'Sparrk Logistics',
  'StarTrack',
  'State Transport and Logistics',
  'Swift Couriers',
  'Swift Transport Services',
  'SwiftTrans',
  'Tamex Transport',
  'Tasfreight',
  'Tattam Express',
  'Team Transport',
  'TFMXpress',
  'TGE Courier',
  'TGE Intermodal',
  'TGE IPEC',
  'TGE Priority',
  'TGE Tasmania',
  'Time Express',
  'TNT Express',
  'To My Door',
  'Tony Innaimo Transport',
  'Total Logistic Solutions',
  'Transit Couriers',
  'TSS Sensitive Freight',
  'Vellex',
  'Victorian Freight Specialists',
  'Vision Freight',
  'VT Freight Express',
  'Warehouse Logistics Australia',
  'Wettenhalls Transport',
  'XL Express',
  'Xpress Freight Management',
]
const machShipCouriers = carriersList.map((carrier) => ({
  label: carrier,
  value: carrier.replace(/\s+/g, ''),
  url: `https://bdlmw.blob.core.windows.net/prod/Machship_Logo/${carrier}.svg`,
}))

export { machShipCouriers }
