import { FC } from 'react'
import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'
import starshipitIcon from 'assets/images/starshipitIcon.svg'
import wixIcon from 'assets/images/wix.svg'

import { OrderStatusLabel } from 'components/atoms/OrderStatusLabel'
import { dateToAEST } from 'utils'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export interface OrderHeaderProps {
  [x: string]: any
}

/** This is OrderHeader */

export const OrderHeader: FC<OrderHeaderProps> = ({
  orderDetails,
}: OrderHeaderProps) => {
  return (
    <div className='header_container flex flex-col w-full pb-3 px-4 pt-4 border-b border-b-N-200 sticky top-0 bg-white z-[1]'>
      <div className='flex w-full items-center justify-between'>
        <div className='header_container-top-left-side flex items-center'>
          <p className='text-lg font-semibold text-black pr-4'>
            Order Info - {orderDetails?.customerOrderNo}
          </p>
          <OrderStatusLabel
            appearance={capitalizeFirstLetter(orderDetails?.status || '')}
            labelValue={capitalizeFirstLetter(orderDetails?.status || '')}
          />
          {orderDetails?.integrationType === 'Woocommerce' && (
            <img src={Iconwoocommerce} alt='Iconwoocommerce' className='pl-4' />
          )}
          {orderDetails?.integrationType === 'Shopify' && (
            <img src={IconShopify} alt='IconShopify' className='pl-4' />
          )}
          {orderDetails?.integrationType === 'PushApi' && (
            <img src={IconPushAPI} alt='IconPushAPI' className='pl-4' />
          )}
          {orderDetails?.integrationType === 'IcsWMS' && (
            <i className='ri-store-3-fill text-xl pl-4 text-N-600'></i>
          )}
          {orderDetails?.integrationType === 'AzureSftp' && (
            <i className='ri-folder-received-fill text-xl pl-4 text-N-600'></i>
          )}
          {orderDetails?.integrationType === 'StarshipIT' && (
            <img src={starshipitIcon} alt='starshipITLogo' className='pl-4' />
          )}

          {orderDetails?.integrationType === 'StarshipIT' &&
            orderDetails?.meta === 'Wix' && (
              <div className='flex pl-2'>
                <span>-</span>
                <img src={wixIcon} alt='wixIcon' className='pl-2' />
              </div>
            )}
        </div>
        <div className='flex items-center'>
          <i className='ri-calendar-event-line text-sm pr-2'></i>
          <p className='text-xs font-medium text-N-500'>
            {dateToAEST(orderDetails?.createdOn)}
          </p>
        </div>
      </div>
      <div className='flex w-full items-center pt-1 text-xs font-medium text-N-500 gap-x-3 divide-x divide-N-500'>
        <p>WMS Order #: {orderDetails?.warehouseOrder?.orderNo || '-'}</p>

        <p className='pl-3'>
          Customer Reference: {orderDetails?.customerReferance || '-'}
        </p>

        <p className='pl-3'>
          {orderDetails?.partner?.code} - {orderDetails?.partner?.name}
        </p>
      </div>
    </div>
  )
}

OrderHeader.defaultProps = {}

export default OrderHeader
