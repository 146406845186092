/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetStateListByCountryCode } from 'framework/api/methods'

import {
  Button,
  Drawer,
  Input,
  JsonLoader,
  SimpleSelect,
  ToggleSwitch,
} from 'components/atoms'
import { isEmail } from 'utils'

export interface UpdatePartnerProps {
  className?: string | undefined
  [x: string]: any
}

export const UpdatePartner: FC<UpdatePartnerProps> = ({
  className,
  countryList,
  getPartnerTableList,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
  useGetPartnerById,
  useUpdatePartner,
}: UpdatePartnerProps) => {
  const [updatePartnerDetails, setUpdatePartnerDetails] = useState<any>({
    status: true,
    partnerName: '',
    partnerCode: '',
    contactPerson: '',
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    streetAddress: '',
    suburb: '',
    state: '',
    postalCode: '',
    country: '',
  })

  const [stateList, setStateList] = useState([])

  /** API Call using react-query */
  const { mutate: getPartnerByIdMutate, isLoading: getPartnerByIdIsLoading } =
    useGetPartnerById()

  const { mutate: updatePartnerMutate, isLoading: updatePartnerIsLoading } =
    useUpdatePartner()

  /** Process the get Partner Table Data */
  async function getPartnerById(id: any): Promise<void> {
    getPartnerByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const {
            isActive,
            name,
            code,
            contactName,
            contactEmail,
            supportEmail,
            contactPhone,
            address,
          } = successData.partner
          setUpdatePartnerDetails({
            ...updatePartnerDetails,
            partnerId: id,
            status: isActive,
            partnerName: name,
            partnerCode: code,
            contactPerson: contactName,
            contactEmail: contactEmail,
            supportEmail: supportEmail,
            phoneNo: contactPhone,
            streetAddress: address.lineOne,
            suburb: address.suburb,
            state: { value: address.stateCode, label: address.state },
            postalCode: address.postCode,
            country: { value: address.countryCode, label: address.country },
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          onCloseClick()
        },
      },
    )
  }

  /** Process the Update partner data */
  async function updatePartner() {
    updatePartnerMutate(updatePartnerDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getPartnerTableList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  useEffect(() => {
    getPartnerById(selectedRowByButtonAction.id)
  }, [selectedRowByButtonAction])

  const [isErrorField, setIsErrorField] = useState<any>({
    partnerName: false,
    partnerCode: false,
    contactPerson: false,
    contactEmail: false,
    supportEmail: false,
    state: false,
  })

  const validateInputs = () => {
    const {
      partnerName,
      partnerCode,
      contactPerson,
      contactEmail,
      supportEmail,
      state,
    } = updatePartnerDetails

    setIsErrorField({
      ...isErrorField,
      partnerName: partnerName ? false : true,
      partnerCode: partnerCode ? false : true,
      contactPerson: contactPerson ? false : true,
      contactEmail: isEmail(contactEmail) ? false : true,
      supportEmail: isEmail(supportEmail) ? false : true,
      state: state ? false : true,
    })

    const isAllValidFields =
      partnerName &&
      partnerCode &&
      contactPerson &&
      state &&
      isEmail(contactEmail) &&
      isEmail(supportEmail)
    return isAllValidFields
  }

  const handleUpdateClick = () => {
    const valid = validateInputs()

    if (valid) {
      updatePartner()
    }
  }

  const AddPartnerClasses = CN(
    'AddPartner flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto',
    className,
    {},
  )

  /** API for  Get state list by country code */
  const { mutate: GetStateListByCountryCodeMutate } =
    useGetStateListByCountryCode()

  /** Process the Get state list by country code */
  async function GetStateListByCountryCode() {
    GetStateListByCountryCodeMutate(
      { code: updatePartnerDetails?.country?.value },
      {
        onSuccess: ({ data: getStateListData }: any) => {
          const states = getStateListData.states.map((state: any) => {
            return {
              value: state.code,
              label: state.name,
            }
          })
          setStateList(states)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  useEffect(() => {
    updatePartnerDetails?.country?.value && GetStateListByCountryCode()
  }, [updatePartnerDetails?.country?.value])

  return (
    <Drawer
      isActive={isActive}
      onOverlayClick={() => console.log('overlay Clicked')}
      onCloseClick={onCloseClick}
      title='Update Partner'>
      <div className={AddPartnerClasses}>
        {getPartnerByIdIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}
        <div className='flex w-full items-center'>
          <Input
            className='pr-2'
            labelValue='Partner Name'
            isRequired
            type='text'
            value={updatePartnerDetails.partnerName}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                partnerName: e.target.value,
              })
            }}
            isError={isErrorField.partnerName}
          />
          <Input
            className='pl-2'
            labelValue='Partner Code'
            isRequired
            type='text'
            value={updatePartnerDetails.partnerCode}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                partnerCode: e.target.value,
              })
            }}
            isError={isErrorField.partnerCode}
            isDisabled={true}
          />
        </div>
        <div className='flex w-full items-center pt-5'>
          <Input
            className='pr-2'
            labelValue='Contact Person'
            isRequired
            type='text'
            value={updatePartnerDetails.contactPerson}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                contactPerson: e.target.value,
              })
            }}
            isError={isErrorField.contactPerson}
          />
          <Input
            className='pl-2'
            labelValue='Contact Email'
            isRequired
            type='email'
            value={updatePartnerDetails.contactEmail}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                contactEmail: e.target.value,
              })
            }}
            isError={isErrorField.contactEmail}
          />
        </div>
        <div className='flex w-full items-center pt-5'>
          <Input
            className='pr-2'
            labelValue='Support Email'
            isRequired
            type='email'
            value={updatePartnerDetails.supportEmail}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                supportEmail: e.target.value,
              })
            }}
            isError={isErrorField.supportEmail}
          />
          <Input
            className='pl-2'
            labelValue='Phone No'
            type='text'
            value={updatePartnerDetails.phoneNo}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                phoneNo: e.target.value,
              })
            }}
          />
        </div>
        <div className='flex w-full items-center pt-5'>
          <Input
            className=''
            labelValue='Street Address'
            type='text'
            value={updatePartnerDetails.streetAddress}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                streetAddress: e.target.value,
              })
            }}
          />
        </div>
        <div className='flex w-full items-center pt-5'>
          <Input
            className='pr-2'
            labelValue='Suburb'
            type='text'
            value={updatePartnerDetails.suburb}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                suburb: e.target.value,
              })
            }}
          />

          <div className='flex flex-col w-full pl-2'>
            <div className='flex'>
              <span className='pb-2 text-N-700'>State</span>
              <span className='text-R-500 ml-[2px]'>*</span>
            </div>

            <SimpleSelect
              value={updatePartnerDetails.state}
              onChange={(e: any) => {
                setUpdatePartnerDetails({
                  ...updatePartnerDetails,
                  state: e,
                })
              }}
              options={stateList}
              isError={isErrorField.state}
              placeholder='Select State'
            />
          </div>
        </div>
        <div className='flex w-full items-center pt-5'>
          <Input
            className='pr-2'
            labelValue='Postal Code'
            type='text'
            value={updatePartnerDetails.postalCode}
            onChange={(e: any) => {
              setUpdatePartnerDetails({
                ...updatePartnerDetails,
                postalCode: e.target.value,
              })
            }}
            placeholder='138576'
          />
          <div className='flex flex-col w-full pl-2'>
            <span className='text-N-700 pb-2'>Country</span>
            <SimpleSelect
              value={updatePartnerDetails.country}
              onChange={(e: any) => {
                setUpdatePartnerDetails({
                  ...updatePartnerDetails,
                  country: e,
                  state: '',
                })
              }}
              options={countryList}
            />
          </div>
        </div>
        <div className='flex w-full items-start justify-start pt-10'>
          <div className='flex flex-col w-full'>
            <span className='text-N-700 pb-2'>Status</span>
            <ToggleSwitch
              text={updatePartnerDetails?.status ? 'Active' : 'Inactive'}
              setValue={updatePartnerDetails?.status}
              onToggleClick={(e: any) => {
                setUpdatePartnerDetails({
                  ...updatePartnerDetails,
                  status: e.target.checked,
                })
              }}
            />
          </div>
        </div>
      </div>

      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
        <Button
          onClick={() => handleUpdateClick()}
          disabled={getPartnerByIdIsLoading || updatePartnerIsLoading}
          isLoading={updatePartnerIsLoading}>
          Update Partner
        </Button>
        <Button
          className='ml-5 w-[152px]'
          appearance='dull'
          disabled={getPartnerByIdIsLoading}
          onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

UpdatePartner.defaultProps = {
  className: undefined,
}

export default UpdatePartner
