import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteOrderShipmentPackageInterface {
  orderId?: string
  shipmentId?: string
  packageId?: string
}

export const useDeleteOrderShipmentPackage = () => {
  const mutate = useMutation(
    ({
      orderId,
      shipmentId,
      packageId,
    }: DeleteOrderShipmentPackageInterface) => {
      return deleteAPI(
        `/WarehouseOrder/${orderId}/Shipments/${shipmentId}/Package/${packageId}`,
      )
    },
  )

  return mutate
}

export default useDeleteOrderShipmentPackage
