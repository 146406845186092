/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  setDeleteObjectDetails,
  deleteObjectDetails,
  shipmentId,
  shipmentStatus,
  warehouseIntegrationName,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])
  const [editedColumns, setEditedColumns] = useState(columns || [])
  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
    if (warehouseIntegrationName !== 'PaperlessWMS') {
      const cols = columns.filter((column) => column.accessor !== 'pickedQty')
      if (shipmentStatus === 'CLOSED') {
        const newColumns = cols.filter(
          (column) => column.accessor !== 'actions',
        )
        setEditedColumns(newColumns)
      } else {
        setEditedColumns(cols)
      }
    } else {
      if (shipmentStatus === 'CLOSED') {
        const newColumns = columns.filter(
          (column) => column.accessor !== 'actions',
        )
        setEditedColumns(newColumns)
      } else {
        setEditedColumns(columns)
      }
      // if shipmentStatus is 'CLOSED' then remove the 'actions' column
    }
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={editedColumns}
        setDeleteObjectDetails={setDeleteObjectDetails}
        deleteObjectDetails={deleteObjectDetails}
        shipmentId={shipmentId}
        shipmentStatus={shipmentStatus}
        warehouseIntegrationName={warehouseIntegrationName}
      />
    </div>
  )
}

export default DataTable
