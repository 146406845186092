import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateStockOrderInterface {
  id?: string
  warehouseCode?: string
  customerReferance?: string
  stockNote?: string
  invoiceNo?: string
  jobReference?: string
  eta?: any
  receiptDate?: any
  instructions?: string
}

export const useUpdateStockOrder = () => {
  const mutate = useMutation(
    ({
      id,
      warehouseCode,
      customerReferance,
      stockNote,
      invoiceNo,
      jobReference,
      eta,
      receiptDate,
      instructions,
    }: UpdateStockOrderInterface) => {
      return putAPI(`/StockOrder`, {
        id,
        warehouseCode,
        customerReferance,
        stockNote,
        invoiceNo,
        jobReference,
        eta,
        receiptDate,
        instructions,
      })
    },
  )

  return mutate
}

export default useUpdateStockOrder
