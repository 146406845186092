import { FC } from 'react'
import CN from 'classnames'

export interface FooterProps {
  [x: string]: any
  isFullMenu?: boolean
}

export const Footer: FC<FooterProps> = ({
  className,
  isFullMenu,
  ...restProps
}: FooterProps) => {
  const FooterClasses = CN(
    'main-layout__Footer z-10 fixed bg-white h-[60px] px-8 py-[14px] flex justify-start items-center',
    {
      'left-[280px] bottom-0 w-[calc(100%-280px)]': isFullMenu,
      'left-[60px] bottom-0 w-[calc(100%-60px)]': !isFullMenu,
    },
    className,
  )

  return (
    <div className={FooterClasses} {...restProps}>
      <div className='flex items-center text-sm text-N-800'>
        <p>
          © {new Date().getFullYear()} BDL, All rights reserved.
          {/* Powered by{' '} <a className='font-bold text-P-500' href='https://www.google.lk/'>
            Taprobanes IT
          </a> */}
        </p>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  isFullMenu: undefined,
}

export default Footer
