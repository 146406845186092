import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

const userDeactivate = async (reqBody: any) => {
  const res = await post('/UserProfile/Deactivate', reqBody)
  return res.data
}

export const useUserDeactivate = (onSettled: any, onError: any) => {
  return useMutation(userDeactivate, {
    onSettled,
    onError,
  })
}
