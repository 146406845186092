import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UseLoginInterface {
  username: string
  password: string
}

export const useLogin = () => {
  const mutate = useMutation(({ username, password }: UseLoginInterface) => {
    return postAPI('/identity/authenticate', {
      username: username,
      password: password,
    })
  })

  return mutate
}

export default useLogin
