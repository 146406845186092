import React, { FC } from 'react'
import empty from 'assets/animations/empty.json'
import { useLottie } from 'lottie-react'

export interface DashboardProps {
  [x: string]: any
}

/** This is dashboard */
/** UAT commit */
export const Dashboard: FC<DashboardProps> = () => {
  const AnimatedView = () => {
    const options = {
      animationData: empty,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  //repo migration test comment

  return (
    <div className='flex items-center justify-center bg-N-50 h-full w-full px-8 py-5'>
      <div className='flex items-center justify-center h-full w-full rounded-lg bg-white shadow-md'>
        <div className='opacity-90 w-1/4'>
          <AnimatedView />
        </div>
      </div>
    </div>
  )
}

Dashboard.defaultProps = {}

export default Dashboard
