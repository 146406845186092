import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteOrderShipmentInterface {
  orderId?: string
  shipmentId?: string
}

export const useDeleteOrderShipment = () => {
  const mutate = useMutation(
    ({ orderId, shipmentId }: DeleteOrderShipmentInterface) => {
      return deleteAPI(`/WarehouseOrder/${orderId}/Shipments/${shipmentId}`)
    },
  )

  return mutate
}

export default useDeleteOrderShipment
