import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateShipmentLineInterface {
  wereHouseOrderId?: string
  shipmentId?: string
  shipmentLines?: any
}

export const useCreateShipmentLine = () => {
  const mutate = useMutation(
    ({
      wereHouseOrderId,
      shipmentId,
      shipmentLines,
    }: CreateShipmentLineInterface) => {
      return postAPI(
        `/WarehouseOrder/${wereHouseOrderId}/Shipments/${shipmentId}/ShipmentLine`,
        shipmentLines,
      )
    },
  )

  return mutate
}

export default useCreateShipmentLine
