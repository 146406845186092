import { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Input } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'

export interface UpdateOrderLineProps {
  className?: string | undefined
  [x: string]: any
}

export const UpdateOrderLine: FC<UpdateOrderLineProps> = ({
  className,
  isActive,
  onCloseClick,
  selectedOrderDetails,
  selectedOrderId,
  updateOrderLineById,
}: UpdateOrderLineProps) => {
  const [orderLineDetails, setOrderLineDetails] = useState({
    orderId: '',
    lineId: '',
    sku: '',
    uom: '',
  })

  useEffect(() => {
    if (isActive) {
      setOrderLineDetails({
        ...orderLineDetails,
        orderId: selectedOrderId,
        lineId: selectedOrderDetails?.id,
        sku: selectedOrderDetails?.partnerSKU,
        uom: selectedOrderDetails?.uom,
      })
    }
  }, [isActive])

  const OrderLineClasses = CN(
    'UpdateOrderLine flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={400}
      title='Update Order Lines'>
      <div className={OrderLineClasses}>
        <Input
          labelValue='SKU'
          type='text'
          value={orderLineDetails?.sku}
          onChange={(e: any) => {
            setOrderLineDetails({
              ...orderLineDetails,
              sku: e.target.value,
            })
          }}
        />

        <Input
          labelValue='UOM'
          type='text'
          value={orderLineDetails?.uom}
          onChange={(e: any) => {
            setOrderLineDetails({
              ...orderLineDetails,
              uom: e.target.value,
            })
          }}
        />
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isFullWidth
          onClick={() => {
            updateOrderLineById(orderLineDetails)
          }}>
          Update
        </Button>

        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

UpdateOrderLine.defaultProps = {
  className: undefined,
}

export default UpdateOrderLine
