/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GenericTasksListInterface {
  id: number
}

export const useGetGenericTasksList = () => {
  const mutate = useMutation(({ id }: GenericTasksListInterface) => {
    return get(`/Tasks/GenericTask`)
  })

  return mutate
}

export default useGetGenericTasksList
