import { FC } from 'react'
import groovyWalkAnimation from 'assets/animations/loader.json'
import CN from 'classnames'
import { useLottie } from 'lottie-react'

export interface JsonLoaderProps {
  className?: string | undefined
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | undefined
}

export const JsonLoader: FC<JsonLoaderProps> = ({ size }: JsonLoaderProps) => {
  const AnimatedView = () => {
    const options = {
      animationData: groovyWalkAnimation,
      loop: true,
      autoplay: true,
    }

    const { View } = useLottie(options)

    return View
  }
  return (
    <div
      className={CN('opacity-90', {
        'w-full': size === 'lg',
        'w-1/2': size === 'md',
        'w-1/4': size === 'sm',
        'w-1/6': size === 'xs',
        'w-1/12': size === 'xxs',
      })}>
      <AnimatedView />
    </div>
  )
}

JsonLoader.defaultProps = {
  className: undefined,
  size: 'xs',
}

export default JsonLoader
