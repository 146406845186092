import ReactTooltip from 'react-tooltip'

export const columns = [
  {
    customWidth: 100,
    Header: () => '',
    accessor: 'status',
    Cell: ({ row }) => (
      <div
        className='flex items-center'
        data-tip={row.original.wareHouseOrderLineError}>
        {row.original.wareHouseOrderLineError && (
          <>
            <i className='ri-error-warning-fill text-sm text-R-500 ml-1'></i>
            <p className='pl-2'>Error</p>
            <ReactTooltip
              place='top'
              effect='solid'
              delayShow={200}
              textColor='#E2E8F0'
              backgroundColor='#334155'
            />
          </>
        )}
        {row.original.wareHouseOrderLineError === null && (
          <i className='ri-checkbox-circle-fill text-sm text-G-500 ml-1'></i>
        )}
      </div>
    ),
  },
  {
    customWidth: 163,
    Header: () => 'Partner SKU',
    accessor: 'partnerSKU',
    Cell: ({ row }) => <div>{row.original.partnerSKU}</div>,
  },
  {
    customWidth: 320,
    Header: () => 'Item Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 45,
    Header: 'Qty',
    accessor: 'qty',
    Cell: ({ row }) => <div>{row.original.qty}</div>,
  },
  {
    customWidth: 160,
    Header: () => 'Warehouse SKU',
    accessor: 'warehouseSKU',
    isWarehouseSKU: true,
    Cell: () => true,
    // Cell: ({ row }) => (

    // ),
  },
  {
    customWidth: 140,
    Header: 'Warehouse',
    accessor: 'warehouseCode',
    Cell: ({ row }) => <div>{row?.original?.warehouseCode}</div>,
  },
]

export default columns
