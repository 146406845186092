/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetUserPermissionByRoleId } from 'framework/api/methods'

import { Button, Drawer, JsonLoader, ToggleSwitch } from 'components/atoms'

export interface ViewUserRolesProps {
  className?: string | undefined
  [x: string]: any
}

export const ViewUserRoles: FC<ViewUserRolesProps> = ({
  className,
  isActive,
  onCloseClick,
  userRoleDetails,
  setIsAddNew,
  setDeleteUserRolePopOutIsOpen,
  setUpdateUserRoleDrawerIsOpen,
  setViewUserRoleDrawerIsOpen,
  notify,
  privilegesList,
  ...restProps
}: ViewUserRolesProps) => {
  const ViewUserRolesClasses = CN(
    'ViewUserRoles flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto styled-scroll',
    className,
    {},
  )

  const [userPrivilegesList, setUserPrivilegesList] =
    useState<any>(privilegesList)
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<any>(
    [],
  )

  const { id, name } = userRoleDetails

  useEffect(() => {
    const toBeUpdated = userPrivilegesList.map((item: any) => {
      return {
        ...item,
        isActive: selectedUserPermissions?.includes(item.name),
      }
    })
    setUserPrivilegesList(toBeUpdated)
  }, [selectedUserPermissions])

  useEffect(() => {
    getUserPermissionByRoleId(id)
  }, [userRoleDetails])

  /** API Call for get user roles using react-query */
  const {
    mutate: getUserPermissionByRoleIdMutate,
    isLoading: getUserPermissionByRoleIdIsLoading,
  } = useGetUserPermissionByRoleId()

  /** Process to get selected user's user privileges */
  const getUserPermissionByRoleId = (id: any) => {
    getUserPermissionByRoleIdMutate(
      { Id: id },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set user privileges according to result */
          setSelectedUserPermissions(successData?.permissions)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  return (
    <Drawer
      isActive={isActive}
      width={360}
      {...restProps}
      title='View User Role'
      onCloseClick={onCloseClick}>
      {getUserPermissionByRoleIdIsLoading && (
        <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
          <JsonLoader size='xs' />
        </div>
      )}
      <div className={ViewUserRolesClasses}>
        <div className='user-role-name flex flex-col pt-[2px] pb-[53px]'>
          <span className='font-medium text-sm text-N-500 pb-1'>
            Role Name :
          </span>

          <span className='font-semibold text-base text-N-700'>{name}</span>
        </div>

        <div className='flex flex-col w-full'>
          <span className='pb-4 text-black font-bold'>Privileges</span>

          {userPrivilegesList.map((item: any, id: any) => (
            <ToggleSwitch
              key={id}
              setValue={item.isActive}
              text={item.name}
              className='pb-4'
              isDisabled={true}
            />
          ))}
        </div>
      </div>
      <div className='drawer-footer flex items-center justify-between w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
        <Button
          isLoading={false}
          appearance='outline'
          onClick={() => {
            setViewUserRoleDrawerIsOpen(false)
            setIsAddNew(false)
            setUpdateUserRoleDrawerIsOpen(true)
          }}>
          Update Role
        </Button>
        <Button
          className='text-R-500 border-R-500 hover:bg-N-50'
          appearance='outline'
          onClick={() => {
            onCloseClick()
            setDeleteUserRolePopOutIsOpen(true)
          }}>
          Delete Role
        </Button>
      </div>
    </Drawer>
  )
}

ViewUserRoles.defaultProps = {
  className: undefined,
}

export default ViewUserRoles
