import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateOrderLineByIdInterface {
  partnerOrderId?: string
  orderLineId?: string
  warehouseSKU?: string
}

export const useUpdateOrderLineById = () => {
  const mutate = useMutation(
    ({
      partnerOrderId,
      orderLineId,
      warehouseSKU,
    }: UpdateOrderLineByIdInterface) => {
      return putAPI(
        `/PartnerOrders/${partnerOrderId}/OrderLines/${orderLineId}`,
        {
          warehouseSKU: warehouseSKU,
        },
      )
    },
  )

  return mutate
}

export default useUpdateOrderLineById
