/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CN from 'classnames'
import { setBreadcrumb } from 'store/reducers/header/headerSlice'

export interface LeftNavProps {
  [x: string]: any
  menuItemList?: any
  isFullMenu?: boolean | undefined
  firstRoute?: string | undefined
  secondRoute?: string | undefined
}

export const LeftNav: FC<LeftNavProps> = ({
  menuItemList,
  isFullMenu,
  firstRoute,
  secondRoute,
  userDetails,
}: LeftNavProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [menuItems, setMenuItems] = useState<any>([])
  const [firstBreadcrumb, setFirstBreadcrumb] = useState('')
  const [secondBreadcrumb, setSecondBreadcrumb] = useState('')

  useEffect(() => {
    const filterSubMenus = menuItemList.map((item: any) => {
      return {
        ...item,
        subMenuItems: item.subMenuItems?.filter((item: any) => {
          return item.isActive
        }),
      }
    })

    const filterMainMenu = filterSubMenus.filter((item: any) => {
      return item.isActive
    })

    setMenuItems(filterMainMenu)
    // const filterMainMenu = menuItemList.filter((item: any) => {
    //   return item.isActive
    // })
  }, [userDetails])

  useEffect(() => {
    dispatch(
      setBreadcrumb(secondBreadcrumb ? secondBreadcrumb : firstBreadcrumb),
    )
  }, [firstBreadcrumb, secondBreadcrumb])

  useEffect(() => {
    if (menuItems.length > 0) {
      setSecondBreadcrumb('')
      setFirstBreadcrumb('')
      const itemsToUpdate = menuItems

      if (firstRoute || firstRoute === '') {
        const selectedIndex = itemsToUpdate.findIndex((item: any) => {
          return item.url === `/${firstRoute}`
        })
        itemsToUpdate[selectedIndex].isSelected = true
        setFirstBreadcrumb(itemsToUpdate[selectedIndex]?.name)
        setMenuItems(itemsToUpdate.slice())
      }

      if (secondRoute) {
        const selectedIndex = itemsToUpdate.findIndex((item: any) => {
          return item.url === `/${firstRoute}`
        })
        const selectedSubMenuIndex = itemsToUpdate[
          selectedIndex
        ].subMenuItems.findIndex((subItem: any) => {
          return subItem.url === `/${firstRoute}/${secondRoute}`
        })
        itemsToUpdate[selectedIndex].subMenuItems[
          selectedSubMenuIndex
        ].isSelected = true
        setSecondBreadcrumb(
          itemsToUpdate[selectedIndex].subMenuItems[selectedSubMenuIndex]?.name,
        )
        setMenuItems(itemsToUpdate.slice())
      }
    }
  }, [firstRoute, secondRoute])

  const clickMainItem = (selectedItem: any) => {
    const itemToUpdate = menuItems
    if (!selectedItem.isSelected) {
      !selectedItem.hasSubmenu && navigate(selectedItem.url)
      const updatedItems = itemToUpdate.map((item: any) => {
        if (item.mainMenuId === selectedItem.mainMenuId) {
          return {
            ...item,
            isSelected: true,
            subMenuItems: item.subMenuItems.map((subItem: any) => {
              return {
                ...subItem,
                isSelected: false,
              }
            }),
          }
        } else {
          return {
            ...item,
            isSelected: false,
            subMenuItems: item.subMenuItems.map((subItem: any) => {
              return {
                ...subItem,
                isSelected: false,
              }
            }),
          }
        }
      })
      setMenuItems(updatedItems)
    }
  }

  const clickSubMainItem = (mainMenuItemIndex: any, selectedSubItem: any) => {
    const itemToUpdate = menuItems
    navigate(selectedSubItem.url)
    const updatedItems = itemToUpdate[mainMenuItemIndex].subMenuItems.map(
      (subMenuItem: any) => {
        if (subMenuItem.subMenuId === selectedSubItem.subMenuId) {
          return {
            ...subMenuItem,
            subMenuItemKey: Math.random(),
            isSelected: true,
          }
        } else {
          return {
            ...subMenuItem,
            subMenuItemKey: Math.random(),
            isSelected: false,
          }
        }
      },
    )

    itemToUpdate[mainMenuItemIndex].subMenuItems = updatedItems
    itemToUpdate[mainMenuItemIndex].mainMenuItemKey = Math.random()
    setMenuItems(itemToUpdate.slice())
  }
  return (
    <div className='side-bar-item-container mt-8 h-full w-full'>
      <ul>
        {menuItems?.map((item: any, idx: any) => (
          <li key={item?.mainMenuItemKey} className='side-bar-item'>
            <a href='#'>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  clickMainItem(item)
                }}
                className={CN(
                  'side-bar-item__container flex items-center justify-between h-12 w-full py-3 rounded-md transition-all duration-500',
                  {
                    'px-5': isFullMenu,
                    'px-[10px]': !isFullMenu,
                  },
                  {
                    'bg-P-500 text-white': item?.isSelected,
                    'px-[10px] hover:bg-P-100': !item?.isSelected,
                  },
                )}>
                <div className='flex items-center'>
                  {item?.icon}
                  {isFullMenu && (
                    <p className='pl-3 font-medium'>{item?.name}</p>
                  )}
                </div>

                {isFullMenu &&
                  item?.hasSubmenu &&
                  (item?.isSelected ? (
                    <i className='ri-arrow-down-s-line text-white'></i>
                  ) : (
                    <i className='ri-arrow-left-s-line text-N-700'></i>
                  ))}
              </div>
            </a>
            {isFullMenu &&
              item?.hasSubmenu &&
              item?.isSelected &&
              item?.subMenuItems?.map((subItem: any, subIdx: any) => (
                <ul
                  key={`${subItem.subMenuItemKey}${subIdx}`}
                  className={CN(
                    'side-bar-item__sub-menu transition-all duration-1000',
                    {
                      'h-0': !item?.isSelected,
                      'h-full': item?.isSelected,
                    },
                  )}>
                  <li>
                    <a
                      className={CN('hover:text-P-400', {
                        'text-P-500': subItem?.isSelected,
                        'text-N-700': !subItem?.isSelected,
                      })}
                      href='#'>
                      <div
                        onClick={(e) => {
                          e.stopPropagation()
                          clickSubMainItem(idx, subItem)
                        }}
                        className='side-bar-item__sub-menu-item py-2 px-[25px]'>
                        <div className='flex items-center'>
                          {subItem?.icon}
                          {isFullMenu && (
                            <p className='pl-3 text-sm'>{subItem?.name}</p>
                          )}
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              ))}
          </li>
        ))}
      </ul>
    </div>
  )
}

LeftNav.defaultProps = {
  initialObj: undefined,
  isFullMenu: true,
  firstRoute: undefined,
  secondRoute: undefined,
}

export default LeftNav
