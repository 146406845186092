import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

import { timeIntoDateTimeFormat } from 'utils'

interface CreateInvoiceInterface {
  partnerCode?: string
  invoiceId?: string
  title?: string
  type?: string
  year?: string
  month?: string
  dateRaised?: string
  dueDate?: string
  amount?: number
  isPaid?: boolean
  status?: boolean
  invoiceFiles?: File[]
}
export const useCreateInvoice = () => {
  const mutate = useMutation((data: CreateInvoiceInterface) => {
    const formData = new FormData()
    // Append all text-based fields
    formData.append('partnerCode', data.partnerCode || '')
    formData.append('invoiceId', data.invoiceId || '')
    formData.append('title', data.title || '')
    formData.append('type', data.type || '')
    formData.append('year', data.year || '')
    formData.append('month', data.month || '')
    formData.append('dateRaised', data.dateRaised || '')
    formData.append('dueDate', data.dueDate || '')
    formData.append('amount', data.amount?.toString() || '0')
    formData.append('isPaid', data.isPaid ? 'true' : 'false')
    formData.append('status', data.status ? 'true' : 'false')
    // Append files if present
    if (data.invoiceFiles && data.invoiceFiles.length > 0) {
      data.invoiceFiles.forEach((file) => {
        formData.append('invoiceFiles', file)
      })
    }
    console.log('formdata', formData)
    return postAPI('/CreateInvoice', formData)
  })
  return mutate
}
export default useCreateInvoice
