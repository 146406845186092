import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface gtPaperlessWMSWarehouseIntegrationByIdInterface {
  id?: string
}

export const useGetPaperlessWMSWarehouseIntegrationById = () => {
  const mutate = useMutation(
    ({ id }: gtPaperlessWMSWarehouseIntegrationByIdInterface) => {
      return get(`/Warehouse/${id}/PaperlessWms`)
    },
  )

  return mutate
}

export default useGetPaperlessWMSWarehouseIntegrationById
