/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { useGetOrdersExportCSV } from 'framework/api/methods'

import { CheckBox } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { MultiSelect } from 'components/atoms/MultiSelect'
import { SimpleSelect } from 'components/atoms/Select'
import { capitalizeAll } from 'utils/capitalizeAll'

import './AdvanceSearchModal.scss'

export interface AdvanceSearchModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  partnerList?: any
  setPartnerOrderVariables?: any
  partnerOrderVariables?: any
  searchKeyword?: any
  startDateValue?: any
  endDateValue?: any
  setPageNumber?: any
}

export const AdvanceSearchModal: FC<AdvanceSearchModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  partnerList,
  setPartnerOrderVariables,
  partnerOrderVariables,
  searchKeyword,
  startDateValue,
  endDateValue,
  setPageNumber,
}: AdvanceSearchModalProps) => {
  const list = [
    {
      id: 1,
      label: 'All',
      value: 'ALL',
      disabled: false,
    },
    {
      id: 2,
      label: 'Received',
      value: 'RECEIVED',
      disabled: false,
    },
    {
      id: 3,
      label: 'Back Order',
      value: 'BACKORDER',
      disabled: false,
    },
    {
      id: 4,
      label: 'Confirmed',
      value: 'CONFIRMED',
      disabled: false,
    },
    {
      id: 5,
      label: 'Open',
      value: 'OPEN',
      disabled: false,
    },
    {
      id: 6,
      label: 'To Be Consigned',
      value: 'TOBECONSIGNED',
      disabled: false,
    },
    {
      id: 7,
      label: 'Closed',
      value: 'CLOSED',
      disabled: false,
    },
    {
      id: 8,
      label: 'Partial-consigned',
      value: 'PARTIAL-CONSIGNED',
      disabled: false,
    },
    {
      id: 9,
      label: 'Failed',
      value: 'FAILED',
      disabled: false,
    },
    {
      id: 10,
      label: 'Cancelled',
      value: 'CANCELLED',
      disabled: false,
    },
  ]

  const csvLinkEl = useRef<
    CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
  >(null)

  const [selectedPartner, setSelectedPartner] = useState<any>(
    partnerOrderVariables.partner,
  )
  const [statusList, setStatusList] = useState(list)
  const [selectedStatus, setSelectedStatus] = useState<any>()
  const [startDate, setStartDate] = useState<any>(partnerOrderVariables.from)
  const [endDate, setEndDate] = useState<any>(partnerOrderVariables.to)
  const [isInternational, setIsInternational] = useState<any>(
    partnerOrderVariables.isInternational,
  )

  const [csvOrderData, setCsvOrderData] = useState([])

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleApplyFilter = (event: any) => {
    setPageNumber(1)
    const stDate = new Date(startDate)
    const enDate = new Date(endDate)
    event.stopPropagation()

    setPartnerOrderVariables({
      ...partnerOrderVariables,
      partner: selectedPartner,
      Search: searchKeyword,
      status: selectedStatus?.map((obj: any) => obj.value),
      from:
        stDate.getFullYear() +
        '-' +
        ('0' + (stDate.getMonth() + 1)).slice(-2) +
        '-' +
        stDate.getDate(),
      to:
        enDate.getFullYear() +
        '-' +
        ('0' + (enDate.getMonth() + 1)).slice(-2) +
        '-' +
        enDate.getDate(),
      isInternational,
    })
    if (onCloseClick) {
      onCloseClick()
    }
  }

  const onResetClick = () => {
    setPageNumber(1)
    setPartnerOrderVariables({
      ...partnerOrderVariables,
      Search: '',
      partner: '',
      status: ['ALL'],
      from:
        startDateValue.getFullYear() +
        '-' +
        ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        startDateValue.getDate(),
      to:
        endDateValue.getFullYear() +
        '-' +
        ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        endDateValue.getDate(),
      isInternational: false,
    })
    if (onCloseClick) {
      onCloseClick()
    }
  }

  /** Handling filtering orders Export as Csv */
  const handleExportCSV = () => {
    /** set partner ID empty when not selected a partner from drop down*/
    const partnerID =
      selectedPartner?.value === undefined ? '' : selectedPartner.value

    /** call process the orders Export as CSV */
    getOrdersExportCSV(
      searchKeyword,
      selectedStatus?.map((obj: any) => obj.value),
      partnerID,
      startDate,
      endDate,
      isInternational,
    )
  }

  /** Process the orders Export as CSV */
  async function getOrdersExportCSV(
    searchKeyword: string,
    status: string,
    pId: string,
    startDate: string,
    endDate: string,
    isInternational: boolean,
  ) {
    getOrdersExportCSVMutate(
      {
        searchKeyword,
        status: status,
        pId: pId,
        starDate: startDate,
        endDate: endDate,
        isInternational,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          /** set data to csv document */
          setCsvOrderData(successData)

          /** trigger csv element */
          csvLinkEl?.current?.link.click()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for filtering orders export as CSV using react-query */
  const { mutate: getOrdersExportCSVMutate } = useGetOrdersExportCSV()

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  /** notification styles */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(() => {
    const selectedStatuses: any = partnerOrderVariables.status

    const updatedState = selectedStatuses?.map((item: any) => ({
      label: list?.find((obj: any) => obj?.value === item)?.label,
      value: item,
    }))
    setSelectedStatus(updatedState)
  }, [partnerOrderVariables.status])

  useEffect(() => {
    if (selectedStatus?.length > 0) {
      const updatedArray = list.map((item, idx) => {
        if (selectedStatus?.length > 0 && selectedStatus[0]?.value !== 'ALL') {
          return {
            ...item,
            disabled: item.value === 'ALL',
          }
        } else {
          return {
            ...item,
            disabled: item.value !== selectedStatus[idx]?.value,
          }
        }
      })

      setStatusList(updatedArray)
    } else {
      setStatusList(list)
    }
  }, [selectedStatus])
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-fit bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Order Filter</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full pt-6 px-4 max-w-[580px]'>
          <div className='flex w-full flex-col'>
            <div className='flex w-full flex-col'>
              <p className='text-sm font-medium text-N-700 mb-1'>Status</p>

              <MultiSelect
                value={selectedStatus}
                onChange={(e: any) => {
                  setSelectedStatus(e)
                }}
                options={statusList}
                isMulti={true}
                placeholder='Select Status'
              />
            </div>

            <div className='flex flex-col pt-10 w-full'>
              <p className='text-sm font-medium text-N-700 mb-1'>
                Partner Code and Name
              </p>
              <SimpleSelect
                options={partnerList}
                value={selectedPartner}
                onChange={(e: any) => {
                  setSelectedPartner(e)
                }}
              />
            </div>
            <div className='flex flex-col pt-10 w-full'>
              <p className='text-sm font-medium text-N-700'>Select Date</p>
              <DateRangePickerComponent
                format='dd/MM/yyyy'
                delayUpdate={true}
                placeholder='Select a range'
                startDate={startDate}
                endDate={endDate}
                onChange={(e: any) => {
                  setStartDate(e.target.startDate)
                  setEndDate(e.target.endDate)
                }}
              />
            </div>

            <div className='flex gap-x-2 mt-6'>
              <CheckBox
                checked={isInternational}
                onChange={(e: any) => {
                  setIsInternational(e.target.checked)
                }}
              />

              <p className='text-sm font-medium text-N-700'>
                International orders only
              </p>
            </div>
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 mt-[116px] px-5 py-4'>
          <Button
            className='w-[152px]'
            onClick={(e) => handleApplyFilter(e)}
            disabled={selectedStatus?.length == 0 ? true : false}
            isLoading={false}>
            Apply
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onResetClick}>
            Reset Filters
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={handleExportCSV}>
            Export CSV
          </Button>

          {/* for csv export */}
          <CSVLink
            filename='partner_orders.csv'
            data={csvOrderData}
            ref={csvLinkEl}
          />
        </div>
      </div>
    </div>
  )
}

AdvanceSearchModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AdvanceSearchModal
