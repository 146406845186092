import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerTableInterface {
  take?: number
  skip?: number
  Search?: string
}

export const useGetPartnerTable = () => {
  const mutate = useMutation(
    ({ take, skip, Search }: GetPartnerTableInterface) => {
      return get(`/Partner?take=${take}&skip=${skip}&Search=${Search}`)
    },
  )

  return mutate
}

export default useGetPartnerTable
