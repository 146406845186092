/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import { useDeleteUser } from 'framework/api/methods/user/useDeleteUser'
import { useUserList } from 'framework/api/methods/user/useUserList'

import { PopUpAlert } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { UserView } from 'components/DrawerModals/Users'
import { AddNewUser } from 'components/DrawerModals/Users/AddNewUser'

import { DataTable } from './UserListDataTable'

export interface UserListProps {
  [x: string]: any
}

/** This is User List */

export const UserList: FC<UserListProps> = ({
  className,
  ...restProps
}: UserListProps) => {
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  const [addNewUserDrawerIsOpen, setAddNewUserDrawerIsOpen] = useState(false)
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })
  const [isOpenViewUser, setIsOpenViewUser] = useState<boolean>(false)
  const [tableVariables, setTableVariables] = useState<any>({
    take: 10,
    skip: 0,
    Search: '',
  })
  const [isAddNew, setIsAddNew] = useState(false)
  const [deleteUserPopOutIsOpen, setDeleteUserPopOutIsOpen] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')

  const [deleteUserId, setDeleteUserId] = useState('')

  /** API Call for get User list function using react-query */
  const { isLoading, data, refetch } = useUserList(tableVariables)

  /** deactivate user */

  const onSuccess = (data: any) => {
    notify(data.message, 'success')
    setDeleteUserPopOutIsOpen(false)
    refetch()
  }

  const onError = (err: any) => {
    notify(err.message || 'Error...', 'error')
  }

  /** delete user api handling */
  const { mutate: userDelete } = useDeleteUser(onSuccess, onError)

  const deleteUser = (id: any) => {
    setDeleteUserId(id)
    userDelete({
      id: id,
    })
  }

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        Search: searchKeyword,
      })
    }
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}
      {...restProps}>
      <div className='flex flex-col w-full h-full px-5 bg-white rounded-lg shadow-md'>
        <div className='py-[28px]'>
          <h2 className='text-lg font-semibold'>Users List</h2>
        </div>
        <div className='flex justify-between add-new-user-search'>
          <Button
            onClick={() => {
              setIsAddNew(true)
              setAddNewUserDrawerIsOpen(true)
            }}>
            <i className='pr-2 ri-add-circle-line'></i>Add New User
          </Button>
          <div className='relative pl-2 w-[400px]'>
            <input
              onChange={(e: any) => {
                setSearchKeyword(e.target.value)
              }}
              onKeyPress={(e: any) => {
                handleSearchKeyPress(e)
              }}
              type='text'
              placeholder='Search by First name, Last name, Email, Role'
              className='w-full py-2 pr-4 text-sm border rounded-md outline-none pl-11 text-N-500 border-N-200'
            />
            <i
              className='ri-search-2-line absolute top-[8px] h-full bottom-[0px] left-[22px] text-1xl text-N-500 cursor-pointer'
              onClick={() => {
                setTableVariables({
                  ...tableVariables,
                  Search: searchKeyword,
                })
              }}></i>
          </div>
        </div>
        <div className='w-full h-[calc(100%-128px)] pt-4'>
          <DataTable
            itemData={data?.userProfiles || []}
            setTableVariables={setTableVariables}
            tableVariables={tableVariables}
            totalRowCount={data?.totalCount}
            setAddNewUserDrawerIsOpen={setAddNewUserDrawerIsOpen}
            setSelectedRowByButtonAction={setSelectedRowByButtonAction}
            isLoading={isLoading}
            setIsOpenViewUser={setIsOpenViewUser}
            setIsAddNew={setIsAddNew}
            setDeleteUserPopOutIsOpen={setDeleteUserPopOutIsOpen}
          />
        </div>
      </div>
      {addNewUserDrawerIsOpen && (
        <AddNewUser
          isActive={addNewUserDrawerIsOpen}
          onCloseClick={() => setAddNewUserDrawerIsOpen(false)}
          userDetails={selectedRowByButtonAction}
          isAddNew={isAddNew}
          notify={notify}
          refetch={refetch}
        />
      )}
      {isOpenViewUser && (
        <UserView
          isActive={isOpenViewUser}
          onCloseClick={() => setIsOpenViewUser(false)}
          userDetails={selectedRowByButtonAction}
          setDeleteUserPopOutIsOpen={setDeleteUserPopOutIsOpen}
          setUpdatePartnerDrawerIsOpen={setAddNewUserDrawerIsOpen}
          setIsOpenViewUser={setIsOpenViewUser}
          setIsAddNew={setIsAddNew}
        />
      )}
      {deleteUserPopOutIsOpen && (
        <PopUpAlert
          isActive={deleteUserPopOutIsOpen}
          title='Delete User?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() => setDeleteUserPopOutIsOpen(false)}
          onOverlayClick={() => setDeleteUserPopOutIsOpen(false)}
          onConfirmClick={() => {
            deleteUser(selectedRowByButtonAction.id)
          }}
        />
      )}

      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
    </div>
  )
}

UserList.defaultProps = {}

export default UserList
