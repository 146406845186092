/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setUpdateCutOffTimeDrawerIsOpen,
  setSelectedRowByButtonAction,
  getCutOffTimeListIsLoading,
  setDeleteCutOffTimePopOutIsOpen,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    itemData.length > 0 ? setData([...itemData]) : setData([])
  }, [itemData])

  const { userDetails } = useSelector((state) => state.user)
  const cutOffTimeEditAccess = userDetails?.userPermissions?.includes(
    'CutOffTimeEditAccess',
  )
  const modifiedColumns = !cutOffTimeEditAccess
    ? columns.filter((column) => column.accessor !== 'action')
    : columns

  return (
    <div className='data-table w-full text-sm h-full' {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={modifiedColumns}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        setUpdateCutOffTimeDrawerIsOpen={setUpdateCutOffTimeDrawerIsOpen}
        setSelectedRowByButtonAction={setSelectedRowByButtonAction}
        getCutOffTimeListIsLoading={getCutOffTimeListIsLoading}
        setDeleteCutOffTimePopOutIsOpen={setDeleteCutOffTimePopOutIsOpen}
      />
    </div>
  )
}

export default DataTable
