import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface RegenerateKeyInterface {
  Id?: string
  PId?: string
}

export const useRegenerateKey = () => {
  const mutate = useMutation(({ Id, PId }: RegenerateKeyInterface) => {
    return putAPI(
      `/Partner/${Id}/Integration/PushAPI/${PId}/ApiKeyRegenerate`,
      {
        Id: Id,
        PId: PId,
      },
    )
  })

  return mutate
}

export default useRegenerateKey
