/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import IconICS from 'assets/images/icon-ics.svg'
import IconPaperless from 'assets/images/paperless-icon.svg'
import CN from 'classnames'
import {
  useAddWarehouseIntegration,
  useDeleteWarehouseIntegrationById,
  useGetIcsWMSWarehouseIntegrationById,
  useGetPaperlessWMSWarehouseIntegrationById,
  useUpdateWarehouseIntegration,
} from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { JsonLoader } from 'components/atoms/JsonLoader'
import { PopUpAlert } from 'components/atoms/PopUpAlert'
import { WarehouseIntegrationPanel } from 'components/molecules/WarehouseIntegrationPanel'

export interface WarehouseIntegrationProps {
  className?: string | undefined
  [x: string]: any
}

export const WarehouseIntegration: FC<WarehouseIntegrationProps> = ({
  className,
  getWarehousesList,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
}: WarehouseIntegrationProps) => {
  const AddPartnerClasses = CN(
    'warehouse-integration flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto',
    className,
    {},
  )
  const [showIntegrationDropDown, setShowIntegrationDropDown] = useState(false)
  const [newIntegrations, setNewIntegrations] = useState<any>()
  const [sectionKey, setSectionKey] = useState(Math.random())
  const [isDisabledUpdateButton, setIsDisabledUpdateButton] = useState(false)
  const [
    deletePartnerIntegrationPopOutIsOpen,
    setDeletePartnerIntegrationPopOutIsOpen,
  ] = useState(false)

  const chooseIntegration = (event: any, selectedIntegration: string) => {
    event.stopPropagation()

    setNewIntegrations({
      type: 'new',
      name: selectedIntegration,
      id: '',
      newId: 1,
      version: null,
      isActive: true,
      baseUrl: '',
      apiKey: '',
      token: '',
    })
    setShowIntegrationDropDown(false)
  }

  /** ----------------- NOTE : This phase only develop for icsWMS integrations -------------------------- */
  const {
    mutate: getIcsWMSWarehouseIntegrationByIdMutate,
    isLoading: getIcsWMSWarehouseIntegrationByIdIsLoading,
  } = useGetIcsWMSWarehouseIntegrationById()

  // /** Process to get IcsWMS warehouse Integrations */
  async function getIcsWMSWarehouseIntegrationById(id: any): Promise<void> {
    getIcsWMSWarehouseIntegrationByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const item = successData?.integration
          if (item) {
            setNewIntegrations({
              type: 'existing',
              id: item?.id,
              newId: '',
              name: item?.name,
              version: item?.apiVersion,
              isActive: item?.isActive,
              baseUrl: item.baseUrl,
              apiKey: item.apiKey,
              token: '',
            })
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  // API call for the get warehouse integration by id with paperless
  const {
    mutate: getPaperlessWMSWarehouseIntegrationByIdMutate,
    isLoading: getPaperlessWMSWarehouseIntegrationByIdIsLoading,
  } = useGetPaperlessWMSWarehouseIntegrationById()

  // /** Process to get Paperless warehouse Integrations */
  async function getPaperlessWMSWarehouseIntegrationById(
    id: any,
  ): Promise<void> {
    getPaperlessWMSWarehouseIntegrationByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const item = successData?.integration
          if (item) {
            setNewIntegrations({
              type: 'existing',
              id: item?.id,
              newId: '',
              name: item?.name,
              version: item?.apiVersion,
              isActive: item?.isActive,
              baseUrl: item.baseUrl,
              token: item.token,
              apiKey: '',
            })
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  const {
    mutate: deleteWarehouseIntegrationByIdMutate,
    isLoading: deleteWarehouseIntegrationByIdIsLoading,
  } = useDeleteWarehouseIntegrationById()

  // /** Process Delete Integration By ID  & remove it from Existing integration Array*/
  async function deleteWarehouseIntegrationById(
    warehouseId: any,
    IntegrationId: any,
  ): Promise<void> {
    deleteWarehouseIntegrationByIdMutate(
      { warehouseId: warehouseId, integrationId: IntegrationId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          setNewIntegrations(null)
          setSectionKey(Math.random())
          getWarehousesList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  useEffect(() => {
    selectedRowByButtonAction?.warehouseIntegration?.name === 'IcsWMS' &&
      getIcsWMSWarehouseIntegrationById(selectedRowByButtonAction.id)
    selectedRowByButtonAction?.warehouseIntegration?.name === 'PaperlessWMS' &&
      getPaperlessWMSWarehouseIntegrationById(selectedRowByButtonAction.id)
  }, [selectedRowByButtonAction])

  // /** Checking Fields are valid or not in each integration and set setIsDisabledUpdateButton to true if any of them is invalid */
  useEffect(() => {
    if (newIntegrations?.name === 'PaperlessWMS') {
      setIsDisabledUpdateButton(
        !newIntegrations?.baseUrl || !newIntegrations?.token,
      )
    } else {
      setIsDisabledUpdateButton(
        !newIntegrations?.baseUrl || !newIntegrations?.apiKey,
      )
    }
  }, [newIntegrations])

  // /** Deleting the Integrations individually */
  const deleteThisIntegration = () => {
    setDeletePartnerIntegrationPopOutIsOpen(false)

    const { type } = newIntegrations

    if (type === 'new') {
      setNewIntegrations(null)
    }
    if (type === 'existing') {
      deleteWarehouseIntegrationById(
        selectedRowByButtonAction.id,
        newIntegrations.id,
      )
    }
  }

  const {
    mutate: updateWarehouseIntegrationMutate,
    isLoading: updateWarehouseIntegrationIsLoading,
  } = useUpdateWarehouseIntegration()

  const {
    mutate: addWarehouseIntegrationMutate,
    isLoading: addWarehouseIntegrationIsLoading,
  } = useAddWarehouseIntegration()

  const updateIntegration = () => {
    if (newIntegrations.type === 'new') {
      /** Call Mutate with Created Objects */
      addWarehouseIntegrationMutate(
        {
          warehouseId: selectedRowByButtonAction.id,
          apiKey:
            newIntegrations?.name === 'IcsWMS'
              ? newIntegrations?.apiKey
              : undefined,
          baseUrl: newIntegrations?.baseUrl,
          name: newIntegrations?.name,
          token:
            newIntegrations?.name === 'PaperlessWMS'
              ? newIntegrations?.token
              : undefined,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify(successData.message, 'success')
            getWarehousesList()
            onCloseClick()
          },
          onError: ({ response: { data: errData } }: any) => {
            notify(errData.message || 'Error', 'error')
          },
        },
      )
    } else {
      /** Call Mutate with Created Objects */
      updateWarehouseIntegrationMutate(
        {
          warehouseId: selectedRowByButtonAction.id,
          apiKey:
            newIntegrations?.name === 'IcsWMS'
              ? newIntegrations?.apiKey
              : undefined,
          token:
            newIntegrations?.name === 'PaperlessWMS'
              ? newIntegrations?.token
              : undefined,
          baseUrl: newIntegrations?.baseUrl,
          name: newIntegrations?.name,
          id: newIntegrations?.id,
        },
        {
          onSuccess: ({ data: successData }: any) => {
            notify(successData.message, 'success')
            getWarehousesList()
            onCloseClick()
          },
          onError: ({ response: { data: errData } }: any) => {
            notify(errData.message || 'Error', 'error')
          },
        },
      )
    }
  }

  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      title={`Add New Warehouse Integration`}>
      <div className={AddPartnerClasses}>
        {(getIcsWMSWarehouseIntegrationByIdIsLoading ||
          deleteWarehouseIntegrationByIdIsLoading ||
          updateWarehouseIntegrationIsLoading ||
          addWarehouseIntegrationIsLoading ||
          getPaperlessWMSWarehouseIntegrationByIdIsLoading) && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}

        <div className='flex bg-N-50 w-full justify-between items-center px-[30px] py-4 rounded-lg mt-6 cursor-pointer'>
          <div className='flex flex-col justify-center'>
            <h2 className='text-lg font-bold text-N-700'>
              Warehouse Integration
            </h2>
            <p className='text-sm text-N-700 pt-1'>
              Integrate API integrations for warehouses (You can add multiple
              integrations)
            </p>
          </div>
          <div
            className='relative'
            onClick={() => {
              !newIntegrations &&
                setShowIntegrationDropDown(!showIntegrationDropDown)
            }}>
            <i
              className={CN('ri-add-circle-line text-4xl', {
                'text-P-500': !newIntegrations,
                'text-P-200 cursor-not-allowed': newIntegrations,
              })}></i>
            {showIntegrationDropDown && (
              <ul className='drop-me absolute right-0 top-[110%] bg-white w-40 rounded-lg shadow-2xl'>
                <li
                  onClick={(event: any) => {
                    chooseIntegration(event, 'IcsWMS')
                  }}>
                  <div className='flex items-center p-[14px] hover:bg-N-50 active:bg-N-100 rounded-t-lg'>
                    <img src={IconICS} alt='IconICS' />
                    <p className='text-sm pl-[10px]'>ICS</p>
                  </div>
                </li>
                <li
                  onClick={(event: any) => {
                    chooseIntegration(event, 'PaperlessWMS')
                  }}>
                  <div className='flex items-center p-[14px] hover:bg-N-50 active:bg-N-100 rounded-t-lg'>
                    <img src={IconPaperless} alt='paperlessIcon' />
                    <p className='text-sm pl-[10px]'>Paperless</p>
                  </div>
                </li>
              </ul>
            )}
          </div>
        </div>
        {!newIntegrations && (
          <div className='flex w-full h-full items-center justify-center flex-col'>
            <h2 className='text-2xl text-N-700 font-medium'>
              No Integrations Found
            </h2>
            <p className='font-normal text-N-500'>
              You can add integrations by clicking on above orange plus button
            </p>
          </div>
        )}

        <div key={sectionKey}>
          {newIntegrations && (
            <WarehouseIntegrationPanel
              key={1}
              indexValue={1}
              setDeletePartnerIntegrationPopOutIsOpen={
                setDeletePartnerIntegrationPopOutIsOpen
              }
              setNewIntegrations={setNewIntegrations}
              newIntegrations={newIntegrations}
              warehouseId={selectedRowByButtonAction.id}
              notify={notify}
            />
          )}
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
        <Button
          disabled={isDisabledUpdateButton || !newIntegrations}
          isLoading={
            getIcsWMSWarehouseIntegrationByIdIsLoading ||
            deleteWarehouseIntegrationByIdIsLoading ||
            updateWarehouseIntegrationIsLoading ||
            addWarehouseIntegrationIsLoading ||
            getPaperlessWMSWarehouseIntegrationByIdIsLoading
          }
          onClick={() => {
            updateIntegration()
          }}>
          Save Integration
        </Button>

        <Button
          className='ml-5 w-[152px]'
          appearance='dull'
          onClick={() => {
            onCloseClick()
            getWarehousesList()
          }}>
          Close
        </Button>
      </div>
      {deletePartnerIntegrationPopOutIsOpen && (
        <PopUpAlert
          isActive={deletePartnerIntegrationPopOutIsOpen}
          title='Delete Integration?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() => setDeletePartnerIntegrationPopOutIsOpen(false)}
          onOverlayClick={() => setDeletePartnerIntegrationPopOutIsOpen(false)}
          onConfirmClick={() => {
            deleteThisIntegration()
          }}
        />
      )}
    </Drawer>
  )
}

WarehouseIntegration.defaultProps = {
  className: undefined,
}

export default WarehouseIntegration
