import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetBulkOrderInterface {
  bulkConsignmentId?: string
}

export const useGetBulkOrder = () => {
  const mutate = useMutation(({ bulkConsignmentId }: GetBulkOrderInterface) => {
    return get(`/BulkConsignments/${bulkConsignmentId}`)
  })

  return mutate
}

export default useGetBulkOrder
