/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Badge } from 'components/atoms/Badge'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { JsonLoader } from 'components/atoms/JsonLoader'
import { IntegrationPanelView } from 'components/molecules/IntegrationPanel'

export interface ViewPartnerProps {
  className?: string | undefined
  [x: string]: any
}

export const ViewPartner: FC<ViewPartnerProps> = ({
  className,
  isActive,
  notify,
  onCloseClick,
  selectedRowByButtonAction,
  setDeletePartnerPopOutIsOpen,
  setPartnerIntegrationDrawerIsOpen,
  setUpdatePartnerDrawerIsOpen,
  useGetPartnerById,
  useGetPartnerIntegrationById,
}: ViewPartnerProps) => {
  const [viewPartnerDetails, setViewPartnerDetails] = useState<any>({
    status: true,
    partnerName: '',
    partnerCode: '',
    contactPerson: '',
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    streetAddress: '',
    suburb: '',
    state: '',
    postalCode: '',
    country: '',
  })
  /** API Call using react-query */
  const { mutate: getPartnerByIdMutate, isLoading: getPartnerByIdIsLoading } =
    useGetPartnerById()
  const {
    mutate: getPartnerIntegrationByIdMutate,
    isLoading: getPartnerIntegrationByIdIsLoading,
  } = useGetPartnerIntegrationById()
  const [newIntegrations, setNewIntegrations] = useState([] as any)

  /** Process the get Partner Table Data */
  async function getPartnerById(id: any): Promise<void> {
    getPartnerByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          const {
            isActive,
            name,
            code,
            contactName,
            contactEmail,
            supportEmail,
            contactPhone,
            address,
          } = successData.partner
          setViewPartnerDetails({
            ...viewPartnerDetails,
            partnerId: id,
            status: isActive,
            partnerName: name || '-',
            partnerCode: code || '-',
            contactPerson: contactName || '-',
            contactEmail: contactEmail || '-',
            supportEmail: supportEmail || '-',
            phoneNo: contactPhone || '-',
            streetAddress: address.lineOne || '-',
            suburb: address.suburb || '-',
            state: address.state || '-',
            postalCode: address.postCode || '-',
            country: { value: address.countryCode, label: address.country },
          })
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message, 'error')
          onCloseClick()
        },
      },
    )
  }

  useEffect(() => {
    getPartnerById(selectedRowByButtonAction.id)
    getPartnerIntegrationById(selectedRowByButtonAction.id)
  }, [selectedRowByButtonAction])

  /** Process to get partner Integrations */
  async function getPartnerIntegrationById(id: any): Promise<void> {
    getPartnerIntegrationByIdMutate(
      { id },
      {
        onSuccess: ({ data: successData }: any) => {
          if (successData.detailedPartnerIntegrations.length > 0) {
            const toBeAdded = successData.detailedPartnerIntegrations.map(
              (item: any) => {
                if (item.name === 'Shopify') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: true,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    isStockValidating: item.isStockValidating,
                    warehouses: {
                      value: item.warehouses[0]?.code,
                      label: item.warehouses[0]?.name,
                    },
                    apiBaseUrl: item.baseUrl,
                    apiPassword: item.apiPassword,
                    locationId: item.locationId,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                  }
                }
                if (item.name === 'Woocommerce') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: true,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    isStockValidating: item.isStockValidating,
                    warehouses: {
                      value: item.warehouses[0]?.code,
                      label: item.warehouses[0]?.name,
                    },
                    apiBaseUrl: item.baseUrl,
                    consumerKey: item.consumerKey,
                    consumerSecret: item.consumerSecret,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                  }
                }
                if (item.name === 'PushApi') {
                  return {
                    type: 'existing',
                    id: item.id,
                    newId: '',
                    name: item.name,
                    version: item.apiVersion,
                    isActive: true,
                    extractOrders: item.extractOrders,
                    updateTrackingInfo: item.updateTrackingInfo,
                    updateShippingInfo: item.updateShippingInfo,
                    enabledSubCarrierAccount: item.enabledSubCarrierAccount,
                    isStockValidating: item.isStockValidating,
                    warehouses: {
                      value: item.warehouses[0]?.code,
                      label: item.warehouses[0]?.name,
                    },
                    apiKey: item.apiKey,
                  }
                }
              },
            )
            setNewIntegrations(toBeAdded.slice())
          }
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message, 'error')
          onCloseClick()
        },
      },
    )
  }

  const AddPartnerClasses = CN(
    'AddPartner flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto',
    className,
    {},
  )

  const ViewSection = (
    heading: string,
    value: string,
    viewAlignment: string,
  ) => {
    return (
      <div
        className={CN('flex flex-col', {
          'w-1/2 pr-2': viewAlignment === 'left',
          'w-1/2 pl-2': viewAlignment === 'right',
          'w-full': viewAlignment === 'full',
        })}>
        <span className='text-N-500 text-sm'>{heading}</span>
        <span className='text-N-700 font-semibold pt-[2px]'>{value}</span>
      </div>
    )
  }

  return (
    <Drawer
      isActive={isActive}
      onOverlayClick={onCloseClick}
      onCloseClick={onCloseClick}
      title={viewPartnerDetails.partnerName}
      objectAfterTitle={
        <div className='pl-[14px]'>
          <Badge
            appearance={viewPartnerDetails.status ? 'success' : 'error'}
            labelValue={viewPartnerDetails.status ? 'ACTIVE' : 'IN-ACTIVE'}
          />
        </div>
      }>
      <div className={AddPartnerClasses}>
        {(getPartnerByIdIsLoading || getPartnerIntegrationByIdIsLoading) && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}
        <div className='flex w-full'>
          <span className='text-N-700 text-lg font-bold w-36'>
            Partner Info
          </span>
          <Button
            onClick={() => {
              onCloseClick()
              setUpdatePartnerDrawerIsOpen(true)
            }}
            size='xs'
            appearance='dull'>
            <i className='ri-edit-line'></i>
          </Button>
          <Button
            onClick={() => {
              setDeletePartnerPopOutIsOpen(true)
            }}
            size='xs'
            appearance='danger'
            className='ml-4'>
            <i className='ri-delete-bin-6-line'></i>
          </Button>
        </div>
        <div className='flex w-full items-center pt-[18px]'>
          {ViewSection('Partner Name:', viewPartnerDetails.partnerName, 'left')}
          {ViewSection(
            'Partner Code:',
            viewPartnerDetails.partnerCode,
            'right',
          )}
        </div>
        <div className='flex w-full items-center pt-[14px]'>
          {ViewSection(
            'Contact Person:',
            viewPartnerDetails.contactPerson,
            'left',
          )}
          {ViewSection(
            'Contact Email:',
            viewPartnerDetails.contactEmail,
            'right',
          )}
        </div>
        <div className='flex w-full items-center pt-[14px]'>
          {ViewSection(
            'Support Email:',
            viewPartnerDetails.supportEmail,
            'left',
          )}
          {ViewSection('Phone No:', viewPartnerDetails.phoneNo, 'right')}
        </div>
        <div className='flex w-full items-center pt-[14px]'>
          {ViewSection(
            'Street Address:',
            viewPartnerDetails.streetAddress,
            'full',
          )}
        </div>
        <div className='flex w-full items-center pt-[14px]'>
          {ViewSection('Suburb:', viewPartnerDetails.suburb, 'left')}
          {ViewSection('State:', viewPartnerDetails.state, 'right')}
        </div>
        <div className='flex w-full items-center pt-[14px]'>
          {ViewSection('Postal Code:', viewPartnerDetails.postalCode, 'left')}
          {ViewSection('Country:', viewPartnerDetails.country.label, 'right')}
        </div>
        <div className='w-full pt-10'>
          <div className='flex items-center'>
            <h2 className='text-lg font-bold text-N-700'>Integration</h2>
            <p className='text-sm text-N-500 pl-1'>
              ({newIntegrations.length} integration)
            </p>
            <Button
              onClick={() => {
                onCloseClick()
                setPartnerIntegrationDrawerIsOpen(true)
              }}
              size='xs'
              appearance='dull'
              className='ml-6'>
              <i className='ri-edit-line'></i>
            </Button>
          </div>
          {newIntegrations.length > 0 &&
            newIntegrations.map((integration: any, idx: any) => (
              <IntegrationPanelView
                IntegrationData={integration}
                setNewIntegrations={setNewIntegrations}
                newIntegrations={newIntegrations}
                key={idx}
                indexValue={idx}
              />
            ))}
        </div>
      </div>
    </Drawer>
  )
}

ViewPartner.defaultProps = {
  className: undefined,
}

export default ViewPartner
