import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

import { timeIntoDateTimeFormat } from 'utils'

interface Document {
  invoiceId: string
  fileName: string
  type: string
  url: string
  isDeleted: boolean
}
interface UpdateInvoiceInterface {
  id: string
  partnerCode?: string
  invoiceId?: string
  type?: string
  title?: string
  year?: string
  month?: string
  dateRaised?: string
  dueDate?: string
  amount?: number
  isPaid?: boolean
  status?: boolean
  invoiceFiles?: File[]
  newFiles?: File[]
  removedDocuments?: Document[]
}
export const useUpdateInvoice = () => {
  const mutate = useMutation((data: UpdateInvoiceInterface) => {
    console.log('data', data)
    const formData = new FormData()
    // Append all text-based fields
    formData.append('id', data.id)
    formData.append('partnerCode', data.partnerCode || '')
    formData.append('invoiceId', data.invoiceId || '')
    formData.append('title', data.title || '')
    formData.append('type', data.type || '')
    formData.append('year', data.year || '')
    formData.append('month', data.month || '')
    formData.append('dateRaised', data.dateRaised || '')
    formData.append('dueDate', data.dueDate || '')
    formData.append('amount', data.amount?.toString() || '0')
    formData.append('isPaid', data.isPaid ? 'true' : 'false')
    formData.append('status', data.status ? 'true' : 'false')
    // Append files if present
    if (data.invoiceFiles && data.invoiceFiles.length > 0) {
      data.invoiceFiles.forEach((file) => {
        formData.append('invoiceFiles', file)
      })
    }
    if (data.newFiles && data.newFiles.length > 0) {
      data.newFiles.forEach((file) => {
        formData.append('newFiles', file)
      })
    }
    data.removedDocuments?.forEach((document, index) => {
      formData.append(`RemovedDocuments[${index}]`, JSON.stringify(document))
    })
    console.log('formdata', formData.entries())
    return putAPI('/UpdateInvoice', formData)
  })
  return mutate
}
export default useUpdateInvoice
