import { useMutation } from 'react-query'
import { post } from 'framework/api/http'

const userActivate = async (reqBody: any) => {
  const res = await post('/UserProfile/Activate', reqBody)
  return res.data
}

export const useUserActivate = (onSettled: any, onError: any) => {
  return useMutation(userActivate, {
    onSettled,
    onError,
  })
}
