import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'

const Table = ({
  columns,
  data,
  selectedTableRows,
  handleInputChange,
  handleButtonClick,
  handleButtonCloseClick,
  partnerOrderStatus,
  deleteOrderLinePopOut,
  setDeleteOrderLinePopOut,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 30,
        pageIndex: 0,
        selectedRowIds: selectedTableRows || [],
      },
      manualPagination: true,
      manualSortBy: false,
      autoResetPage: false,
      autoResetSelectedRows: false,
      disableSortBy: false,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((cols) => [
    //     {
    //       id: 'selection',
    //       customWidth: 32,
    //       Cell: ({ row }) => <div {...row.getToggleRowSelectedProps()} />,
    //     },
    //     ...cols,
    //   ])
    // },
  )

  const slicedPageData = page

  return (
    <>
      <div className='styled-scroll h-[calc(100%-60px)] relative overflow-y-auto rounded-t-md border border-N-200'>
        <table className='w-full overflow-y-auto' {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={CN(
                      'bg-N-100 border-b border-b-N-200 sticky top-0 shadow-sm text-left pl-4 z-[1]',
                      {
                        'bg-N-200': column.isSorted,
                      },
                    )}
                    style={{ height: 40 }}
                    width={column.customWidth}>
                    <div
                      className='inline-flex items-center'
                      style={{ minHeight: 20 }}>
                      {column.render('Header')}
                      <span className='flex ml-1' style={{ width: 14 }}>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <i className='ri-arrow-up-s-line' />
                          ) : (
                            <i className='ri-arrow-down-s-line' />
                          ))}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {slicedPageData.map((row) => {
              prepareRow(row)
              return (
                <tr
                  className={CN('h-11  cursor-pointer', {
                    'border-l-8 border-l-R-500':
                      row.original.wareHouseOrderLineError !== null,
                  })}
                  {...row.getRowProps()}
                  onClick={() => {
                    toggleAllRowsSelected(false)
                    row.toggleRowSelected()
                  }}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={CN(
                        'border-b border-b-N-200 text-sm text-N-700 py-[11px] px-4',
                        {
                          'bg-N-300 border-b border-b-N-400': cell.isSelected,
                        },
                      )}>
                      {cell.column.isWarehouseSKU ? (
                        <div className='flex items-center w-fit'>
                          {row.original.showEditView ? (
                            <input
                              type='text'
                              value={row.original.warehouseSKU}
                              onChange={(e) => {
                                handleInputChange(row.original, e.target.value)
                              }}
                              className='text-sm font-normal text-N-700 px-3 py-[6px] border border-N-200 rounded-md w-24 outline-none active:border-N-300'
                            />
                          ) : (
                            <p className='text-sm font-normal text-N-700'>
                              {row.original.warehouseSKU}
                            </p>
                          )}
                          {(partnerOrderStatus === 'RECEIVED' ||
                            partnerOrderStatus === 'FAILED' ||
                            partnerOrderStatus === 'ERRORED') && (
                            <Button
                              disabled={
                                row.original.showEditView &&
                                row.original.warehouseSKU.trim().length === 0
                              }
                              className='ml-1'
                              onClick={(e) => {
                                e.stopPropagation()
                                handleButtonClick(
                                  row.original,
                                  row.original.showEditView ? 'update' : 'edit',
                                )
                              }}
                              appearance='dull'
                              size='xs'>
                              <i
                                className={CN({
                                  'ri-edit-line': !row.original.showEditView,
                                  'ri-save-line': row.original.showEditView,
                                })}></i>
                            </Button>
                          )}

                          {(partnerOrderStatus === 'FAILED' ||
                            partnerOrderStatus === 'ERRORED') &&
                            data?.length > 1 && (
                              <Button
                                className='ml-1'
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setDeleteOrderLinePopOut({
                                    ...deleteOrderLinePopOut,
                                    enable: true,
                                    orderLineId: row.original?.id,
                                  })
                                }}
                                appearance='dull'
                                size='xs'>
                                <i
                                  className={CN({
                                    'ri-delete-bin-line':
                                      !row.original.showEditView,
                                  })}></i>
                              </Button>
                            )}

                          {row.original.showEditView && (
                            <Button
                              className='ml-1'
                              onClick={(e) => {
                                e.stopPropagation()
                                handleButtonCloseClick(row.original)
                              }}
                              appearance='dull'
                              size='xs'>
                              <i className='ri-close-line'></i>
                            </Button>
                          )}
                        </div>
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

Table.propTypes = {}

export default Table
