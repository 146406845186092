/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  deleteOrderLinePopOut,
  itemData,
  updateOrderLineById,
  partnerOrderId,
  partnerOrderStatus,
  setDeleteOrderLinePopOut,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  const handleButtonClick = (rowDetails, mode) => {
    if (mode === 'edit') {
      const toBeUpdated = data.map((item) => {
        if (item.id === rowDetails.id) {
          return {
            ...item,
            showEditView: !item.showEditView,
          }
        }
        return {
          ...item,
          showEditView: false,
          warehouseSKU: item.originalValueWarehouseSKU,
        }
      })
      setData(toBeUpdated)
    }
    if (mode === 'update') {
      updateOrderLineById({
        partnerOrderId: partnerOrderId,
        orderLineId: rowDetails.id,
        warehouseSKU: rowDetails.warehouseSKU,
      })
    }
  }

  const handleButtonCloseClick = (rowDetails) => {
    const toBeUpdated = data.map((item) => {
      if (item.id === rowDetails.id) {
        return {
          ...item,
          showEditView: false,
          warehouseSKU: item.originalValueWarehouseSKU,
        }
      }
      return item
    })
    setData(toBeUpdated)
  }

  const handleInputChange = (rowDetails, value) => {
    const toBeUpdated = data.map((item) => {
      if (item.id === rowDetails.id) {
        return {
          ...item,
          warehouseSKU: value,
        }
      }
      return item
    })
    setData(toBeUpdated)
  }

  useEffect(() => {
    if (itemData?.length > 0) {
      const toBeUpdated = itemData.map((item) => ({
        ...item,
        showEditView: false,
        originalValueWarehouseSKU: item.warehouseSKU,
      }))
      setData([...toBeUpdated])
    }
  }, [itemData])

  return (
    <div className='data-table w-full text-sm h-full' {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={columns}
        handleInputChange={handleInputChange}
        handleButtonClick={handleButtonClick}
        handleButtonCloseClick={handleButtonCloseClick}
        partnerOrderStatus={partnerOrderStatus}
        deleteOrderLinePopOut={deleteOrderLinePopOut}
        setDeleteOrderLinePopOut={setDeleteOrderLinePopOut}
      />
    </div>
  )
}

export default DataTable
