/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import {
  CANCELLED,
  ENTERED,
  FAILED,
  RECEIVED,
  STOCKRECEIVED,
} from 'static-data/stockOrderStatus'

import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

import './AdvanceSearchModal.scss'

export interface AdvanceSearchModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  partnerList?: any
  setStockOrderVariables?: any
  stockOrderVariables?: any
  searchKeyword?: any
  startDateValue?: any
  endDateValue?: any
  setPageNumber?: any
}

export const AdvanceSearchModal: FC<AdvanceSearchModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  partnerList,
  setStockOrderVariables,
  stockOrderVariables,
  searchKeyword,
  startDateValue,
  endDateValue,
  setPageNumber,
}: AdvanceSearchModalProps) => {
  const list = [
    {
      id: 1,
      name: 'name',
      label: 'All',
      selected: false,
      value: 'ALL',
    },
    {
      id: 2,
      name: 'name',
      label: RECEIVED,
      selected: false,
      value: RECEIVED,
    },
    {
      id: 3,
      name: 'name',
      label: ENTERED,
      selected: false,
      value: ENTERED,
    },
    {
      id: 4,
      name: 'name',
      label: STOCKRECEIVED,
      selected: false,
      value: STOCKRECEIVED,
    },
    {
      id: 5,
      name: 'name',
      label: FAILED,
      selected: false,
      value: FAILED,
    },
    {
      id: 6,
      name: 'name',
      label: CANCELLED,
      selected: false,
      value: CANCELLED,
    },
  ]

  const [selectedPartner, setSelectedPartner] = useState<any>(
    stockOrderVariables.partner,
  )
  const [selectedStatus, setSelectedStatus] = useState<any>(
    stockOrderVariables.status,
  )
  const [startDate, setStartDate] = useState<any>(stockOrderVariables.from)
  const [endDate, setEndDate] = useState<any>(stockOrderVariables.to)

  useEffect(() => {
    setSelectedStatus(list?.find((item: any) => item.label === selectedStatus))
  }, [isActive])

  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const handleApplyFilter = (event: any) => {
    setPageNumber(1)
    const stDate = new Date(startDate)
    const enDate = new Date(endDate)
    event.stopPropagation()
    setStockOrderVariables({
      ...stockOrderVariables,
      partner: selectedPartner,
      Search: searchKeyword,
      status: capitalizeFirstLetter(selectedStatus?.label),
      from:
        stDate.getFullYear() +
        '-' +
        ('0' + (stDate.getMonth() + 1)).slice(-2) +
        '-' +
        stDate.getDate(),
      to:
        enDate.getFullYear() +
        '-' +
        ('0' + (enDate.getMonth() + 1)).slice(-2) +
        '-' +
        enDate.getDate(),
    })
    if (onCloseClick) {
      onCloseClick()
    }
  }

  const onResetClick = () => {
    // setPageNumber(1)
    // setStockOrderVariables({
    //   ...stockOrderVariables,
    //   Search: '',
    //   partner: '',
    //   status: 'All',
    //   from:
    //     startDateValue.getFullYear() +
    //     '-' +
    //     ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
    //     '-' +
    //     startDateValue.getDate(),
    //   to:
    //     endDateValue.getFullYear() +
    //     '-' +
    //     ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
    //     '-' +
    //     endDateValue.getDate(),
    // })
    setSelectedPartner('')
    setStartDate(startDateValue)
    setEndDate(endDateValue)
    setSelectedStatus({ label: 'All', value: 'All' })
    // if (onCloseClick) {
    //   onCloseClick()
    // }
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-2/5 bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Order Filter</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full pt-6 px-4'>
          <div className='flex w-2/3 flex-col'>
            <p className='text-sm font-medium text-N-700'>Status</p>
            <SimpleSelect
              options={list}
              value={selectedStatus}
              onChange={(e: any) => {
                setSelectedStatus(e)
              }}
            />
            <div className='flex flex-col pt-10'>
              <p className='text-sm font-medium text-N-700'>
                Partner Code and Name
              </p>
              <SimpleSelect
                options={partnerList}
                value={selectedPartner}
                onChange={(e: any) => {
                  setSelectedPartner(e)
                }}
              />
            </div>
            <div className='flex flex-col pt-10'>
              <p className='text-sm font-medium text-N-700'>Select Date</p>
              <DateRangePickerComponent
                format='dd/MM/yyyy'
                delayUpdate={true}
                placeholder='Select a range'
                startDate={startDate}
                endDate={endDate}
                onChange={(e: any) => {
                  setStartDate(e.target.startDate)
                  setEndDate(e.target.endDate)
                }}
              />
            </div>
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 mt-[116px] px-5 py-4'>
          <Button
            className='w-[152px]'
            onClick={(e) => handleApplyFilter(e)}
            disabled={false}
            isLoading={false}>
            Apply
          </Button>

          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>

          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onResetClick}>
            Reset Filters
          </Button>
        </div>
      </div>
    </div>
  )
}

AdvanceSearchModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AdvanceSearchModal
