import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ActivateWarehouseIntegrationInterface {
  integrationId?: string
  warehouseId?: string
}

export const useActivateWarehouseIntegration = () => {
  const mutate = useMutation(
    ({ integrationId, warehouseId }: ActivateWarehouseIntegrationInterface) => {
      return postAPI(
        `/warehouse/${warehouseId}/Integration/${integrationId}/Activate`,
        {},
      )
    },
  )

  return mutate
}

export default useActivateWarehouseIntegration
