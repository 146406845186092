/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import VerifyMailFail from 'assets/animations/confirm-mail-fail.json'
import emailSearching from 'assets/animations/email-searching.json'
import emailVerified from 'assets/animations/email-verified.json'
import { useConfirmEmail } from 'framework/api/methods'
import { useLottie } from 'lottie-react'

import { Logo } from 'components/atoms/Logo'

export interface ConfirmEmailProps {
  [x: string]: any
}

export const ConfirmEmail: FC<ConfirmEmailProps> = ({
  ...restProps
}: ConfirmEmailProps) => {
  const navigate = useNavigate()
  const [isVerifying, setIsVerifying] = useState(true)
  const [isVerifySuccess, setIsVerifySuccess] = useState(false)
  const [isVerifyFail, setIsVerifyFail] = useState(false)
  const [searchParams] = useSearchParams()
  const { mutate: confirmEmailMutate } = useConfirmEmail()

  /** get route params and verify user */
  useEffect(() => {
    const username: any = searchParams.get('user')
    const token: any = searchParams.get('token')
    confirmEmailMutate(
      { username, token },
      {
        onSuccess: ({ data: successData }: any) => {
          setIsVerifying(false)
          setIsVerifyFail(false)
          setIsVerifySuccess(true)
          setTimeout(() => {
            navigate('/', { replace: true })
          }, 3000)
        },
        onError: ({ response: { data: errData } }: any) => {
          setIsVerifying(false)
          setIsVerifySuccess(false)
          setIsVerifyFail(true)
          setTimeout(() => {
            navigate('/', { replace: true })
          }, 3000)
        },
      },
    )
  }, [])

  /** email verifying gif */
  const AnimatedEmailVerifyingView = () => {
    const options = {
      animationData: emailSearching,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  /** email verified gif */
  const AnimatedEmailVerifiedView = () => {
    const options = {
      animationData: emailVerified,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  /** email verified fail gif */
  const AnimatedEmailVerifyFailView = () => {
    const options = {
      animationData: VerifyMailFail,
      loop: false,
      autoplay: true,
    }

    const { View } = useLottie(options)
    return View
  }

  return (
    <div
      className='w-[520px] bg-white py-[71px] px-9 rounded-[20px] shadow-2xl'
      {...restProps}>
      <div className='w-full h-full'>
        <Logo />

        <div className='flex w-full pt-5'>
          {/* initial ui for verifying email */}
          {isVerifying && (
            <div className='container w-full flex flex-col justify-center items-center'>
              <div className='w-2/3 flex justify-center items-center'>
                <AnimatedEmailVerifyingView />
              </div>
              <span className='text-3xl text-N-700 font-bold'>
                Please wait.
              </span>
              <span className='text-gray-800 pt-2'>
                we are verifying your email.
              </span>
            </div>
          )}

          {/* email verification success ui */}
          {isVerifySuccess && (
            <div className='container w-full flex flex-col justify-center items-center'>
              <div className='w-2/5 flex justify-center items-center'>
                <AnimatedEmailVerifiedView />
              </div>
              <span className='text-3xl text-N-700 font-bold'>
                Email verified.
              </span>
              <span className='text-gray-800 pt-2'>
                your email has been verified.
              </span>
            </div>
          )}

          {/* email verification fail ui */}
          {isVerifyFail && (
            <div className='container w-full flex flex-col justify-center items-center'>
              <div className='w-2/5 flex justify-center items-center'>
                <AnimatedEmailVerifyFailView />
              </div>
              <span className='text-3xl text-N-700 font-bold'>Failed.</span>
              <span className='text-gray-800 pt-2'>
                User invalid or already verified.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ConfirmEmail.defaultProps = {}

export default ConfirmEmail
