import { FC } from 'react'
import CN from 'classnames'

import { Loader } from '../Loader'

export interface ButtonProps {
  className?: string | undefined
  disabled?: boolean | undefined
  isLoading?: boolean | undefined
  isCustomStyles?: boolean | undefined
  appearance?: 'default' | 'outline' | 'dull' | 'danger' | undefined
  onClick?: (e: any) => void | undefined
  isFullWidth?: boolean | undefined
  size?: 'xs' | 'sm' | 'md' | 'lg' | undefined
  [x: string]: any
}

export const Button: FC<ButtonProps> = ({
  children,
  disabled,
  className,
  isCustomStyles,
  appearance,
  isLoading,
  onClick,
  isFullWidth,
  size,
}: ButtonProps) => {
  const ButtonClasses = !isCustomStyles
    ? CN(
        'button font-semibold rounded',
        className,
        {
          'bg-P-500 hover:bg-P-600 active:bg-P-700 disabled:bg-P-300 text-white':
            appearance === 'default' || !appearance,
          'bg-white hover:bg-P-50 active:bg-P-100 disabled:bg-white text-P-500 border border-P-500 disabled:border-P-300 disabled:text-P-300':
            appearance === 'outline',
          'bg-N-50 hover:bg-N-100 active:bg-N-200 disabled:bg-N-50 text-N-700':
            appearance === 'dull',
          'bg-R-50 hover:bg-R-100 active:bg-R-200 disabled:bg-R-50 text-R-500':
            appearance === 'danger',
        },
        {
          'cursor-not-allowed': disabled,
          'cursor-pointer': !disabled,
        },
        {
          'w-full': isFullWidth,
          'w-fit': !isFullWidth,
        },
        {
          'px-[26px] py-[10px] text-lg': size === 'lg',
          'px-[30px] py-2': size === 'md',
          'px-8 py-[6px] text-sm': size === 'sm',
          'px-2 py-1 text-xs': size === 'xs',
        },
      )
    : className
  return (
    <button onClick={onClick} disabled={disabled} className={ButtonClasses}>
      <div className='relative w-full'>
        <div
          className={CN({
            'opacity-0 flex items-center justify-center': isLoading,
            'opacity-100 flex items-center justify-center': !isLoading,
          })}>
          {children}
        </div>
        {isLoading && (
          <div className='absolute translate-x-[-50%] translate-y-[-150%] left-[50%]'>
            <Loader />
          </div>
        )}
      </div>
    </button>
  )
}

Button.defaultProps = {
  className: undefined,
  appearance: undefined,
  onClick: undefined,
  isLoading: false,
  isFullWidth: false,
  size: 'md',
}

export default Button
