import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePartnerIntegrationByIdInterface {
  partnerId?: string
  integrationId?: string
}

export const useDeletePartnerIntegrationById = () => {
  const mutate = useMutation(
    ({ partnerId, integrationId }: DeletePartnerIntegrationByIdInterface) => {
      return deleteAPI(`/Partner/${partnerId}/Integration/${integrationId}`)
    },
  )

  return mutate
}

export default useDeletePartnerIntegrationById
