/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  setTableVariables,
  tableVariables,
  totalRowCount,
  setAddNewUserDrawerIsOpen,
  setSelectedRowByButtonAction,
  isLoading,
  setIsOpenViewUser,
  setIsAddNew,
  setDeleteUserPopOutIsOpen,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData.length > 0) {
      setData([...itemData])
    } else if (itemData.length == 0) {
      setData([])
    }
  }, [itemData])

  return (
    <div className='w-full h-full text-sm data-table' {...restProps}>
      <Table
        data={data}
        loading={loading}
        columns={columns}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        setAddNewUserDrawerIsOpen={setAddNewUserDrawerIsOpen}
        setSelectedRowByButtonAction={setSelectedRowByButtonAction}
        isLoading={isLoading}
        setIsOpenViewUser={setIsOpenViewUser}
        setIsAddNew={setIsAddNew}
        setDeleteUserPopOutIsOpen={setDeleteUserPopOutIsOpen}
      />
    </div>
  )
}

export default DataTable
