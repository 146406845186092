import React, { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import { useGetUserPrivilegesTable } from 'framework/api/methods'

import { DataTable } from './UserPrivilegesDataTable'

export interface UserPrivilegesListProps {
  [x: string]: any
}

/** This is user privileges */

export const UserPrivilegesList: FC<UserPrivilegesListProps> = ({
  className,
}: UserPrivilegesListProps) => {
  const [rows, setRows] = useState({})

  /** API Call for get user privileges using react-query */
  const {
    mutate: getUserPrivilegesTableMutate,
    isLoading: getUserPrivilegesTableIsLoading,
  } = useGetUserPrivilegesTable()

  /** Process the get User privileges Table Data */
  async function getUserPrivilegesTableList() {
    getUserPrivilegesTableMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData.appPermissions)
          // setTotalRowCount(successData.totalCount)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** notify message */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  useEffect(() => {
    getUserPrivilegesTableList()
  }, [])

  return (
    <div
      className={CN(
        'flex justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>User Privileges</h2>
          <div className='w-[606px] h-full pt-6 pb-5'>
            <DataTable
              itemData={rows}
              getUserPrivilegesTableIsLoading={getUserPrivilegesTableIsLoading}
            />
          </div>

          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

UserPrivilegesList.defaultProps = {}

export default UserPrivilegesList
