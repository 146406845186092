import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

/* Get all sample users */
export const getRoleList = async () => {
  const res = await get(`identity/role`)

  return res.data
}

/* Use Query as a Hook */
export const useRoleList = () => {
  return useQuery('divisions', getRoleList, {
    refetchOnWindowFocus: true,
  })
}
