/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useDeleteStockOrderOrderLines,
  useGetPartnerList,
  useGetStockOrderDetailsById,
  useGetStockOrdersList,
  useStockOrderRetry,
  useUpdateStockOrder,
  useUpdateStockOrderLineById,
  useUpdateStockOrderStatusCancel,
} from 'framework/api/methods'
import { CANCELLED, ENTERED, FAILED } from 'static-data/stockOrderStatus'

import { PopUpAlert } from 'components/atoms'
import { Button } from 'components/atoms/Button/Button'
import { JsonLoader } from 'components/atoms/JsonLoader'
import { JsonSearchEmpty } from 'components/atoms/JsonSearchEmpty'
import { PopOut } from 'components/atoms/PopOut'
import { UpdateOrderLine } from 'components/DrawerModals/StockOrders'
import { StockOrderCard } from 'components/molecules/StockOrderCard'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

import { OrderFooter } from './sections/OrderFooter'
import { AdvanceSearchModal } from './AdvanceSearchModal'
import { DataTable } from './OrderLineDataTable'
import {
  FilterChipSection,
  FooterWithPagination,
  OrderHeader,
  ShippingDetailEditSection,
  ShippingDetailViewSection,
} from './sections'

import 'react-toastify/dist/ReactToastify.css'

export interface StockOrderProps {
  [x: string]: any
}

/** This is Orders */

export const StockOrder: FC<StockOrderProps> = ({
  className,
}: StockOrderProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const stockOrderEditAccess = userDetails?.userPermissions?.includes(
    'StockOrderEditAccess',
  )

  const date = new Date()
  const startDateValue = new Date(date.getFullYear(), date.getMonth() - 2, 1)
  const endDateValue = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
  )
  const [stockOrderVariables, setStockOrderVariables] = useState<any>({
    take: 10,
    skip: 0,
    Search: '',
    partner: '',
    status: 'All',
    from:
      startDateValue.getFullYear() +
      '-' +
      ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
      '-' +
      startDateValue.getDate(),
    to:
      endDateValue.getFullYear() +
      '-' +
      ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
      '-' +
      endDateValue.getDate(),
  })

  /** Pagination & Table Related States */
  const [paginationVariables, setPaginationVariables] = useState({
    start: 0,
    end: 0,
  })
  const [searchKeyword, setSearchKeyword] = useState('')
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [filterSectionKey, setFilterSectionKey] = useState(Math.random())

  const [stockOrders, setStockOrders] = useState([])
  const [partnerList, setPartnerList] = useState([])
  const [orderDetails, setOrderDetails] = useState<any>({})
  const [selectedOrder, setSelectedOrder] = useState<any>({ id: '' })

  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)

  const [deleteOrderLinePopOut, setDeleteOrderLinePopOut] = useState({
    enable: false,
    orderLineId: '',
  })

  const [isEditOrderLineDrawerOpen, setIsEditOrderLineDrawerOpen] = useState({
    enable: false,
    orderDetails: {},
  })

  const [
    stockOrderAdvancedSearchModalIsOpen,
    setStockOrderAdvancedSearchModalIsOpen,
  ] = useState(false)
  const [isShippingDetailUpdateView, setIsShippingDetailUpdateView] =
    useState(false)
  const [editViewShippingDetails, setEditViewShippingDetails] = useState<any>(
    {},
  )

  /** Toaster Function */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** Get Stock Order Details By Id Success Function */
  const getStockOrderDetailsByIdOnSuccess = (data: any) => {
    const { stockOrder } = data
    setOrderDetails(stockOrder)
    setEditViewShippingDetails({
      ...editViewShippingDetails,
      warehouseCode: stockOrder?.warehouseCode || '',
      customerReferance: stockOrder?.customerReferance || '',
      invoiceNo: stockOrder?.invoiceNo || '',
      jobReference: stockOrder?.jobReference || '',
      eta: (stockOrder?.eta && new Date(stockOrder?.eta)) || null,
      receiptDate:
        (stockOrder?.receiptDate && new Date(stockOrder?.receiptDate)) || null,
      stockNote: stockOrder?.stockNote || '',
      instructions: stockOrder?.instructions || '',
    })
    setIsShippingDetailUpdateView(false)
  }

  /** Get Stock Order Details By Id Error Function */
  const getStockOrderDetailsByIdOnError = (error: any) => {
    notify(error, 'error')
  }

  /** API Call for Get Stock Order Details By Id */
  const {
    isLoading: GetStockOrderDetailsByIdIsLoading,
    refetch: callGetStockOrderDetailsById,
  } = useGetStockOrderDetailsById(
    {
      orderId: selectedOrder.id,
    },
    getStockOrderDetailsByIdOnSuccess,
    getStockOrderDetailsByIdOnError,
  )

  /** API Call for Order Line Update */
  const {
    mutate: updateOrderLineByIdMutate,
    isLoading: updateOrderLineByIdIsLoading,
  } = useUpdateStockOrderLineById()

  /** Process the Order Line Update API call */
  async function updateOrderLineById(updateVariables: any) {
    updateOrderLineByIdMutate(updateVariables, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message, 'success')
        setIsEditOrderLineDrawerOpen({
          enable: false,
          orderDetails: {},
        })
        callGetStockOrderDetailsById()
      },

      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call for Stock Order Shipping info Update */
  const {
    mutate: updateStockOrderMutate,
    isLoading: updateStockOrderIsLoading,
  } = useUpdateStockOrder()

  /** Process the Stock Order Shipping info Update API call */
  async function updateStockOrder() {
    const updateVariables = {
      ...editViewShippingDetails,
      id: selectedOrder.id,
    }
    updateStockOrderMutate(updateVariables, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData?.message || '', 'success')
        setIsShippingDetailUpdateView(false)
        callGetStockOrderDetailsById()
        getStockOrders()
      },

      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call For Partner List (Advanced Search Modal)  */
  const { mutate: getPartnerListMutate } = useGetPartnerList()

  /** Process the get Partner List */
  async function getPartnerList() {
    getPartnerListMutate(stockOrderVariables, {
      onSuccess: ({ data: successData }: any) => {
        setPartnerList(successData.partnerCodeNameModelList)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call For Stock Orders (Order List View)  */
  const { mutate: getStockOrdersMutate } = useGetStockOrdersList()

  /** Process the get Stock Order Table Data */
  async function getStockOrders() {
    getStockOrdersMutate(stockOrderVariables, {
      onSuccess: ({ data: successData }: any) => {
        setStockOrders(successData.stockOrders)
        setTotalRowCount(successData.totalCount)
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error...', 'error')
      },
    })
  }

  /** API Call for Retry Order (Push-Order) */
  const { mutate: stockOrderRetryMutate, isLoading: stockOrderRetryIsLoading } =
    useStockOrderRetry()

  /** Process the Push-Order API Data */
  async function pushOrder() {
    stockOrderRetryMutate(
      { stockOrderId: selectedOrder.id },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'Success...', 'success')
          getStockOrders()
          callGetStockOrderDetailsById()
        },

        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** Get Order Details by clicking Item */
  useEffect(() => {
    callGetStockOrderDetailsById()
  }, [selectedOrder])

  /** Pagination Functions */
  useEffect(() => {
    setPaginationVariables({
      ...paginationVariables,
      start:
        Number(stockOrderVariables.take) * Number(pageNumber) -
        Number(stockOrderVariables.take) +
        1,
      end:
        Number(stockOrderVariables.take) * Number(pageNumber) >= totalRowCount
          ? totalRowCount
          : Number(stockOrderVariables.take) * Number(pageNumber),
    })
  }, [stockOrderVariables, totalRowCount, pageNumber])

  /** Pagination Functions */
  useEffect(() => {
    setStockOrderVariables({
      ...stockOrderVariables,
      skip:
        pageNumber === 1
          ? 0
          : Number(stockOrderVariables.take) * (Number(pageNumber) - 1),
    })
  }, [pageNumber])

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setStockOrderVariables({
        ...stockOrderVariables,
        Search: searchKeyword,
      })
      setPageNumber(1)
    }
  }

  const handleGoBack = () => {
    setIsShippingDetailUpdateView(!isShippingDetailUpdateView)
    const stockOrder = orderDetails
    setEditViewShippingDetails({
      ...editViewShippingDetails,
      warehouseCode: stockOrder?.warehouseCode || '',
      customerReferance: stockOrder?.customerReferance || '',
      invoiceNo: stockOrder?.invoiceNo || '',
      jobReference: stockOrder?.jobReference || '',
      eta: (stockOrder?.eta && new Date(stockOrder?.eta)) || null,
      receiptDate:
        (stockOrder?.receiptDate && new Date(stockOrder?.receiptDate)) || null,
      stockNote: stockOrder?.stockNote || '',
      instructions: stockOrder?.instructions || '',
    })
  }

  useEffect(() => {
    getPartnerList()
  }, [])

  useEffect(() => {
    getStockOrders()
    setFilterSectionKey(Math.random())
    setSelectedOrder({ id: '' })
  }, [stockOrderVariables])

  /** Hide Cancel Modal */
  const hideCancelOrder = () => {
    setShowCancelOrderModal(!showCancelOrderModal)
  }

  /** Confirm Cancel Order */
  const {
    mutate: updateStockOrderStatusCancelMutate,
    isLoading: updateStockOrderStatusCancelIsLoading,
  } = useUpdateStockOrderStatusCancel()

  const confirmCancelOrder = () => {
    updateStockOrderStatusCancelMutate(
      {
        stockOrderId: selectedOrder.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          getStockOrders()
          callGetStockOrderDetailsById()
          setShowCancelOrderModal(!showCancelOrderModal)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  /** API Call for Delete Stock Order Order Line */
  const {
    mutate: deleteStockOrderOrderLinesMutate,
    isLoading: deleteStockOrderOrderLinesIsLoading,
  } = useDeleteStockOrderOrderLines()

  /** Process the Delete Stock Order Order Line */
  async function deleteStockOrderOrderLines(
    orderId: string,
    orderLineId: string,
  ) {
    deleteStockOrderOrderLinesMutate(
      { orderId, orderLineId },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')

          setDeleteOrderLinePopOut({
            ...deleteOrderLinePopOut,
            enable: false,
            orderLineId: '',
          })

          callGetStockOrderDetailsById()
          getStockOrders()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      {/* Left Side Order Card view table  */}

      <div className='left-column flex flex-col h-full w-1/3 rounded-lg bg-white shadow-md'>
        <div className='flex items-center w-full bg-white shadow-md py-[10px] px-4 rounded-t-lg'>
          <div className='flex flex-col w-full relative'>
            <input
              onChange={(e: any) => {
                setSearchKeyword(e.target.value)
              }}
              onKeyPress={(e: any) => {
                handleSearchKeyPress(e)
              }}
              type='text'
              placeholder='Search by Customer ref, WMS order'
              className='pr-3 pl-11 py-2 w-full outline-none border text-N-500 border-N-200 rounded-md text-sm'
            />
            <i
              className='ri-search-2-line absolute top-[6px] left-[15px] text-lg text-N-500 cursor-pointer'
              onClick={() => {
                setPaginationVariables({
                  ...stockOrderVariables,
                  Search: searchKeyword,
                })
                setPageNumber(1)
              }}></i>
          </div>
          <button
            onClick={() => {
              setStockOrderAdvancedSearchModalIsOpen(true)
            }}
            className='flex items-center justify-center w-8 h-9 bg-white border border-N-200 rounded ml-2 relative'>
            <i className='ri-filter-2-line text-N-500'></i>
            {((stockOrderVariables.status !== 'All' &&
              stockOrderVariables.status !== '') ||
              !(
                stockOrderVariables.from.toString() ===
                  (
                    startDateValue.getFullYear() +
                    '-' +
                    ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
                    '-' +
                    startDateValue.getDate()
                  ).toString() &&
                stockOrderVariables.to.toString() ===
                  (
                    endDateValue.getFullYear() +
                    '-' +
                    ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
                    '-' +
                    endDateValue.getDate()
                  ).toString()
              ) ||
              stockOrderVariables.partner.value !== undefined) && (
              <div className='w-[6px] h-[6px] bg-R-500 rounded-full absolute top-[-3px] right-[-3px]'></div>
            )}
          </button>
        </div>
        <div className='flex flex-col items-center justify-start w-full h-full overflow-y-auto styled-scroll'>
          {stockOrders.length > 0 &&
            stockOrders.map((order: any, idx: any) => (
              <StockOrderCard
                key={idx}
                orderId={order?.customerReferance}
                orderStatus={capitalizeFirstLetter(order?.status)}
                partnerName={order?.partnerName}
                orderDate={order?.createdOn}
                integrationType={order?.integrationType}
                wmsId={order?.wmsOrderNo || '-'}
                selectedOrder={selectedOrder}
                orderUid={order?.id}
                onClick={() => {
                  setSelectedOrder({
                    ...selectedOrder,
                    id: order?.id,
                  })
                }}
              />
            ))}
        </div>
        {/* Left Side Order Card view table pagination Section  */}
        <FooterWithPagination
          stockOrderVariables={stockOrderVariables}
          setPageNumber={setPageNumber}
          setStockOrderVariables={setStockOrderVariables}
          paginationVariables={paginationVariables}
          totalRowCount={totalRowCount}
          pageNumber={pageNumber}
        />
      </div>

      {/* Right side panel  */}
      <div className='right-column flex flex-col w-2/3 h-full ml-4 relative rounded-b-md'>
        {/* Right side panel Filter Chips  */}

        <FilterChipSection
          filterSectionKey={filterSectionKey}
          stockOrderVariables={stockOrderVariables}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setStockOrderVariables={setStockOrderVariables}
          setPageNumber={setPageNumber}
        />

        <div className='flex flex-col w-full rounded-lg bg-white shadow-md h-full overflow-y-auto styled-scroll'>
          {/* Right side panel Animation  */}
          {!selectedOrder.id && (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <JsonSearchEmpty size='sm' />
              <p className='font-2xl font-medium text-N-700'>
                No Order Selected
              </p>
              <p className='text-N-500 font-normal pt-5'>
                Please select a order from the left panel to see details
              </p>
            </div>
          )}

          {(GetStockOrderDetailsByIdIsLoading ||
            updateStockOrderIsLoading ||
            stockOrderRetryIsLoading ||
            updateStockOrderStatusCancelIsLoading ||
            updateOrderLineByIdIsLoading ||
            deleteStockOrderOrderLinesIsLoading) && (
            <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[2] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
              <JsonLoader size='xs' />
            </div>
          )}

          {selectedOrder.id && (
            <div className='flex flex-col h-full w-full bg-white'>
              {/* Right side panel Header  */}

              <OrderHeader orderDetails={orderDetails} />

              {/* Right side panel Body  */}

              <div className='body_panel w-full h-[calc(100%-77px)] rounded-b overflow-y-auto styled-scroll'>
                {/* order cancel display  */}
                {capitalizeFirstLetter(orderDetails?.status || '-') ===
                  CANCELLED && (
                  <div className='flex w-full h-12 items-center bg-S-100 pl-9 mt-4'>
                    <i className='ri-error-warning-fill mr-[14px] text-xl text-N-500'></i>
                    <span className='text-base text-S-700 font-bold'>
                      Order has been cancelled
                    </span>
                  </div>
                )}

                {/* WhereHouse Error Display  */}
                {orderDetails?.stockOrderErrors?.length > 0 &&
                  capitalizeFirstLetter(orderDetails?.status || '-') ===
                    FAILED && (
                    <div className='flex flex-col w-full px-4'>
                      {orderDetails?.stockOrderErrors.map(
                        (warehouseOrderError: any) => (
                          <PopOut
                            key={warehouseOrderError.id}
                            className='w-full mt-4'
                            appearance='error'
                            size='default'
                            isOrderPopOut={true}
                            isSelfCloseByClick={true}
                            description='Please find the detailed error message in order lines.'>
                            {warehouseOrderError.description}
                          </PopOut>
                        ),
                      )}
                    </div>
                  )}

                {/* Right side panel Body [Shipping Details]  */}
                <div
                  className='flex w-full pt-4 flex-col'
                  key={orderDetails?.id}>
                  <div className='flex w-full items-center justify-between px-4'>
                    <p className='font-bold'>SHIPPING DETAILS</p>
                    <div className='flex items-center'>
                      {isShippingDetailUpdateView && (
                        <Button
                          appearance='outline'
                          size='sm'
                          className='px-2 mr-2'
                          onClick={() => {
                            handleGoBack()
                          }}>
                          <i className='ri-arrow-go-back-line'></i>
                        </Button>
                      )}
                      {capitalizeFirstLetter(orderDetails?.status || '-') !==
                        CANCELLED &&
                        stockOrderEditAccess && (
                          <Button
                            appearance='outline'
                            size='sm'
                            className='px-2'
                            onClick={() => {
                              if (!isShippingDetailUpdateView) {
                                setIsShippingDetailUpdateView(
                                  !isShippingDetailUpdateView,
                                )
                              } else {
                                updateStockOrder()
                              }
                            }}>
                            {isShippingDetailUpdateView ? (
                              <i className='ri-save-line mr-2'></i>
                            ) : (
                              <i className='ri-edit-line mr-2'></i>
                            )}
                            {isShippingDetailUpdateView ? 'Save' : 'Update'}
                          </Button>
                        )}
                    </div>
                  </div>

                  {/* Right side panel Body [Shipping Details - View]  */}
                  {!isShippingDetailUpdateView && (
                    <ShippingDetailViewSection orderDetails={orderDetails} />
                  )}

                  {/* Right side panel Body [Shipping Details - Edit]  */}
                  {isShippingDetailUpdateView && (
                    <ShippingDetailEditSection
                      setEditViewShippingDetails={setEditViewShippingDetails}
                      editViewShippingDetails={editViewShippingDetails}
                    />
                  )}

                  {/* Right side panel Order Line Table  */}
                  <div className='flex w-full flex-col'>
                    <div className='flex flex-col w-full items-start px-4 pt-4 pb-2'>
                      <p className='font-bold mb-5'>ORDER LINES</p>
                      <DataTable
                        itemData={orderDetails?.stockOrderLines}
                        partnerOrderId={orderDetails?.id}
                        setIsEditOrderLineDrawerOpen={
                          setIsEditOrderLineDrawerOpen
                        }
                        isEditOrderLineDrawerOpen={isEditOrderLineDrawerOpen}
                        deleteOrderLinePopOut={deleteOrderLinePopOut}
                        setDeleteOrderLinePopOut={setDeleteOrderLinePopOut}
                        selectedOrderStatus={orderDetails?.status}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {(capitalizeFirstLetter(orderDetails?.status || '-') === FAILED ||
                capitalizeFirstLetter(orderDetails?.status || '-') ===
                  ENTERED) && (
                <OrderFooter
                  isCancelOrderBtn={
                    capitalizeFirstLetter(orderDetails?.status || '-') ===
                    ENTERED
                      ? true
                      : false
                  }
                  isRetryOrderBtn={
                    capitalizeFirstLetter(orderDetails?.status || '-') ===
                    FAILED
                      ? true
                      : false
                  }
                  isLoadingRetryButton={stockOrderRetryIsLoading}
                  isLoadingCancelButton={updateStockOrderStatusCancelIsLoading}
                  onClickRetryButton={() => {
                    pushOrder()
                  }}
                  onClickCancelButton={() => {
                    setShowCancelOrderModal(!showCancelOrderModal)
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {stockOrderAdvancedSearchModalIsOpen && (
        <AdvanceSearchModal
          setStockOrderVariables={setStockOrderVariables}
          stockOrderVariables={stockOrderVariables}
          partnerList={partnerList}
          isActive={true}
          onCloseClick={() => {
            setStockOrderAdvancedSearchModalIsOpen(false)
          }}
          searchKeyword={searchKeyword}
          startDateValue={startDateValue}
          endDateValue={endDateValue}
          setPageNumber={setPageNumber}
        />
      )}
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
        className='toast-container'
      />
      <PopUpAlert
        isActive={showCancelOrderModal}
        title='Cancel Order?'
        message='Are you sure you want to proceed? This is not reversible.'
        cancelButtonText='Cancel'
        confirmButtonText='Yes, I’m Sure'
        onCancelClick={hideCancelOrder}
        onOverlayClick={hideCancelOrder}
        onConfirmClick={confirmCancelOrder}
      />

      {deleteOrderLinePopOut && (
        <PopUpAlert
          isActive={deleteOrderLinePopOut?.enable}
          title='Delete Order Line?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() =>
            setDeleteOrderLinePopOut({
              ...deleteOrderLinePopOut,
              enable: false,
              orderLineId: '',
            })
          }
          onOverlayClick={() =>
            setDeleteOrderLinePopOut({
              ...deleteOrderLinePopOut,
              enable: false,
              orderLineId: '',
            })
          }
          onConfirmClick={() => {
            deleteStockOrderOrderLines(
              selectedOrder.id,
              deleteOrderLinePopOut?.orderLineId,
            )
          }}
        />
      )}

      {isEditOrderLineDrawerOpen?.enable && (
        <UpdateOrderLine
          isActive={isEditOrderLineDrawerOpen?.enable}
          selectedOrderId={orderDetails.id}
          onCloseClick={() =>
            setIsEditOrderLineDrawerOpen({
              ...isEditOrderLineDrawerOpen,
              enable: false,
              orderDetails: {},
            })
          }
          selectedOrderDetails={isEditOrderLineDrawerOpen?.orderDetails}
          notify={notify}
          updateOrderLineById={updateOrderLineById}
        />
      )}
    </div>
  )
}

StockOrder.defaultProps = {}

export default StockOrder
