/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'
import { capitalizeAll } from 'utils/capitalizeAll'

import { DataTable as AddNewShipmentTable } from './AddNewShipmentTable'

export interface AddNewShipmentModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  availableProductDetailTableValues?: any
  setAvailableProductDetailTableValues?: any
  setAddNewShipmentSelectedRows?: any
  addNewShipmentSelectedRows?: any
  useCreateShipment?: any
  notify?: any
  selectedOrderDetails?: any
  callOrderShipmentsById?: any
  setSelectedTabIndex?: any
}

export const AddNewShipmentModal: FC<AddNewShipmentModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  availableProductDetailTableValues,
  setAvailableProductDetailTableValues,
  setAddNewShipmentSelectedRows,
  addNewShipmentSelectedRows,
  useCreateShipment,
  notify,
  selectedOrderDetails,
  callOrderShipmentsById,
  setSelectedTabIndex,
}: AddNewShipmentModalProps) => {
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  /** API Call for Create Shipment */
  const { mutate: createShipmentMutate } = useCreateShipment()

  /** Process the Create Shipment */
  async function createShipment(wereHouseOrderId: string, shipmentLines: any) {
    createShipmentMutate(
      { wereHouseOrderId, shipmentLines },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          if (onCloseClick) {
            onCloseClick()
          }
          callOrderShipmentsById()
          setSelectedTabIndex(selectedOrderDetails?.shipments?.length || 0)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleConfirmClick = (event: any) => {
    const shipmentLines = addNewShipmentSelectedRows.map((row: any) => {
      return {
        sku: row.warehouseSKU,
        qty: row.selectedQty,
        price: row?.totalPrice,
      }
    })
    createShipment(selectedOrderDetails.id, shipmentLines)
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[962px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>
            Add New Shipment - Shipment{' '}
            {selectedOrderDetails?.shipments?.length + 1}
          </h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        <div className='flex w-full pt-6 px-4 max-h-[650px] overflow-y-auto styled-scroll'>
          <div className='flex w-full flex-col'>
            <div className='flex w-full pt-[7px]'>
              <AddNewShipmentTable
                itemData={availableProductDetailTableValues}
                setAvailableProductDetailTableValues={
                  setAvailableProductDetailTableValues
                }
                className={undefined}
                setAddNewShipmentSelectedRows={setAddNewShipmentSelectedRows}
              />
            </div>
          </div>
        </div>
        <div className='w-full border-t border-t-N-200 mt-[116px] px-5 py-4'>
          <Button
            onClick={(e: any) => {
              handleConfirmClick(e)
            }}
            disabled={
              addNewShipmentSelectedRows &&
              addNewShipmentSelectedRows.length === 0
            }
            isLoading={false}>
            Save Shipment
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

AddNewShipmentModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AddNewShipmentModal
