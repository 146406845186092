import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePermissionInRoleInterface {
  Id?: string
  Permission?: string
}

export const useDeletePermissionInRole = () => {
  const mutate = useMutation(
    ({ Id, Permission }: DeletePermissionInRoleInterface) => {
      return deleteAPI(
        `/Identity/Role/${Id}/Permission?permission=${Permission}`,
      )
    },
  )

  return mutate
}

export default useDeletePermissionInRole
