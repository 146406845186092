import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdatePartnerOrderInterface {
  id?: string
  customerFirstName?: string
  customerLastName?: string
  customerEmail?: string
  customerPhoneNo?: string
  customerAddressLine01?: string
  customerAddressLine02?: string
  customerSuburb?: string
  customerState?: string
  customerCountry?: string
  customerCountryCode?: string
  customerPostalCode?: string
  shippingNote?: string
  warehouseNote?: string
  customerStateCode?: string
}

export const useUpdatePartnerOrder = () => {
  const mutate = useMutation(
    ({
      id,
      customerFirstName,
      customerLastName,
      customerEmail,
      customerPhoneNo,
      customerAddressLine01,
      customerAddressLine02,
      customerSuburb,
      customerState,
      customerCountry,
      customerCountryCode,
      customerPostalCode,
      shippingNote,
      warehouseNote,
      customerStateCode,
    }: UpdatePartnerOrderInterface) => {
      return putAPI('/PartnerOrder', {
        id: id,
        customerFirstName: customerFirstName,
        customerLastName: customerLastName,
        customerEmail: customerEmail,
        customerPhone: customerPhoneNo,
        shippingAddress: {
          lineOne: customerAddressLine01,
          lineTwo: customerAddressLine02,
          city: '',
          suburb: customerSuburb,
          state: customerState,
          stateCode: customerStateCode,
          country: customerCountry,
          countryCode: customerCountryCode,
          postCode: customerPostalCode,
        },
        shippingNote: shippingNote,
        warehouseNote: warehouseNote,
      })
    },
  )

  return mutate
}

export default useUpdatePartnerOrder
