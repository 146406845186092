export const timeIntoDateTimeFormat = (timeString: any) => {
  const [hours, minutes, period] = timeString.split(/:| /)
  let hoursNum = parseInt(hours)
  const minutesNum: any = parseInt(minutes)

  if (period === 'PM' && hoursNum !== 12) {
    hoursNum += 12
  } else if (period === 'AM' && hoursNum === 12) {
    hoursNum = 0
  }

  const originalTime = new Date()
  originalTime.setHours(hoursNum)
  originalTime.setMinutes(minutesNum)

  const newTime = new Date(originalTime)

  const year = newTime.getFullYear()
  const month = String(newTime.getMonth() + 1).padStart(2, '0') // Month is 0-based, so add 1 and pad with leading zeros
  const day = String(newTime.getDate()).padStart(2, '0')
  const newHours = String(newTime.getHours()).padStart(2, '0')
  const newMinutes = String(newTime.getMinutes()).padStart(2, '0')

  const formattedDateAndTime = `${year}-${month}-${day}T${newHours}:${newMinutes}`

  return formattedDateAndTime
}
