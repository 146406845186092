/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  setSelectedRows,
  selectedOriginalRowIds,
  isBulkSlip,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table
        data={data}
        setSelectedRows={setSelectedRows}
        loading={loading}
        columns={columns}
        selectedOriginalRowIds={selectedOriginalRowIds}
        isBulkSlip={isBulkSlip}
      />
    </div>
  )
}

export default DataTable
