import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

/* Get all manifest lit */
export const getManifestListByCourierAndWarehouse = async (queryData: any) => {
  const { skip, take, subCourier, warehouseCode, courierCode } =
    queryData.queryKey[1]

  const res = await get(
    `ManifestOrders?Take=${take}&Skip=${skip}&CourierCode=${courierCode}&SubCourier=${
      subCourier === 'All' ? '' : subCourier
    }&WarehouseCode=${warehouseCode === 'All' ? '' : warehouseCode}`,
  )

  return res.data
}

/* Use Query as a Hook */
export const useGetManifestListByCourierAndWarehouse = (
  queryData: any,
  onError: any,
) => {
  return useQuery(
    ['manifestListByCourierAndWarehouse', queryData],
    getManifestListByCourierAndWarehouse,
    {
      retry: 1,
      refetchOnWindowFocus: false,
      onError,
    },
  )
}
