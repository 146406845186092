import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface PartnerOrderStatusInterface {
  partnerOrderId?: string
}

export const useUpdatePartnerOrderStatus = () => {
  const mutate = useMutation(
    ({ partnerOrderId }: PartnerOrderStatusInterface) => {
      return postAPI(`/PartnerOrder/${partnerOrderId}/Cancel`, {})
    },
  )

  return mutate
}

export default useUpdatePartnerOrderStatus
