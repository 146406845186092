/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({ itemData, className, ...restProps }) => {
  const [data, setData] = useState([])
  const columns = useMemo(() => columnsStructure, [])
  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
  }, [itemData])

  return (
    <div className={CN('data-table w-full text-sm', className)} {...restProps}>
      <Table data={data} columns={columns} />
    </div>
  )
}

export default DataTable
