/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getOrderShipmentsById = async (reqData: any) => {
  const data = reqData.queryKey[1]
  const res = await get(`/WarehouseOrder/Get?orderNo=${data.id}`)
  return res.data
}

export const useOrderShipmentsById = (
  { ...reqData },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['userData', reqData], getOrderShipmentsById, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useOrderShipmentsById
