/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState } from 'react'
import CN from 'classnames'
import { useGetStockOnHandList } from 'framework/api/methods'

import { DataTable } from './StockOnHandDataTable'

export interface StockOnHandProps {
  [x: string]: any
}

export const StockOnHand: FC<StockOnHandProps> = ({
  className,
  ...restProps
}: StockOnHandProps) => {
  const [tableVariables, setTableVariables] = useState<any>({
    take: 10,
    skip: 0,
    Search: '',
  })
  const [pageNumber, setPageNumber] = useState(1)
  const [searchKeyword, setSearchKeyword] = useState('')

  /** API Call for get stock on hand list function using react-query */
  const { isLoading, data } = useGetStockOnHandList(tableVariables)

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setTableVariables({
        ...tableVariables,
        Search: searchKeyword,
      })
    }
    setPageNumber(1)
  }

  return (
    <div
      className={CN(
        'flex items-center justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}
      {...restProps}>
      <div className='flex flex-col w-full h-full px-5 bg-white rounded-lg shadow-md'>
        <div className='relative mt-6 w-1/3'>
          <input
            onChange={(e: any) => {
              setSearchKeyword(e.target.value)
            }}
            onKeyPress={(e: any) => {
              handleSearchKeyPress(e)
            }}
            type='text'
            placeholder='Search by Principle, SKU, Warehouse'
            className='w-full py-2 pr-4 text-sm border rounded-md outline-none pl-11 text-N-500 border-N-200'
          />
          <i
            className='ri-search-2-line absolute top-[8px] h-full bottom-[0px] left-[18px] text-1xl text-N-500 cursor-pointer'
            onClick={() => {
              setTableVariables({
                ...tableVariables,
                Search: searchKeyword,
              })
            }}></i>
        </div>

        <div className='w-full h-[calc(100%-128px)] pt-4'>
          <DataTable
            itemData={data?.stockOnHands || []}
            setTableVariables={setTableVariables}
            tableVariables={tableVariables}
            totalRowCount={data?.totalCount}
            isLoading={isLoading}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </div>
  )
}

StockOnHand.defaultProps = {}

export default StockOnHand
