import React, { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import CN from 'classnames'
import {
  useCreateNewUserRole,
  useCreatePermissionInRole,
  useDeletePermissionInRole,
  useGetUserPermissionByRoleId,
} from 'framework/api/methods'

import {
  Button,
  Drawer,
  Input,
  JsonLoader,
  ToggleSwitch,
} from 'components/atoms'

export interface AddUserRolesProps {
  [x: string]: any
}

export const AddUserRoles: FC<AddUserRolesProps> = ({
  className,
  isActive,
  userRoleDetails,
  isAddNew,
  setRefetch,
  refetch,
  onCloseClick,
  notify,
  privilegesList,
  ...restProps
}: AddUserRolesProps) => {
  const [roleName, setRoleName] = useState('')
  const [isErrorField, setIsErrorField] = useState<any>({
    roleName: false,
  })
  const [userPrivilegesList, setUserPrivilegesList] =
    useState<any>(privilegesList)
  const [selectedUserPermissions, setSelectedUserPermissions] = useState<any>(
    [],
  )

  const AddNewUserRoleClasses = CN(
    ' AddNewUserRole flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto styled-scroll',
    className,
    {},
  )

  /** set selected user role ,toggle button true and false states of privileges */
  useEffect(() => {
    const toBeUpdated = userPrivilegesList.map((item: any) => {
      return {
        ...item,
        isActive: selectedUserPermissions?.includes(item.name),
      }
    })
    setUserPrivilegesList(toBeUpdated)
  }, [selectedUserPermissions])

  /** use effect for call get user permission by role id */
  useEffect(() => {
    if (!isAddNew) {
      setRoleName(userRoleDetails.name)
      getUserPermissionByRoleId(userRoleDetails.id)
    } else {
      setRoleName('')
    }
  }, [userRoleDetails, isAddNew])

  /** API Call for get user roles using react-query */
  const {
    mutate: getUserPermissionByRoleIdMutate,
    isLoading: getUserPermissionByRoleIdIsLoading,
  } = useGetUserPermissionByRoleId()

  /** Process to get selected roles's  privileges */
  const getUserPermissionByRoleId = (id: any) => {
    getUserPermissionByRoleIdMutate(
      { Id: id },
      {
        onSuccess: ({ data: successData }: any) => {
          setSelectedUserPermissions(successData?.permissions)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** create User role API */
  const {
    mutate: createUserRoleMutate,
    isLoading: createUserRoleMutateIsLoading,
  } = useCreateNewUserRole()

  /** Process to get selected roles's  privileges */
  const addNewUserRole = (name: any) => {
    createUserRoleMutate(
      { name },
      {
        onSuccess: ({ data: successData }: any) => {
          notify('New user role added successfully.', 'success')
          onCloseClick()
          setRefetch(!refetch)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          onCloseClick()
        },
      },
    )
  }

  /** Process the add new role */
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const valid = validateInputs()
    if (isAddNew) {
      if (valid) {
        addNewUserRole(roleName)
      }
    }
  }

  /** validate input fields */
  const validateInputs = () => {
    setIsErrorField({
      ...isErrorField,
      roleName: roleName ? false : true,
    })

    const isAllValidFields = roleName
    return isAllValidFields
  }

  /** api for activate user role privilege */
  const {
    mutate: userRolePermissionActivateMutate,
    isLoading: userRolePermissionActivateIsLoading,
  } = useCreatePermissionInRole()

  /** Process for activate user role privilege */
  async function activateUserRolePermission(Id: string, Permission: string) {
    userRolePermissionActivateMutate(
      { Id, Permission },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** api for activate user role privilege */
  const {
    mutate: userRolePermissionDeactivateMutate,
    isLoading: userRolePermissionDeactivateIsLoading,
  } = useDeletePermissionInRole()

  /** Process for activate user role privilege */
  async function deactivateUserRolePermission(Id: string, Permission: string) {
    userRolePermissionDeactivateMutate(
      { Id, Permission },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }
  return (
    <Drawer
      isActive={isActive}
      width={360}
      {...restProps}
      title={!isAddNew ? 'Edit User Role' : 'Add New Role'}
      onCloseClick={onCloseClick}>
      <div className={AddNewUserRoleClasses}>
        {getUserPermissionByRoleIdIsLoading && (
          <div className='flex w-full h-full items-center justify-center bg-N-800 absolute z-[1] left-0 top-0 bg-opacity-20 cursor-not-allowed'>
            <JsonLoader size='xs' />
          </div>
        )}
        <Input
          labelValue='Role Name'
          isRequired={isAddNew ? true : false}
          type='text'
          className='pb-5'
          value={roleName}
          onChange={(e: any) => setRoleName(e.target.value)}
          isDisabled={!isAddNew ? true : false}
          isError={isErrorField.roleName}
        />

        {!isAddNew && (
          <div className='flex flex-col w-full'>
            <span className='pb-4 text-black font-bold'>Privileges</span>

            {userPrivilegesList?.map((item: any, key: any) => (
              <ToggleSwitch
                key={key}
                setValue={item.isActive}
                text={item.name}
                className='pb-4'
                onToggleClick={(e: any) => {
                  // setToggleAction(!toggleAction)

                  // eslint-disable-next-line prefer-const
                  let toggleActions: any = [...userPrivilegesList]

                  toggleActions.map((toggleAction: any) => {
                    toggleAction.name === item.name
                      ? (toggleAction.isActive = e.target.checked)
                      : toggleAction
                  })
                  setUserPrivilegesList(toggleActions)

                  const id = userRoleDetails.id
                  e.target.checked === true
                    ? activateUserRolePermission(id, item.name)
                    : deactivateUserRolePermission(id, item.name)
                }}
                isDisabled={isAddNew ? true : false}
              />
            ))}
          </div>
        )}
      </div>
      {isAddNew && (
        <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
          <Button
            isLoading={false}
            onClick={(e: any) => {
              handleSubmit(e)
            }}>
            {!isAddNew ? 'Update Role' : 'Save Role'}
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      )}
    </Drawer>
  )
}

AddUserRoles.defaultProps = {}

export default AddUserRoles
