import { useMutation } from 'react-query'
import { putAPI } from 'framework/api/http'

interface UpdateStockOrderLineByIdInterface {
  orderId?: string
  lineId?: string
  sku?: string
  uom?: string
}

export const useUpdateStockOrderLineById = () => {
  const mutate = useMutation(
    ({ orderId, lineId, sku, uom }: UpdateStockOrderLineByIdInterface) => {
      return putAPI(`/StockOrder/${orderId}/StockOrderLine/${lineId}`, {
        sku,
        uom,
      })
    },
  )

  return mutate
}

export default useUpdateStockOrderLineById
