/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import CN from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import * as XLSX from 'xlsx'

import Button from '../../../../atoms/Button/Button'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  className,
  // setSelectedRows,
  // selectedOriginalRowIds,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData?.length > 0) {
      setData([...itemData])
    }
  }, [itemData])

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })

    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    const url = window.URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'data.xlsx')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className='pb-2 pr-0 text-right'>
        <Button onClick={exportToExcel}>Export to Excel</Button>
      </div>
      <div
        className={CN('data-table w-full text-sm', className)}
        {...restProps}>
        <Table
          data={data}
          // setSelectedRows={setSelectedRows}
          loading={loading}
          columns={columns}
        />
      </div>
    </>
  )
}

export default DataTable
