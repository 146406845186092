/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  shippingSummeryWarehouseCode: null,
}

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetToInitialState(state, action) {
      ;(state.isLoading = false), (state.shippingSummeryWarehouseCode = null)
    },

    setIsLoadingState(state, { payload }) {
      state.isLoading = payload
    },

    setShippingSummeryWarehouseCodesState(state, { payload }) {
      state.shippingSummeryWarehouseCode = payload
    },
  },
})

export const {
  resetToInitialState,
  setIsLoadingState,
  setShippingSummeryWarehouseCodesState,
} = orderSlice.actions

export const setToInitial =
  (values, cb = () => {}) =>
  (dispatch) => {
    dispatch(resetToInitialState(values))
    return cb(values)
  }

export const setIsLoading =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoadingState(payload))
  }

export const setShippingSummeryWarehouseCodes =
  (value, cb = () => {}) =>
  (dispatch) => {
    dispatch(setShippingSummeryWarehouseCodesState(value))
  }

export default orderSlice.reducer
