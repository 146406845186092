/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForgotPassword, useLogin } from 'framework/api/methods'
import { setToInitial, setUserDetails } from 'store/reducers/user/userSlice'

import { ConfirmEmail } from './ConfirmEmail'
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { LoginForm } from './LoginForm'
import { ResetPassword } from './ResetPassword'

export interface LoginProps {
  [x: string]: any
}

export const Login: FC<LoginProps> = ({ ...restProps }: LoginProps) => {
  const navigate = useNavigate()
  const urlLocation = useLocation()
  const [userData, setUserData] = useState<any>({ username: '', password: '' })
  const [loginError, setLoginError] = useState<any>()
  const [forgotPasswordError, setForgotPasswordError] = useState<any>()
  const [forgotPasswordData, setForgotPasswordData] = useState<any>()
  const [logInComponent, setLogInComponent] = useState<any>('logIn')
  const isResetPassword = urlLocation.pathname.includes('reset-password')
  const isConfirmEmail = urlLocation.pathname.includes('confirm/email')
  const dispatch = useDispatch()

  /** API Call for Login function using react-query */
  const { mutate: loginMutate, isLoading: loginIsLoading } = useLogin()
  const { mutate: forgotPasswordMutate, isLoading: forgotPasswordIsLoading } =
    useForgotPassword()

  /** Get Local Token */
  const token = sessionStorage.getItem('token')

  /** Redirect user to Homepage if Token exists */
  useEffect(() => {
    if (token) {
      navigate('/', { replace: true })
    }
  })

  /** set to the default value Forgot-password errors & Messages */
  const setDefault = () => {
    setForgotPasswordError({
      ...forgotPasswordError,
      isError: false,
      message: '',
    })
    setForgotPasswordData({
      ...forgotPasswordData,
      isSuccess: false,
      data: {},
    })
  }

  /** Process the Login submission */
  async function handleLogInSubmit(event: any) {
    event.preventDefault()
    setLoginError({
      ...loginError,
      isError: false,
      message: '',
    })
    loginMutate(userData, {
      onSuccess: ({ data: authenticatedData }: any) => {
        authenticatedData.token &&
          sessionStorage.setItem('token', authenticatedData.token)
        dispatch(setUserDetails(authenticatedData.userProfile))
        navigate('/', { replace: true })
      },
      onError: ({ response: { data: errData } }: any) => {
        setLoginError({
          ...loginError,
          isError: true,
          message: errData.message,
        })
        dispatch(setToInitial())
      },
    })
  }

  /** Process the ForgotPassword submission */
  async function handleForgotPasswordSubmit(event: any) {
    event.preventDefault()
    setDefault()
    forgotPasswordMutate(userData, {
      onSuccess: ({ data: successData }: any) => {
        setForgotPasswordData({
          ...forgotPasswordData,
          isSuccess: true,
          data: successData,
        })
      },
      onError: ({ response: { data: errData } }: any) => {
        setForgotPasswordError({
          ...forgotPasswordError,
          isError: true,
          message: errData.message,
        })
      },
    })
  }

  return (
    <div className='w-full flex justify-center' {...restProps}>
      {!isResetPassword && !isConfirmEmail && logInComponent === 'logIn' && (
        <LoginForm
          handleLogInSubmit={handleLogInSubmit}
          userData={userData}
          setUserData={setUserData}
          loginError={loginError}
          loginIsLoading={loginIsLoading}
          setLogInComponent={setLogInComponent}
        />
      )}
      {!isResetPassword &&
        !isConfirmEmail &&
        logInComponent === 'ForgotPassword' && (
          <ForgotPasswordForm
            handleForgotPasswordSubmit={handleForgotPasswordSubmit}
            userData={userData}
            setUserData={setUserData}
            forgotPasswordError={forgotPasswordError}
            forgotPasswordIsLoading={forgotPasswordIsLoading}
            setLogInComponent={setLogInComponent}
            forgotPasswordData={forgotPasswordData}
            setForgotPasswordData={setForgotPasswordData}
            setForgotPasswordError={setForgotPasswordError}
          />
        )}
      {isResetPassword && !isConfirmEmail && (
        <ResetPassword
          userData={userData}
          setUserData={setUserData}
          setLogInComponent={setLogInComponent}
        />
      )}
      {isConfirmEmail && <ConfirmEmail />}
    </div>
  )
}

Login.defaultProps = {}

export default Login
