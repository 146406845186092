import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface manifestByTrackingNumbersInterface {
  trackingNumbers?: any
  subCourier?: string
  warehouseCode?: string
  courierCode?: string
}

export const useManifestByTrackingNumbers = () => {
  const mutate = useMutation(
    ({
      trackingNumbers,
      subCourier,
      warehouseCode,
      courierCode,
    }: manifestByTrackingNumbersInterface) => {
      return postAPI('/Manifests/ManifestOrders', {
        trackingNumbers,
        subCourier,
        warehouseCode,
        courierCode,
      })
    },
  )

  return mutate
}

export default useManifestByTrackingNumbers
