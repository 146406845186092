import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface ResetPasswordDataInterface {
  username?: string | number
  resetToken?: string | number
  password?: string | number
}

export const useResetPassword = () => {
  const mutate = useMutation(
    ({ username, resetToken, password }: ResetPasswordDataInterface) => {
      return postAPI('/identity/ResetPassword', {
        username,
        resetToken,
        password,
      })
    },
  )

  return mutate
}

export default useResetPassword
