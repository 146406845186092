import { FC, useEffect, useState } from 'react'
import { useGetWarehouseTable } from 'framework/api/methods'

import { SimpleSelect } from 'components/atoms'
import { DatePicker } from 'components/atoms/DatePicker'
import { Input } from 'components/atoms/Input'
import { TextArea } from 'components/atoms/TextArea'

export interface ShippingDetailEditSectionProps {
  [x: string]: any
}

/** This is ShippingDetailViewSection */

export const ShippingDetailEditSection: FC<ShippingDetailEditSectionProps> = ({
  editViewShippingDetails,
  setEditViewShippingDetails,
}: ShippingDetailEditSectionProps) => {
  const { mutate: getWarehouseListMutate } = useGetWarehouseTable()
  const [wareHouseList, setWareHouseList] = useState([])

  /** Process to get Warehouse List for integration */
  async function getWarehouseList(): Promise<void> {
    getWarehouseListMutate(
      { take: 100, skip: 0, name: '', isActive: true },
      {
        onSuccess: ({ data: successData }: any) => {
          const updatedWarehouseList = successData.warehouses.map(
            (item: any) => {
              return {
                ...item,
                value: item?.code,
                label: item?.code,
              }
            },
          )
          setWareHouseList(updatedWarehouseList)
        },
        onError: ({ response: { data: errData } }: any) => {
          //
        },
      },
    )
  }
  useEffect(() => {
    getWarehouseList()
  }, [])

  return (
    <div className='flex w-full flex-col px-4 pt-[10px] mb-7'>
      <div className='flex w-full items-center'>
        <div className='flex flex-col pr-2 w-1/2'>
          <span className='pb-2 text-N-700'>Warehouse</span>

          <SimpleSelect
            value={{
              value: editViewShippingDetails.warehouseCode,
              label: editViewShippingDetails.warehouseCode,
            }}
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                warehouseCode: e.value,
              })
            }}
            options={wareHouseList}
          />
        </div>

        <Input
          className='w-1/2 pl-2'
          labelValue='Customer Reference'
          type='text'
          value={editViewShippingDetails.customerReferance}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              customerReferance: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <Input
          className='w-1/2 pr-2'
          labelValue='Supplier Invoice Number'
          type='text'
          value={editViewShippingDetails.invoiceNo}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              invoiceNo: e.target.value,
            })
          }}
        />

        <Input
          className='w-1/2 pl-2'
          labelValue='Job Reference'
          type='text'
          value={editViewShippingDetails.jobReference}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              jobReference: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <div className='flex flex-col w-full pr-2'>
          <span className='pb-2 text-N-700'>ETA</span>

          <DatePicker
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                eta: e.target.value,
              })
            }}
            value={editViewShippingDetails.eta}
            allowEdit={false}
            className='pl-2'
            minDate={new Date()}
          />
        </div>

        <div className='flex flex-col w-full pl-2'>
          <span className='pb-2 text-N-700'>Receipt Date</span>

          <DatePicker
            onChange={(e: any) => {
              setEditViewShippingDetails({
                ...editViewShippingDetails,
                receiptDate: e.target.value,
              })
            }}
            value={editViewShippingDetails.receiptDate}
            allowEdit={false}
            className='pl-2'
            minDate={new Date()}
          />
        </div>
      </div>

      <div className='flex w-full items-center pt-4'>
        <TextArea
          labelValue='Order Notes'
          rows={3}
          value={editViewShippingDetails.stockNote}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              stockNote: e.target.value,
            })
          }}
        />
      </div>

      <div className='flex w-full items-center pt-4'>
        <TextArea
          labelValue='Instructions'
          rows={3}
          value={editViewShippingDetails.instructions}
          onChange={(e: any) => {
            setEditViewShippingDetails({
              ...editViewShippingDetails,
              instructions: e.target.value,
            })
          }}
        />
      </div>
    </div>
  )
}

ShippingDetailEditSection.defaultProps = {}

export default ShippingDetailEditSection
