import { FC, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  FaDownload,
  FaFile,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaPlusCircle,
  FaTrash,
} from 'react-icons/fa'
import { colors } from 'react-select/dist/declarations/src/theme'
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars'
import CN from 'classnames'
import { useCreateInvoice } from 'framework/api/methods'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'

import { Input, PopUpAlert, SimpleSelect, ToggleSwitch } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'

import './billing.css'

export interface AddInvoiceProps {
  className?: string | undefined
  [x: string]: any
}
export const AddInvoice: FC<AddInvoiceProps> = ({
  className,
  isActive,
  onCloseClick,
  partnerList,
  notify,
  getInvoiceList,
}: AddInvoiceProps) => {
  const InvoiceClasses = CN(
    'AddInvoice flex flex-col py-4 px-5 relative h-full overflow-y-auto overscroll-y-auto styled-scroll gap-y-5',
    className,
    {},
  )
  const [InvoiceDetails, setInvoiceDetails] = useState<any>({
    partnerCode: '',
    invoiceId: '',
    title: '',
    type: '',
    year: '',
    month: '',
    dateRaised: '',
    dueDate: '',
    amount: 0,
    invoiceFiles: '',
    isPaid: '',
    status: '',
  })
  const [isErrorField, setIsErrorField] = useState<any>({
    partnerCode: false,
    invoiceId: false,
    title: false,
    type: false,
    year: false,
    month: false,
    dateRaised: false,
    dueDate: false,
    amount: false,
    isPaid: false,
    status: false,
  })
  const [filterVariables, setFilterVariables] = useState<any>({
    partner: { label: 'All', value: '', id: '' },
    month: { name: 'All', value: '' },
    year: { name: 'All', value: '' },
    dateRaisedFrom: '',
    dateRaisedTo: '',
    dueDateFrom: '',
    dueDateTo: '',
    invoiceStatus: { label: 'All', value: '' },
  })
  const [tableVariables, setTableVariables] = useState({
    take: 10,
    skip: 0,
    search: '',
  })
  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [previews, setPreviews] = useState<string[]>([])
  const [deleteFilePopOutIsOpen, setDeleteFilePopOutIsOpen] = useState(false)
  const [deleteFile, SetDelFile] = useState<any>()
  const fileInputRef = useRef<HTMLInputElement>(null as any)
  // Function to select the right icon based on the file's MIME type
  const getFileIcon = (mimeType: string) => {
    if (mimeType.startsWith('image')) {
      return <FaFileImage style={{ color: 'orange' }} />
    } else if (mimeType === 'application/pdf') {
      return <FaFilePdf style={{ color: 'red' }} />
    } else if (mimeType.includes('word')) {
      return <FaFileWord style={{ color: 'blue' }} />
    } else if (mimeType.includes('spreadsheetml')) {
      return <FaFileExcel style={{ color: 'green' }} />
    } else if (mimeType.includes('csv')) {
      return <FaFileCsv style={{ color: '#90ee90' }} />
    } else if (mimeType.includes('xlsx')) {
      return <FaFileExcel style={{ color: 'green' }} />
    } else {
      return <FaFile style={{ color: 'blue' }} />
    }
  }
  const handleFiles = (files: FileList | null) => {
    if (files) {
      const newFilesArray = Array.from(files)
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFilesArray])
      const newPreviews = newFilesArray.map(
        (file) =>
          file.type.startsWith('image/')
            ? URL.createObjectURL(file)
            : 'placeholder', // Use 'placeholder' for non-image files
      )
      setPreviews((prevPreviews) => [...prevPreviews, ...newPreviews])
      // Reset the file input after the state is updated
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }
  const handleDownloadFile = (file: any) => {
    // Create a URL for the file
    const url = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    a.href = url
    a.download = file.name || 'download'
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }
  const handleDeleteFile = (index: number) => {
    setDeleteFilePopOutIsOpen(true)
    handleRemoveFile(index)
  }
  const handleRemoveFile = (index: number) => {
    setDeleteFilePopOutIsOpen(false)
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    setPreviews((prevPreviews) => {
      // Revoke the object URL to avoid memory leaks
      URL.revokeObjectURL(prevPreviews[index])
      return prevPreviews.filter((_, i) => i !== index)
    })
    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }
  function getMonthName(monthNumber: any) {
    const monthIndex = parseInt(monthNumber, 10) - 1 // Convert string to number and adjust for zero-based index
    const date = new Date(0, monthIndex)
    return date.toLocaleString('default', { month: 'long' })
  }
  function formatDateToISO(dateString: string) {
    if (!dateString) return '' // Return empty string if date is undefined or null
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0') // Month is zero-indexed, add 1
    const day = date.getDate().toString().padStart(2, '0')
    return `${year}-${month}-${day}`
  }
  const [selectedYear, setYear] = useState(new Date().getFullYear()) // Default to current year
  const [selectedMonth, setMonth] = useState(new Date().getMonth() + 1) // Default to current month
  const [selectedDueDate, setDueDate] = useState(new Date())
  const [selectedDateRaised, setDateRaised] = useState(new Date())
  const statusOptions = [
    { value: 'Due', label: 'Due' },
    { value: 'Overdue', label: 'Overdue' },
    { value: 'Paid', label: 'Paid' },
  ]
  const currentDate = new Date()
  const defaultStatus =
    selectedDueDate < currentDate
      ? statusOptions.find((option) => option.value === 'Overdue')
      : statusOptions.find((option) => option.value === 'Overdue')
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] =
    useState(defaultStatus)
  const years = Array.from(
    new Array(30),
    (val, index) => new Date().getFullYear() - index,
  )
  const months = moment.months().map((name, index) => ({
    value: index + 1, // moment.js month index starts at 0
    name: name,
  }))
  useEffect(() => {
    if (selectedInvoiceStatus?.value != 'Paid') {
      const newStatus =
        new Date(selectedDueDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0)
          ? statusOptions.find((option) => option.value === 'Overdue')
          : statusOptions.find((option) => option.value === 'Due')
      setInvoiceDetails((prevDetails: any) => ({
        ...prevDetails,
        status: newStatus,
      }))
      setSelectedInvoiceStatus(newStatus) // Keep local state in sync
    }
  }, [selectedDueDate])
  /** API Call for create cutoff time using react-query */
  const { mutate: createInvoiceMutate, isLoading: createInvoiceIsLoading } =
    useCreateInvoice()
  /** Process the create cutoff time */
  async function addInvoice() {
    createInvoiceMutate(
      {
        partnerCode: InvoiceDetails?.partnerCode,
        invoiceId: InvoiceDetails?.invoiceId,
        title: InvoiceDetails?.title,
        type: InvoiceDetails?.type,
        year: selectedYear.toString(),
        month: getMonthName(selectedMonth),
        dateRaised: formatDateToISO(selectedDateRaised?.toString()),
        dueDate: formatDateToISO(selectedDueDate?.toString()),
        amount: parseFloat(InvoiceDetails?.amount),
        isPaid: selectedInvoiceStatus?.value == 'Paid',
        status: InvoiceDetails.status,
        invoiceFiles: selectedFiles,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
          onCloseClick()
          getInvoiceList(
            tableVariables.take,
            tableVariables.skip,
            tableVariables.search,
            filterVariables.partner.value != ''
              ? filterVariables.partner.value
              : '',
            filterVariables.year.value != '' ? filterVariables.year.value : '',
            filterVariables.month.value != ''
              ? getMonthName(filterVariables.month)
              : '',
            filterVariables.invoiceStatus.value != ''
              ? filterVariables.invoiceStatus.value
              : '',
            filterVariables.dueDateFrom,
            filterVariables.dueDateTo,
            filterVariables.dateRaisedFrom,
            filterVariables.dateRaisedTo,
          )
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // Allow only numeric input, optionally including a decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setInvoiceDetails({
        ...InvoiceDetails,
        amount: value,
      })
    }
  }
  const handleInvoiceStatusChange = (newValue: any) => {
    const newStatus = statusOptions.find(
      (option) => option.value === newValue.value,
    )
    setSelectedInvoiceStatus(newStatus)
  }
  const handleDueDateChange = (e: any) => {
    const newDueDate = new Date(e.value) // Assuming e.value is the correct date format
    setInvoiceDetails((prevDetails: any) => ({
      ...prevDetails,
      dueDate: newDueDate,
    }))
    setDueDate(newDueDate) // Update the local state for the due date
  }
  const validateInputs = () => {
    const {
      partnerCode,
      invoiceId,
      title,
      year,
      month,
      dateRaised,
      dueDate,
      amount,
    } = InvoiceDetails
    setIsErrorField({
      ...isErrorField,
      partnerCode: partnerCode ? false : true,
      invoiceId: invoiceId ? false : true,
      year: year ? false : true,
      month: month ? false : true,
      dateRaised: dateRaised ? false : true,
      duedate: dueDate ? false : true,
      amount: amount ? false : true,
    })
    const isAllValidFields =
      partnerCode &&
      invoiceId &&
      year &&
      month &&
      dateRaised &&
      dueDate &&
      amount
    return isAllValidFields
  }
  const handleAddClick = () => {
    const valid = validateInputs()
    addInvoice()
  }
  return (
    <Drawer
      isActive={isActive}
      onCloseClick={onCloseClick}
      width={700}
      margin-left={10}
      title='Upload Invoice'>
      <div className={InvoiceClasses}>
        <div
          className='add-invoice-container'
          style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className='add-invoice-form' style={{ width: '300px' }}>
            <div className='AddInvoice flex flex-col py-4 px-5 relative gap-y-5'>
              <div className='flex flex-col w-full mt-1'>
                <span className='pb-2 text-N-700'>
                  Partner Name
                  <span className='text-R-500 ml-[2px]'>*</span>
                </span>
                <SimpleSelect
                  placeholder='Select Partner'
                  value={
                    InvoiceDetails?.partnerName
                      ? {
                          label: InvoiceDetails?.partnerName,
                          value: InvoiceDetails?.partnerCode,
                        }
                      : null
                  }
                  onChange={(e: any) => {
                    setInvoiceDetails({
                      ...InvoiceDetails,
                      partnerName: e.label,
                      partnerCode: e.value,
                    })
                  }}
                  options={partnerList}
                  isError={isErrorField?.partnerCode}
                />
              </div>
              <Input
                labelValue='Invoice Id'
                isRequired
                value={InvoiceDetails.invoiceId}
                onChange={(e: any) => {
                  setInvoiceDetails({
                    ...InvoiceDetails,
                    invoiceId: e.target.value,
                  })
                }}
                isError={isErrorField.invoiceId}
              />
              <Input
                labelValue='Title'
                value={InvoiceDetails.title}
                onChange={(e: any) => {
                  setInvoiceDetails({
                    ...InvoiceDetails,
                    title: e.target.value,
                  })
                }}
                isError={isErrorField.title}
              />
              <Input
                labelValue='Type'
                value={InvoiceDetails.type}
                onChange={(e: any) => {
                  setInvoiceDetails({
                    ...InvoiceDetails,
                    type: e.target.value,
                  })
                }}
                isError={isErrorField.type}
              />
              <div className='date-select-container'>
                <div className='flex flex-col w-1/2 z-[2]'>
                  <span className='pb-2 text-N-700'>
                    Year
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <select
                    style={{ fontSize: 15, color: 'grayText' }}
                    placeholder='Year'
                    value={selectedYear}
                    onChange={(e) => setYear(parseInt(e.target.value, 10))}
                    className='year-selector'>
                    {years.map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='flex flex-col w-1/2 z-[2]'>
                  {/* Month Selector */}
                  <span className='pb-2 text-N-700 ml-2'>
                    Month
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <select
                    style={{ fontSize: 15, color: 'grayText' }}
                    placeholder='Month'
                    value={selectedMonth}
                    onChange={(e) => setMonth(parseInt(e.target.value, 10))}
                    className='month-selector'>
                    {months.map((m) => (
                      <option key={m.value} value={m.value}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <div className='flex flex-col z-[2]'>
                  <span className='pb-2 text-N-700'>Date Raised</span>
                  <div className='date-picker mt-0'>
                    <DatePickerComponent
                      enabled={true}
                      onChange={(e: any) => setDateRaised(e.target.value)}
                      value={selectedDateRaised}
                      allowEdit={false}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className='flex flex-col z-[2]'>
                  <span className='pb-2 text-N-700'>Due Date</span>
                  <div className='date-picker mt-0'>
                    <DatePickerComponent
                      enabled={true}
                      onChange={handleDueDateChange}
                      value={selectedDueDate}
                      allowEdit={false}
                    />
                  </div>
                </div>
              </div>
              <Input
                labelValue='Amount'
                isRequired
                value={InvoiceDetails.amount}
                onChange={handleAmountChange}
                isError={isErrorField.amount}
              />
              <div className='InvoiceDetails'>
                <div className='flex flex-col w-full mt-1'>
                  <span className='pb-2 text-N-700'>
                    Invoice Status
                    <span className='text-R-500 ml-[2px]'>*</span>
                  </span>
                  <SimpleSelect
                    value={selectedInvoiceStatus}
                    onChange={handleInvoiceStatusChange}
                    required
                    options={statusOptions}></SimpleSelect>
                </div>
              </div>
              <div className='InvoiceDetails'>
                <div className='flex flex-col w-full'>
                  <span className='pb-2  text-N-700'>Status</span>
                  <ToggleSwitch
                    text={InvoiceDetails?.status ? 'Active' : 'Inactive'}
                    setValue={InvoiceDetails?.status}
                    onToggleClick={(e: any) => {
                      setInvoiceDetails({
                        ...InvoiceDetails,
                        status: e.target.checked,
                      })
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='add-invoice-upload' style={{ width: '300px' }}>
            <div className='AddInvoice flex flex-col py-4 px-5 relative gap-y-5'>
              <label htmlFor='file-upload' className='block text-N-700 mb-0'>
                Upload Invoice
              </label>
              <div
                className='border-2 border-dashed border-N-200 rounded p-8 text-center relative'
                hidden={selectedFiles.length > 0}>
                <input
                  id='file-upload'
                  type='file'
                  multiple
                  onChange={(e) => handleFiles(e.target.files)}
                  className='absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer'
                  ref={fileInputRef}
                />
                <div className='text-N-600'>
                  <span style={{ color: 'orange' }}>Choose file</span> or drop
                  here
                </div>
              </div>
              <ul className='mt-2'>
                {selectedFiles.map((file, index) => (
                  <li
                    key={index}
                    className='flex items-center border p-2 rounded mb-2 justify-between'>
                    <div className='flex items-center'>
                      {previews[index] !== 'placeholder' ? (
                        <img
                          src={previews[index]}
                          alt={`Preview ${file.name}`}
                          className='w-10 h-10 mr-2'
                        />
                      ) : (
                        <i className='file-icon mr-2'>
                          {getFileIcon(file.type)}
                        </i> // Placeholder icon for non-image files
                      )}
                      <span
                        style={{
                          color: 'gray',
                          fontWeight: 'bold',
                          fontSize: '13px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '150px', // Adjust the maxWidth to fit your layout
                        }}
                        title={file.name} // Shows the full name on hover
                      >
                        {file.name}
                      </span>
                    </div>
                    <div className='flex items-center'>
                      <FaDownload
                        style={{
                          color: 'green',
                          fontSize: '1.00rem', // Size can be adjusted if needed
                          cursor: 'pointer',
                          marginLeft: '0.5rem',
                        }}
                        onClick={() => handleDownloadFile(file)}
                      />
                      <FaTrash
                        style={{
                          color: 'maroon',
                          fontSize: '1.00rem', // Size can be adjusted if needed
                          cursor: 'pointer',
                          marginLeft: '0.5rem',
                        }}
                        onClick={() => handleRemoveFile(index)}
                      />
                    </div>
                  </li>
                ))}
              </ul>
              {previews.length > 0 && (
                <div
                  style={{
                    width: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'orange',
                    borderRadius: '20px',
                    padding: '10px 20px',
                    gap: '10px',
                    marginLeft: '30px',
                  }}>
                  <button
                    type='button'
                    style={{
                      width: '150px',
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px', // this ensures that there is some space between the icon and the text
                    }}
                    onClick={() => fileInputRef.current?.click()}>
                    <FaPlusCircle
                      style={{ color: 'white', fontSize: '1.5rem' }}
                    />
                    <span style={{ color: 'white', fontWeight: 'bold' }}>
                      Upload more
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm gap-x-5'>
        <Button
          isLoading={createInvoiceIsLoading}
          disabled={createInvoiceIsLoading}
          isFullWidth
          onClick={() => {
            handleAddClick()
          }}>
          Save
        </Button>
        <Button isFullWidth appearance='dull' onClick={onCloseClick}>
          Close
        </Button>
      </div>
      {deleteFilePopOutIsOpen && (
        <PopUpAlert
          isActive={deleteFilePopOutIsOpen}
          title='Delete Document?'
          message='Are you sure you want to proceed? This is not reversible.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, I’m Sure'
          onCancelClick={() => setDeleteFilePopOutIsOpen(false)}
          onOverlayClick={() => setDeleteFilePopOutIsOpen(false)}
          onConfirmClick={() => {
            handleDeleteFile(deleteFile)
          }}
        />
      )}
      ;
    </Drawer>
  )
}
AddInvoice.defaultProps = {
  className: undefined,
}
export default AddInvoice
