import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteUserRolesInterface {
  userRoleId?: string
}

export const useDeleteUserRoles = () => {
  const mutate = useMutation(({ userRoleId }: DeleteUserRolesInterface) => {
    return deleteAPI(`/Identity/Role/${userRoleId}`)
  })

  return mutate
}

export default useDeleteUserRoles
