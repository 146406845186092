/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from 'react'
import IconPushAPI from 'assets/images/icon-pushapi.svg'
import IconShopify from 'assets/images/icon-shopify.svg'
import Iconwoocommerce from 'assets/images/icon-woocommerce.svg'
import CN from 'classnames'

import { CheckBox } from 'components/atoms/Checkbox'

export interface IntegrationPanelViewProps {
  IntegrationData?: any
  [x: string]: any
}

export const IntegrationPanelView: FC<IntegrationPanelViewProps> = ({
  IntegrationData,
}: IntegrationPanelViewProps) => {
  const [integrationPanelData] = useState(IntegrationData)
  const [showDetails, setShowDetails] = useState(false)

  const ViewSection = (
    heading: string,
    value: string,
    viewAlignment: string,
  ) => {
    return (
      <div
        className={CN('flex flex-col', {
          'w-1/2 pr-2': viewAlignment === 'left',
          'w-1/2 pl-2': viewAlignment === 'right',
          'w-full': viewAlignment === 'full',
        })}>
        <span className='text-N-500 text-sm'>{heading}</span>
        <span className='text-N-700 font-semibold pt-[2px]'>{value}</span>
      </div>
    )
  }

  return (
    <>
      <div
        className={CN(
          'flex w-full bg-N-50 mt-4 items-center justify-between p-4 rounded-t-lg',
          {
            'rounded-b-lg': !showDetails,
          },
        )}>
        <div className='flex items-center'>
          {integrationPanelData.name === 'Shopify' && (
            <img src={IconShopify} alt='IconShopify' />
          )}
          {integrationPanelData.name === 'Woocommerce' && (
            <img src={Iconwoocommerce} alt='Iconwoocommerce' />
          )}
          {integrationPanelData.name === 'PushApi' && (
            <img src={IconPushAPI} alt='IconPushAPI' />
          )}
          <p className='text-lg font-bold pl-2 pr-2'>
            {integrationPanelData.name}
          </p>
          <div className='bg-N-300 rounded-[100px]'>
            <p className='text-xs font-semibold px-[7px]'>
              {integrationPanelData.version}
            </p>
          </div>
        </div>
        <div className='flex items-center'>
          <div
            className='flex items-center justify-center cursor-pointer'
            onClick={() => {
              setShowDetails(!showDetails)
            }}>
            <i
              className={CN('', {
                'ri-arrow-up-s-line': showDetails,
                'ri-arrow-down-s-line': !showDetails,
              })}></i>
          </div>
        </div>
      </div>
      {showDetails && (
        <div
          className={CN(
            'flex flex-col w-full bg-N-50 items-start justify-center p-4 border-b border-N-200 transition-height rounded-b-lg',
            {},
          )}>
          <div className='flex w-full items-center pt-6 gap-x-8'>
            <div className='flex items-center'>
              <CheckBox
                checked={integrationPanelData.extractOrders}
                disabled
                onChange={() => {
                  console.log('View Only')
                }}
              />
              <p className='text-sm font-semibold pl-[10px]'>Extract Orders</p>
            </div>
            <div className='flex items-center'>
              <CheckBox
                checked={integrationPanelData.updateTrackingInfo}
                disabled
                onChange={() => {
                  console.log('View Only')
                }}
              />
              <p className='text-sm font-semibold pl-[10px]'>
                Update Tracking Info
              </p>
            </div>
            <div className='flex items-center'>
              <CheckBox
                checked={integrationPanelData.updateShippingInfo}
                disabled
                onChange={() => {
                  console.log('View Only')
                }}
              />
              <p className='text-sm font-semibold pl-[10px]'>
                Update Shipping Info
              </p>
            </div>
            <div className='flex items-center'>
              <CheckBox
                checked={integrationPanelData.enabledSubCarrierAccount}
                disabled
                onChange={() => {
                  console.log('View Only')
                }}
              />
              <p className='text-sm font-semibold pl-[10px]'>
                Sub Courier Account
              </p>
            </div>
          </div>
          <div className='flex w-full items-center pt-6'>
            <div className='flex items-center mr-8'>
              <CheckBox
                checked={integrationPanelData.isStockValidating}
                disabled
                onChange={() => {
                  console.log('View Only')
                }}
              />
              <p className='text-sm font-semibold pl-[10px]'>
                Stock Validation
              </p>
            </div>
          </div>
          <div className='flex w-full items-center pt-[14px]'>
            {ViewSection(
              'Warehouse:',
              integrationPanelData.warehouses.label || '-',
              'full',
            )}
          </div>
          {integrationPanelData.apiBaseUrl !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'API Base URL:',
                integrationPanelData.apiBaseUrl || '-',
                'full',
              )}
            </div>
          )}
          {integrationPanelData.consumerKey !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'Consumer Key:',
                integrationPanelData.consumerKey || '-',
                'full',
              )}
            </div>
          )}
          {integrationPanelData.consumerSecret !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'Consumer Secret:',
                integrationPanelData.consumerSecret || '-',
                'full',
              )}
            </div>
          )}
          {integrationPanelData.apiPassword !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'API Password:',
                integrationPanelData.apiPassword || '-',
                'full',
              )}
            </div>
          )}
          {integrationPanelData.locationId !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'Location ID:',
                integrationPanelData.locationId || '-',
                'left',
              )}
            </div>
          )}
          {integrationPanelData.apiKey !== undefined && (
            <div className='flex w-full items-center pt-[14px]'>
              {ViewSection(
                'API Key:',
                integrationPanelData.apiKey || '-',
                'full',
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

IntegrationPanelView.defaultProps = {
  IntegrationData: undefined,
}

export default IntegrationPanelView
