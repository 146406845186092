import { FC } from 'react'

export interface AuthLayoutProps {
  className?: string | undefined
  [x: string]: any
}

export const AuthLayout: FC<AuthLayoutProps> = ({
  children,
}: AuthLayoutProps) => {
  return (
    <div className='flex h-screen back-drop'>
      <div className='flex w-full items-center justify-center backdrop-blur bg-black bg-opacity-40'>
        <div className='flex'>{children}</div>
        <div className='flex justify-between px-8 left-[50%] translate-x-[-50%] absolute bottom-[20px] w-full text-sm text-white'>
          <p>
            © {new Date().getFullYear()} BDL, All rights reserved.
            {/* Powered by{' '} <a className='font-bold text-P-500' href='https://www.google.lk/'>
              Taprobanes IT
            </a> */}
          </p>
          <div className='flex'>
            <a className='pl-11' href='https://www.bdynamiclogistics.com.au/'>
              BDL Home
            </a>
            {/* <a className='pl-11' href='https://www.google.lk/'>
              Terms
            </a>
            <a className='pl-11' href='https://www.google.lk/'>
              Privacy Policy
            </a> */}
          </div>
        </div>
      </div>
    </div>
  )
}

AuthLayout.defaultProps = {
  className: undefined,
}

export default AuthLayout
