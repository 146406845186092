import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateWarehouseIntegrationInterface {
  apiKey?: string
  baseUrl?: string
  name?: string
  token?: string
  warehouseId?: string
}

export const useAddWarehouseIntegration = () => {
  const mutate = useMutation(
    ({
      baseUrl,
      apiKey,
      name,
      token,
      warehouseId,
    }: UpdateWarehouseIntegrationInterface) => {
      return postAPI(`/Warehouse/${warehouseId}/Integration/${name}`, {
        baseUrl,
        apiKey: name === 'IcsWMS' ? apiKey : undefined,
        token: name === 'PaperlessWMS' ? token : undefined,
      })
    },
  )

  return mutate
}

export default useAddWarehouseIntegration
