import React from 'react'

export const columns = [
  {
    customWidth: 200,
    Header: () => 'Principle',
    accessor: 'principle',
    Cell: ({ row }) => <div>{row.original.principle}</div>,
  },
  {
    customWidth: 200,
    Header: () => 'SKU',
    accessor: 'sku',
    Cell: ({ row }) => <div>{row.original.sku}</div>,
  },
  {
    customWidth: 200,
    Header: 'UOM',
    accessor: 'uom',
    Cell: ({ row }) => <div>{row.original.uom}</div>,
  },
  {
    customWidth: 200,
    Header: 'Stock on Hand',
    accessor: 'availableStock',
    Cell: ({ row }) => <div>{row.original.availableStock}</div>,
  },
  {
    customWidth: 200,
    Header: 'Commited',
    accessor: 'commited',
    Cell: ({ row }) => <div>{row.original.commited}</div>,
  },
  {
    customWidth: 200,
    Header: () => 'Warehouse',
    accessor: 'warehouse',
    Cell: ({ row }) => <div>{row.original.warehouse}</div>,
  },
]

export default columns
