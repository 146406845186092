import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteWarehouseInterface {
  warehouseId?: string
}

export const useDeleteWarehouse = () => {
  const mutate = useMutation(({ warehouseId }: DeleteWarehouseInterface) => {
    return deleteAPI(`/Warehouse/${warehouseId}`)
  })

  return mutate
}

export default useDeleteWarehouse
