import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

import { capitalizeAll } from 'utils/capitalizeAll'

interface GetStockOrdersListInterface {
  take?: number
  skip?: number
  Search?: string
  partner?: any
  status?: string
  from?: any
  to?: any
}

export const useGetStockOrdersList = () => {
  const mutate = useMutation(
    ({
      take,
      skip,
      Search,
      status,
      partner,
      from,
      to,
    }: GetStockOrdersListInterface) => {
      return get(
        `/StockOrder?take=${take}&skip=${skip}&SearchKeyword=${Search}&Status=${
          status === 'All' ? '' : capitalizeAll(status)
        }&PartnerName=${
          partner.label === undefined ? '' : partner.label
        }&from=${from}&to=${to}`,
      )
    },
  )

  return mutate
}

export default useGetStockOrdersList
