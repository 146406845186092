import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateNewUserRoleInterface {
  name?: string
}

export const useCreateNewUserRole = () => {
  const mutate = useMutation(({ name }: CreateNewUserRoleInterface) => {
    return postAPI(`/Identity/Role`, {
      name: name,
    })
  })

  return mutate
}

export default useCreateNewUserRole
