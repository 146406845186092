import { FC } from 'react'

import { FilterChip } from 'components/atoms/FilterChip'
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter'

export interface FilterChipSectionProps {
  [x: string]: any
}

/** This is FilterChipSection */

export const FilterChipSection: FC<FilterChipSectionProps> = ({
  filterSectionKey,
  stockOrderVariables,
  startDateValue,
  endDateValue,
  setStockOrderVariables,
  setPageNumber,
}: FilterChipSectionProps) => {
  const resetStatusFilter = () => {
    setPageNumber(1)
    setStockOrderVariables({
      ...stockOrderVariables,
      status: 'All',
    })
  }

  const resetPartnerFilter = () => {
    setPageNumber(1)
    setStockOrderVariables({
      ...stockOrderVariables,
      partner: '',
    })
  }

  const resetDateFilter = () => {
    setPageNumber(1)
    setStockOrderVariables({
      ...stockOrderVariables,
      from:
        startDateValue.getFullYear() +
        '-' +
        ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        startDateValue.getDate(),
      to:
        endDateValue.getFullYear() +
        '-' +
        ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
        '-' +
        endDateValue.getDate(),
    })
  }

  return (
    <div
      className='flex w-full items-center justify-start'
      key={filterSectionKey}>
      {stockOrderVariables.status !== 'All' &&
        stockOrderVariables.status !== '' && (
          <FilterChip
            labelValue='Status'
            filterValue={capitalizeFirstLetter(stockOrderVariables.status)}
            onClick={() => {
              resetStatusFilter()
            }}
          />
        )}
      {stockOrderVariables.partner.value !== undefined && (
        <FilterChip
          labelValue='Partner'
          filterValue={stockOrderVariables.partner.label}
          onClick={() => {
            resetPartnerFilter()
          }}
        />
      )}
      {!(
        stockOrderVariables.from.toString() ===
          (
            startDateValue.getFullYear() +
            '-' +
            ('0' + (startDateValue.getMonth() + 1)).slice(-2) +
            '-' +
            startDateValue.getDate()
          ).toString() &&
        stockOrderVariables.to.toString() ===
          (
            endDateValue.getFullYear() +
            '-' +
            ('0' + (endDateValue.getMonth() + 1)).slice(-2) +
            '-' +
            endDateValue.getDate()
          ).toString()
      ) && (
        <FilterChip
          labelValue='Date'
          filterValue={`${stockOrderVariables.from} to ${stockOrderVariables.to}`}
          onClick={() => {
            resetDateFilter()
          }}
        />
      )}
    </div>
  )
}

FilterChipSection.defaultProps = {}

export default FilterChipSection
