/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetStateListByCountryCodeInterface {
  code: string
}

export const useGetStateListByCountryCode = () => {
  const mutate = useMutation(({ code }: GetStateListByCountryCodeInterface) => {
    return get(`/State?CountryCode=${code}`)
  })

  return mutate
}

export default useGetStateListByCountryCode
