import { FC } from 'react'
import { useSelector } from 'react-redux'

import { Button } from 'components/atoms/Button'

export interface OrderFooterProps {
  [x: string]: any
  isCancelOrderBtn?: boolean
  isRetryOrderBtn?: boolean
  onClickRetryButton?: any
  onClickCancelButton?: any
  orderDetails?: any
  isLoadingCancelButton?: boolean
  isLoadingRetryButton?: boolean
}

/** This is OrderFooter */

export const OrderFooter: FC<OrderFooterProps> = ({
  onClickRetryButton,
  isLoadingCancelButton,
  isLoadingRetryButton,
  isCancelOrderBtn,
  isRetryOrderBtn,
  onClickCancelButton,
}: OrderFooterProps) => {
  const { userDetails }: any = useSelector<any>((state) => state.user)

  const permissionForOrderRetry = userDetails?.userPermissions?.includes(
    'StockOrderRetryAccess',
  )

  const permissionForOrderCancel = userDetails?.userPermissions?.includes(
    'StockOrderCancelAccess',
  )

  return (
    <div className='footer_container flex w-full pb-3 px-4 pt-4 border-t border-t-N-200 sticky bottom-0 bg-white z-[1]'>
      {isRetryOrderBtn && permissionForOrderRetry && (
        <Button
          className='mr-5'
          onClick={onClickRetryButton}
          isLoading={isLoadingRetryButton}
          disabled={isLoadingRetryButton}>
          Retry Order
        </Button>
      )}

      {isCancelOrderBtn && permissionForOrderCancel && (
        <Button
          className='text-R-500 border-R-500 hover:bg-N-50'
          appearance='outline'
          isLoading={isLoadingCancelButton}
          disabled={isLoadingCancelButton}
          onClick={onClickCancelButton}>
          Cancel Order
        </Button>
      )}
    </div>
  )
}

OrderFooter.defaultProps = {
  isCancelOrderBtn: true,
  isRetryOrderBtn: false,
  onClickRetryButton: undefined,
  onClickCancelButton: undefined,
  isLoadingCancelButton: false,
  isLoadingRetryButton: false,
}

export default OrderFooter
