import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

const userDelete = async (reqBody: any) => {
  const res = await deleteAPI(`/UserProfile/${reqBody.id}`)
  return res.data
}

export const useDeleteUser = (onSettled: any, onError: any) => {
  return useMutation(userDelete, {
    onSettled,
    onError,
  })
}
