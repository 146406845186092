/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'
import CN from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

export interface AccordionProps {
  className?: string | undefined
  OnClick?: (e: any) => void | undefined
  isOpen?: boolean
  title?: string
  subTitle?: string
  [x: string]: any
}

export const Accordion: FC<AccordionProps> = ({
  children,
  childrenWrapperClassName,
  OnClick,
  isOpen,
  title,
  subTitle,
}: AccordionProps) => {
  return (
    <>
      <div className='flex  w-full items-center justify-between'>
        <div className='flex items-center'>
          <h2 className='text-sm font-medium text-N-700'>{title}</h2>
          <p className='text-sm font-normal text-N-500'>&nbsp;{subTitle}</p>
        </div>
        <i
          onClick={OnClick}
          className={CN('cursor-pointer text-lg', {
            'ri-arrow-down-s-line': !isOpen,
            'ri-arrow-up-s-line': isOpen,
          })}></i>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial='collapsed'
            animate='open'
            exit='collapsed'
            transition={{ type: 'tween', duration: 0.3 }}
            variants={{
              open: { opacity: 1, y: 0, height: 'auto' },

              collapsed: { opacity: -1, y: -35, height: 0 },
            }}
            className={CN('flex w-full', childrenWrapperClassName)}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

Accordion.defaultProps = {
  className: undefined,
  title: '',
  subTitle: '',
  isOpen: false,
}

export default Accordion
