/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { Logo } from 'components/atoms/Logo'
import { PopOut } from 'components/atoms/PopOut'

export interface ForgotPasswordFormProps {
  [x: string]: any
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  forgotPasswordData,
  forgotPasswordError,
  forgotPasswordIsLoading,
  handleForgotPasswordSubmit,
  setLogInComponent,
  setUserData,
  userData,
  setForgotPasswordData,
  setForgotPasswordError,
  ...restProps
}: ForgotPasswordFormProps) => {
  const [isResetButtonDisable, setIsResetButtonDisable] = useState(true)

  /** Reset Button state handling */
  useEffect(() => {
    userData.resetEmail?.length > 0
      ? setIsResetButtonDisable(false)
      : setIsResetButtonDisable(true)
  }, [userData])

  /** This is temporary -- This can be Deleted once the Email is done */
  useEffect(() => {
    setUserData({ ...userData, resetEmail: '' })
    setForgotPasswordData({
      ...forgotPasswordData,
      isSuccess: false,
      data: {},
    })
    setForgotPasswordError({
      ...forgotPasswordError,
      isError: false,
      message: '',
    })
  }, [])

  return (
    <div
      className='w-[520px] bg-white py-[86px] px-9 rounded-[20px] shadow-2xl'
      {...restProps}>
      <div className='w-full h-full'>
        <Logo />

        <div className='flex flex-col w-full pt-[38px]'>
          <span className='text-3xl text-N-700 font-bold'>
            Forgot Password?
          </span>
          <span className='text-gray-800 pt-3'>
            Don’t worry. We will send you a password reset link right away.
            Check your mailbox and click on the reset link to get a new
            password.
          </span>
        </div>

        <div className='pt-8'>
          <input
            type='email'
            id='email'
            value={userData.resetEmail}
            onChange={(e) => {
              setUserData({ ...userData, resetEmail: e.target.value })
            }}
            className={CN(
              'z-10 mt-4 text-N-900 text-sm rounded-[10px] w-full p-[13px] outline-none focus:ring-1 focus:ring-P-500',
              {
                'ring-1 ring-P-500 bg-white': userData?.resetEmail?.length > 0,
                'bg-N-100':
                  userData?.resetEmail?.length === 0 ||
                  userData?.resetEmail?.length === undefined,
              },
            )}
            placeholder='Type in your email address'
            required
          />
        </div>

        {forgotPasswordError?.isError && (
          <PopOut
            className='mt-6'
            size='small'
            isShowCloseButton={false}
            appearance='error'>
            {forgotPasswordError?.message}
          </PopOut>
        )}

        {forgotPasswordData?.isSuccess && (
          <PopOut
            className='mt-6'
            size='small'
            isShowCloseButton={false}
            appearance='success'>
            {forgotPasswordData?.data?.message}
          </PopOut>
        )}

        {!forgotPasswordData?.isSuccess && (
          <div className='pt-6'>
            <Button
              onClick={(e: any) => {
                handleForgotPasswordSubmit(e)
              }}
              disabled={forgotPasswordIsLoading || isResetButtonDisable}
              isLoading={forgotPasswordIsLoading}
              size='lg'
              isFullWidth>
              Send Password Reset Link
            </Button>
          </div>
        )}

        <div className='flex pt-[38px] items-center justify-center cursor-pointer'>
          <p
            onClick={() => {
              setLogInComponent('logIn')
            }}
            className='flex justify-center items-center text-P-700 text-sm'>
            <i className='ri-arrow-left-line pr-4'></i> Back to Login
          </p>
        </div>
      </div>
    </div>
  )
}

ForgotPasswordForm.defaultProps = {}

export default ForgotPasswordForm
