import { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import orderConsignmentVector from 'assets/images/order-consignment-vector.svg'
import { setShippingSummeryWarehouseCodes } from 'store/reducers/order/orderSlice'

import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'

export interface ScanSubmitFormProps {
  [x: string]: any
  isBulk: boolean
  isLoading: boolean
}

/** This is OrderFooter */

export const ScanSubmitFormBulkOrder: FC<ScanSubmitFormProps> = ({
  isBulk,
  isLoading,
  setWmsOrderId,
}: ScanSubmitFormProps) => {
  const dispatch = useDispatch()
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e: any) => {
    setInputValue(e.target.value)
  }

  const handleSearchKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setWmsOrderId(inputValue)
    }
  }

  useEffect(() => {
    dispatch(setShippingSummeryWarehouseCodes(null))
  }, [])

  return (
    <div className='flex flex-col w-2/4'>
      <div className='flex items-center flex-col'>
        {/* vector image */}
        <img src={orderConsignmentVector} alt='order-consignment-vector' />

        {/* label text for bulk and */}
        <p className='font-semibold text-N-500 mt-[62px]'>
          {isBulk
            ? `Type-in bulk WMS order # to consign`
            : `Scan item or type in WMS order # to consign`}
        </p>

        {/* input field */}
        <Input
          className='!w-[420px] mt-[18px]'
          type='text'
          value={inputValue}
          isAutoFocus={true}
          inputClassName='text-center px-4 py-3 focus:border focus:border-P-500'
          placeholder='Ex: 00064152-00'
          onChange={(e: any) => {
            handleChange(e)
          }}
          onKeyPress={(e: any) => {
            handleSearchKeyPress(e)
          }}
        />

        {/* submit button */}
        <Button
          isLoading={isLoading}
          disabled={isLoading}
          className='!w-[420px] px-[10px] mt-6 text-lg'
          onClick={() => {
            setWmsOrderId(inputValue)
          }}>
          {isBulk ? `Consign Bulk Order` : `Proceed Consignment`}
        </Button>
      </div>
    </div>
  )
}

ScanSubmitFormBulkOrder.defaultProps = {
  isLoading: false,
  isBulk: false,
}

export default ScanSubmitFormBulkOrder
