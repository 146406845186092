import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getWarehouseByIdInterface {
  id?: string
}

export const useGetWarehouseById = () => {
  const mutate = useMutation(({ id }: getWarehouseByIdInterface) => {
    return get(`/Warehouse/${id}`)
  })

  return mutate
}

export default useGetWarehouseById
