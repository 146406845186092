import { FC } from 'react'

import { ShippingLineSelection } from 'components/molecules/ShippingLineSelection'

export interface TabAusPostProps {
  className?: string | undefined
  key?: any
  shipment: any
}

export const ConsignedShippingLine: FC<TabAusPostProps> = ({
  className,
  key,
  shipment,
}: TabAusPostProps) => {
  let objArray: { type: string; price: any }[] = []
  if (shipment?.consignment?.type === 'AUSPOST') {
    objArray = [
      {
        type: 'Total Cost Ext GST',
        price: shipment?.consignment?.totalCostExtGST,
      },
      {
        type: 'Total Gst',
        price: shipment?.consignment?.totalGst,
      },
      {
        type: 'Total Cost',
        price: shipment?.consignment?.totalCost,
      },
    ]
  } else if (shipment?.consignment?.type === 'AUSPOST_INTERNATIONAL') {
    objArray = [
      {
        type: 'Total Cost Ext GST',
        price: shipment?.consignment?.totalCostExtGST,
      },
      {
        type: 'Total Gst',
        price: shipment?.consignment?.totalGst,
      },
      {
        type: 'Total Cost',
        price: shipment?.consignment?.totalCost,
      },
    ]
  } else if (shipment?.consignment?.type === 'DIRECT') {
    objArray = [
      {
        type: 'Fuel Levy',
        price: shipment?.consignment?.fuelLevy,
      },
      {
        type: 'Fuel Levy Charge',
        price: shipment?.consignment?.fuelLevyCharge,
      },
      {
        type: 'On Forward Charge',
        price: shipment?.consignment?.onForwardCharge,
      },
      {
        type: 'Standard Charge',
        price: shipment?.consignment?.standardCharge,
      },
      {
        type: 'Total Charge',
        price: shipment?.consignment?.totalCharge,
      },
    ]
  } else if (shipment?.consignment?.type === 'DSE') {
    objArray = [
      {
        type: 'Persons to Lift',
        price: shipment?.consignment?.personsLift,
      },
      {
        type: 'Number Of Packages',
        price: shipment?.consignment?.numberOfPackages,
      },
      {
        type: 'Charge Weight',
        price: shipment?.consignment?.chargeWeight,
      },
      {
        type: 'Cubic Weight',
        price: shipment?.consignment?.cubicWeight,
      },
      {
        type: 'Dead Weight',
        price: shipment?.consignment?.deadWeight,
      },
    ]
  } else if (shipment?.consignment?.type === 'STARSHIPIT') {
    objArray = [
      {
        type: 'Service Name',
        price: shipment?.consignment?.productName,
      },
      {
        type: 'Service Code',
        price: shipment?.consignment?.productCode,
      },
      {
        type: 'Declared Value',
        price: shipment?.consignment?.declaredValue,
      },
    ]
  } else if (shipment?.consignment?.type === 'SMART/SEND') {
    objArray = [
      {
        type: 'Discount',
        price: shipment?.consignment?.discount,
      },
      {
        type: 'Total Discount',
        price: shipment?.consignment?.totalDiscount,
      },
      {
        type: 'Total Transport Assurance',
        price: shipment?.consignment?.totalTransportAssurance,
      },
      {
        type: 'Total GST',
        price: shipment?.consignment?.totalGST,
      },
      {
        type: 'Total Price',
        price: shipment?.consignment?.totalPrice,
      },
    ]
  } else if (shipment?.consignment?.type === 'MACHSHIP') {
    objArray = [
      {
        type: 'Base Total',
        price: shipment?.consignment?.baseTotal,
      },
      {
        type: 'Fuel Level',
        price: shipment?.consignment?.fuelLevy,
      },
      {
        type: 'GST Price',
        price: shipment?.consignment?.gstPrice,
      },
      {
        type: 'Total Price',
        price: shipment?.consignment?.total,
      },
    ]
  } else if (shipment?.consignment?.type === 'ALLIED') {
    objArray = [
      {
        type: 'Job Charge',
        price: shipment?.consignment?.cost,
      },
      {
        type: 'Total GST',
        price: shipment?.consignment?.totalGst,
      },
      {
        type: 'Total',
        price: shipment?.consignment?.totalCost,
      },
    ]
  }
  return (
    <ShippingLineSelection
      key={key}
      isViewOnly={true}
      isForceToStayOpen={true}
      isStayOpen={true}
      ShippingLineObject={{
        type: shipment?.consignment?.type,
        productName: shipment?.consignment?.productName,
        shipmentId: shipment.id,
        productId: shipment?.consignment?.productId,
        cost: shipment?.consignment?.cost,
        originCharges: objArray,
        carrierCode: shipment?.consignment?.carrierCode,
        carrierService: shipment?.consignment?.carrierService,
      }}
    />
  )
}

ConsignedShippingLine.defaultProps = {
  className: undefined,
}

export default ConsignedShippingLine
