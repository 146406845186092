import ReactTooltip from 'react-tooltip'

export const columns = [
  {
    customWidth: 100,
    Header: () => '',
    accessor: 'status',
    Cell: ({ row }) => (
      <div
        className='flex items-center'
        data-tip={row.original.wareHouseOrderLineError}>
        {row.original.wareHouseOrderLineError && (
          <>
            <i className='ri-error-warning-fill text-sm text-R-500 ml-1'></i>
            <p className='pl-2'>Error</p>
            <ReactTooltip
              place='top'
              effect='solid'
              delayShow={200}
              textColor='#E2E8F0'
              backgroundColor='#334155'
            />
          </>
        )}
        {row.original.wareHouseOrderLineError === null && (
          <i className='ri-checkbox-circle-fill text-sm text-G-500 ml-1'></i>
        )}
      </div>
    ),
  },
  {
    customWidth: 0,
    Header: () => '',
    accessor: 'action',
    isOrderLineUpdate: true,
    Cell: () => true,
  },
  {
    customWidth: 100,
    Header: () => 'SKU',
    accessor: 'sku',
    Cell: ({ row }) => <div>{row?.original?.partnerSKU}</div>,
  },
  {
    customWidth: 120,
    Header: () => 'Description',
    accessor: 'description',
    Cell: ({ row }) => (
      <div className='w-[120px]'>{row?.original?.description}</div>
    ),
  },
  {
    customWidth: 80,
    Header: 'UOM',
    accessor: 'UOM',
    Cell: ({ row }) => <div className='w-[80px]'>{row?.original?.uom}</div>,
  },
  {
    customWidth: 112,
    Header: () => 'Total Qty',
    accessor: 'totalQty',
    Cell: ({ row }) => (
      <div className='w-[112px]'>{row?.original?.totalQty}</div>
    ),
  },
  {
    customWidth: 103,
    Header: () => 'Received Qty',
    accessor: 'receivedQty',
    Cell: ({ row }) => <div>{row?.original?.receivedQty}</div>,
  },
  {
    customWidth: 111,
    Header: 'Remaining Qty',
    accessor: 'remainingQty',
    Cell: ({ row }) => <div>{row?.original?.remainingQty}</div>,
  },
  {
    customWidth: 85,
    Header: () => 'Type',
    accessor: 'type',
    Cell: ({ row }) => <div>{row?.original?.type}</div>,
  },
  {
    customWidth: 85,
    Header: () => 'Group',
    accessor: 'group',
    Cell: ({ row }) => <div>{row?.original?.group}</div>,
  },
  {
    customWidth: 126,
    Header: () => 'Batch/Serial',
    accessor: 'gtinBarcode',
    Cell: ({ row }) => <div>{row?.original?.gtinBarcode}</div>,
  },
  {
    customWidth: 90,
    Header: () => 'Height',
    accessor: 'height',
    Cell: ({ row }) => <div>{row?.original?.height}</div>,
  },
  {
    customWidth: 90,
    Header: () => 'Width',
    accessor: 'width',
    Cell: ({ row }) => <div>{row?.original?.width}</div>,
  },
  {
    customWidth: 90,
    Header: () => 'Length',
    accessor: 'length',
    Cell: ({ row }) => <div>{row?.original?.length}</div>,
  },
  {
    customWidth: 90,
    Header: () => 'Weight',
    accessor: 'weight',
    Cell: ({ row }) => <div>{row?.original?.weight}</div>,
  },
  {
    customWidth: 100,
    Header: () => 'Volume',
    accessor: 'volume',
    Cell: ({ row }) => <div>{row?.original?.vol}</div>,
  },
  {
    customWidth: 100,
    Header: () => 'Barcode',
    accessor: 'barcode',
    Cell: ({ row }) => <div>{row?.original?.barcode}</div>,
  },
]

export default columns
