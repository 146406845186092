import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateGenericTasksInterface {
  formDetails?: any
}

export const useCreateGenericTasks = () => {
  const mutate = useMutation(({ formDetails }: CreateGenericTasksInterface) => {
    return postAPI(`/Tasks/GenericTask/Create`, {
      warehouseCode: formDetails?.warehouse?.code,
      title: formDetails?.title,
      description: formDetails?.description,
    })
  })

  return mutate
}

export default useCreateGenericTasks
