/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { postAPI } from 'framework/api/http'

const extractLocation = async (reqData: any) => {
  const data = reqData.queryKey[1]

  const res = await postAPI(
    `/Partner/${data.partnerId}/Integration/Shopify/${data.partnerIntegrationId}/ExtractShopifyLocation`,
    {
      apiBaseUrl: data.apiBaseUrl,
      accessToken: data.apiPassword,
    },
  )
  return res.data
}

export const useExtractLocation = (
  { ...requestData }: any,
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['api_data', requestData], extractLocation, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useExtractLocation
