import React, { FC, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import CN from 'classnames'
import {
  useDeleteUserRoles,
  useGetUserPrivilegesTable,
  useGetUserRolesTable,
} from 'framework/api/methods'

import { Button, PopUpAlert } from 'components/atoms'
import { AddUserRoles, ViewUserRoles } from 'components/DrawerModals/UserRoles'

import { DataTable } from './UserRolesDataTable'

export interface UserRoleListProps {
  [x: string]: any
}

/** This is user roles */

export const UserRoleList: FC<UserRoleListProps> = ({
  className,
}: UserRoleListProps) => {
  const [rows, setRows] = useState({})
  const [selectedRowByButtonAction, setSelectedRowByButtonAction] = useState({
    id: '',
  })

  const [isAddNew, setIsAddNew] = useState(false)
  const [viewUserRoleDrawerIsOpen, setViewUserRoleDrawerIsOpen] =
    useState(false)
  const [addUserRoleDrawerIsOpen, setAddUserRoleDrawerIsOpen] = useState(false)
  const [deleteUserRolePopOutIsOpen, setDeleteUserRolePopOutIsOpen] =
    useState(false)
  const [userPrivilegesList, setUserPrivilegesList] = useState<any>([])

  const [refetch, setRefetch] = useState(true)

  /** notify message */
  const notify = (message: any, type: any) => {
    if (type === 'error') {
      toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    if (type === 'success') {
      toast.success(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }

  /** API Call for get user privileges using react-query */
  const { mutate: getUserPrivilegesTableMutate } = useGetUserPrivilegesTable()

  /** Process the get User privileges Table Data */
  async function getUserPrivilegesTableList() {
    getUserPrivilegesTableMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setUserPrivilegesList(successData.appPermissions)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for get user roles using react-query */
  const {
    mutate: getUserRolesTableMutate,
    isLoading: getUserRolesTableIsLoading,
  } = useGetUserRolesTable()

  /** Process the get User roles Table Data */
  async function getUserRolesTableList() {
    getUserRolesTableMutate(
      {},
      {
        onSuccess: ({ data: successData }: any) => {
          setRows(successData.roles)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  /** API Call for delete user roles using react-query */
  const { mutate: deleteUserRoleMutate } = useDeleteUserRoles()

  /** Process the Delete User Roles */
  async function deleteUserRole(userRoleId: string) {
    deleteUserRoleMutate(
      { userRoleId },
      {
        onSuccess: ({ data: successData }: any) => {
          setDeleteUserRolePopOutIsOpen(false)
          notify('User role successfully deleted.', 'success')
          getUserRolesTableList()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
          setDeleteUserRolePopOutIsOpen(false)
        },
      },
    )
  }

  useEffect(() => {
    getUserRolesTableList()
    getUserPrivilegesTableList()
  }, [refetch])

  return (
    <div
      className={CN(
        'flex justify-center bg-N-50 h-full w-full px-8 py-5',
        {},
        className,
      )}>
      <div className='flex flex-col h-full w-full rounded-lg bg-white shadow-md px-5 py-7'>
        <div className='flex flex-col w-full h-full bg-white'>
          <h2 className='text-lg font-semibold'>User Roles</h2>
          <div className='flex w-full items-center justify-between pt-6'>
            <Button
              onClick={() => {
                setIsAddNew(true)
                setAddUserRoleDrawerIsOpen(true)
              }}>
              <i className='ri-add-circle-line pr-2'></i>Add New Role
            </Button>
          </div>
          <div className='w-[450px] h-[calc(100%-100px)] pt-4'>
            <DataTable
              itemData={rows}
              setAddUserRoleDrawerIsOpen={setAddUserRoleDrawerIsOpen}
              setSelectedRowByButtonAction={setSelectedRowByButtonAction}
              getUserRoleTableIsLoading={getUserRolesTableIsLoading}
              setViewUserRoleDrawerIsOpen={setViewUserRoleDrawerIsOpen}
              setDeleteUserRolePopOutIsOpen={setDeleteUserRolePopOutIsOpen}
              setIsAddNew={setIsAddNew}
            />
          </div>

          {addUserRoleDrawerIsOpen && (
            <AddUserRoles
              isActive={addUserRoleDrawerIsOpen}
              onCloseClick={() => setAddUserRoleDrawerIsOpen(false)}
              userRoleDetails={selectedRowByButtonAction}
              isAddNew={isAddNew}
              notify={notify}
              setRefetch={setRefetch}
              refetch={refetch}
              privilegesList={userPrivilegesList}
            />
          )}

          {viewUserRoleDrawerIsOpen && (
            <ViewUserRoles
              isActive={viewUserRoleDrawerIsOpen}
              onCloseClick={() => setViewUserRoleDrawerIsOpen(false)}
              userRoleDetails={selectedRowByButtonAction}
              setIsAddNew={setIsAddNew}
              notify={notify}
              setUpdateUserRoleDrawerIsOpen={setAddUserRoleDrawerIsOpen}
              setDeleteUserRolePopOutIsOpen={setDeleteUserRolePopOutIsOpen}
              setViewUserRoleDrawerIsOpen={setViewUserRoleDrawerIsOpen}
              privilegesList={userPrivilegesList}
            />
          )}

          {deleteUserRolePopOutIsOpen && (
            <PopUpAlert
              isActive={deleteUserRolePopOutIsOpen}
              title='Delete User Role?'
              message='Are you sure you want to proceed? This is not reversible.'
              cancelButtonText='Cancel'
              confirmButtonText='Yes, I’m Sure'
              onCancelClick={() => setDeleteUserRolePopOutIsOpen(false)}
              onOverlayClick={() => setDeleteUserRolePopOutIsOpen(false)}
              onConfirmClick={() => {
                deleteUserRole(selectedRowByButtonAction.id)
              }}
            />
          )}
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
            className='toast-container'
          />
        </div>
      </div>
    </div>
  )
}

UserRoleList.defaultProps = {}

export default UserRoleList
