import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface CreateBulkOrderInterface {
  orderNo?: string
}

export const useCreateBulkOrder = () => {
  const mutate = useMutation(({ orderNo }: CreateBulkOrderInterface) => {
    return postAPI(`/BulkConsignments`, { orderNo: orderNo })
  })

  return mutate
}

export default useCreateBulkOrder
