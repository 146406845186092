/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, ReactNode } from 'react'
import CN from 'classnames'
import { motion } from 'framer-motion'

interface DrawerProps {
  children?: ReactNode | string | number | undefined
  className?: string | undefined
  isActive?: boolean | undefined
  width?: string | number | undefined
  onCloseClick?: () => void
  onOverlayClick?: () => void
  title?: string | undefined
  objectAfterTitle?: any
}

export const Drawer: FC<DrawerProps> = ({
  children,
  className,
  isActive,
  onCloseClick,
  onOverlayClick,
  title,
  objectAfterTitle,
  width,
  ...restProps
}: DrawerProps) => {
  const DrawerClasses = CN(
    'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
    className,
    {},
  )

  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
  }

  return (
    <motion.div
      animate={isActive ? 'open' : 'closed'}
      variants={variants}
      className={DrawerClasses}
      onClick={(e: any) => handleOverlayClick(e)}
      {...restProps}>
      <div
        className={CN(
          `bg-white bottom-0 shadow-lg h-full fixed right-0 z-30 flex flex-col`,
        )}
        style={{ width: width }}
        onClick={(e: any) => disableOverlayClick(e)}>
        <div className='h-[60px] w-full px-4 flex items-center justify-between shadow-sm border-b border-N-200'>
          <div className='flex items-center'>
            <h2 className='text-lg font-bold'>{title}</h2>
            {objectAfterTitle && objectAfterTitle}
          </div>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='w-6 h-6 rounded-md bg-N-200 hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        {children}
      </div>
    </motion.div>
  )
}

Drawer.defaultProps = {
  children: undefined,
  className: undefined,
  isActive: false,
  onCloseClick: undefined,
  onOverlayClick: undefined,
  title: undefined,
  objectAfterTitle: undefined,
  width: 800,
}

export default Drawer
