const moment = require('moment-timezone')

export const dateToAEST = (incomingDate: any, dateOnly = false) => {
  const dateString = moment
    .utc(incomingDate)
    .tz('Australia/Sydney')
    .format(dateOnly ? 'DD/MM/YYYY' : 'DD/MM/YYYY h:mm:ss a')

  return dateString
}

export default dateToAEST
