/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, { useEffect, useMemo, useState } from 'react'

import columnsStructure from './columnsStructure'
import Table from './Table'

import './DataTable.scss'

export const DataTable = ({
  itemData,
  setTableVariables,
  tableVariables,
  totalRowCount,
  isLoading,
  pageNumber,
  setPageNumber,
  ...restProps
}) => {
  const [data, setData] = useState([])
  const columns = useMemo(() => columnsStructure, [])

  useEffect(() => {
    if (itemData.length > 0) {
      setData([...itemData])
    } else {
      setData([])
    }
  }, [itemData])

  return (
    <div className='w-full h-full text-sm data-table' {...restProps}>
      <Table
        data={data}
        columns={columns}
        setTableVariables={setTableVariables}
        tableVariables={tableVariables}
        totalRowCount={totalRowCount}
        isLoading={isLoading}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  )
}

export default DataTable
