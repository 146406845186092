import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface getPartnerIntegrationByIdInterface {
  id?: string
}

export const useGetPartnerIntegrationById = () => {
  const mutate = useMutation(({ id }: getPartnerIntegrationByIdInterface) => {
    return get(`/Partner/${id}/Integration?skip=0&take=99`)
  })

  return mutate
}

export default useGetPartnerIntegrationById
