/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'

import { Button } from 'components/atoms/Button'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'

export interface AddNewPackageModalProps {
  isActive?: boolean | undefined
  className?: string | undefined
  isCustomStyles?: boolean | undefined
  onCloseClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  notify?: any
  shipmentPackageDetails?: any
  setShipmentPackageDetails?: any
  useCreateShipmentPackage?: any
  callOrderShipmentsById?: any
  selectedShipmentId?: any
  selectedOrderDetails?: any
}

export const AddNewPackageModal: FC<AddNewPackageModalProps> = ({
  className,
  isActive,
  isCustomStyles,
  onCloseClick,
  onOverlayClick,
  shipmentPackageDetails,
  setShipmentPackageDetails,
  useCreateShipmentPackage,
  callOrderShipmentsById,
  selectedShipmentId,
  selectedOrderDetails,
  notify,
}: AddNewPackageModalProps) => {
  /** Handling Click Status */
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCloseClick = (event: any) => {
    if (onCloseClick) {
      event.stopPropagation()
      onCloseClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const packageTypes = [
    {
      value: 'Carton',
      label: 'Carton',
    },
    {
      value: 'Pallet',
      label: 'Pallet',
    },
    {
      value: 'Envelope',
      label: 'Envelope',
    },
    {
      value: 'Satchel',
      label: 'Satchel',
    },
    {
      value: 'Paper Roll',
      label: 'Paper Roll',
    },
  ]

  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true)

  useEffect(() => {
    if (
      shipmentPackageDetails.packageQty &&
      Number(shipmentPackageDetails.packageQty) > 0 &&
      shipmentPackageDetails.length &&
      Number(shipmentPackageDetails.length) > 0 &&
      shipmentPackageDetails.width &&
      Number(shipmentPackageDetails.width) > 0 &&
      shipmentPackageDetails.height &&
      Number(shipmentPackageDetails.height) > 0 &&
      shipmentPackageDetails.weight &&
      Number(shipmentPackageDetails.weight) > 0
    ) {
      setIsConfirmButtonDisabled(false)
    } else {
      setIsConfirmButtonDisabled(true)
    }
  }, [shipmentPackageDetails])

  /** API Call for Create Shipment Package */
  const {
    mutate: createShipmentPackageMutate,
    isLoading: loadingCreatePackage,
  } = useCreateShipmentPackage()

  /** Process the Create Shipment Package */
  async function createShipmentPackage(
    wereHouseOrderId: string,
    shipmentId: string,
    packageData: any,
  ) {
    createShipmentPackageMutate(
      { wereHouseOrderId, shipmentId, packageData },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message || 'success', 'success')
          if (onCloseClick) {
            onCloseClick()
          }
          callOrderShipmentsById()
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...!', 'error')
        },
      },
    )
  }

  const handleConfirmClick = (event: any) => {
    event.stopPropagation()
    createShipmentPackage(
      selectedOrderDetails.id,
      selectedShipmentId,
      shipmentPackageDetails,
    )
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className
  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[458px] bg-white rounded-md flex-col items-center'
        onClick={(e: any) => disableOverlayClick(e)}>
        {/* Modal Header  */}
        <div className='flex items-center justify-between w-full px-4 py-3 border-b border-b-N-200'>
          <h2 className='text-lg font-bold'>Add New Package</h2>
          <button
            onClick={(e: any) => {
              handleOnCloseClick(e)
            }}
            className='bg-N-200 h-6 w-6 rounded-md hover:bg-N-300 accent-N-400'>
            <i className='ri-close-line'></i>
          </button>
        </div>
        {/* Modal Body  */}
        <div className='flex flex-col w-full pt-6 px-4 h-[300px] overflow-y-auto styled-scroll'>
          <div className='flex items-center justify-between w-full'>
            <div className='flex-flex-col pr-4 w-2/3'>
              <p className='text-xs font-medium text-N-700 pb-2'>
                Package Type
              </p>
              <SimpleSelect
                options={packageTypes}
                value={shipmentPackageDetails.packageType}
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    packageType: e,
                  })
                }}
              />
            </div>
            <div className='flex-flex-col pl-4 w-1/3'>
              <p className='text-xs font-medium text-N-700 pb-2'>Quantity</p>
              <Input
                type='text'
                value={shipmentPackageDetails.packageQty}
                isDisabled
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    packageQty: e.target.value,
                  })
                }}
              />
            </div>
          </div>
          <div className='flex items-center justify-between w-full pt-6'>
            <div className='flex-flex-col pr-4 w-2/3'>
              <div className='flex pb-2'>
                <span className='text-xs font-medium text-N-700 '>
                  Dimensions (CM) - (L x W x H per unit)
                </span>
                <span className='text-R-500 leading-[16px] ml-[2px]'>*</span>
              </div>

              <div className='flex'>
                <Input
                  type='text'
                  inputClassName='rounded-r-none'
                  value={shipmentPackageDetails.length}
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      length: e.target.value,
                    })
                  }}
                />
                <Input
                  type='text'
                  inputClassName='rounded-none'
                  value={shipmentPackageDetails.width}
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      width: e.target.value,
                    })
                  }}
                />
                <Input
                  type='text'
                  inputClassName='rounded-l-none'
                  value={shipmentPackageDetails.height}
                  onChange={(e: any) => {
                    setShipmentPackageDetails({
                      ...shipmentPackageDetails,
                      height: e.target.value,
                    })
                  }}
                />
              </div>
            </div>
            <div className='flex-flex-col pl-4 w-1/3'>
              <div className='flex'>
                <span className='text-xs font-medium text-N-700 pb-2'>
                  Weight (KG)
                </span>
                <span className='text-R-500 ml-[2px] leading-[16px] '>*</span>
              </div>

              <Input
                type='text'
                value={shipmentPackageDetails.weight}
                onChange={(e: any) => {
                  setShipmentPackageDetails({
                    ...shipmentPackageDetails,
                    weight: e.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
        {/* Modal Footer  */}
        <div className='w-full border-t border-t-N-200 mt-9 px-5 py-4'>
          <Button
            onClick={(e: any) => {
              handleConfirmClick(e)
            }}
            disabled={isConfirmButtonDisabled}
            isLoading={loadingCreatePackage}>
            Add Package
          </Button>
          <Button
            className='ml-5 w-[152px]'
            appearance='dull'
            disabled={false}
            onClick={onCloseClick}>
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}

AddNewPackageModal.defaultProps = {
  className: undefined,
  isActive: undefined,
  onCloseClick: undefined,
  onOverlayClick: undefined,
}

export default AddNewPackageModal
