/* eslint-disable no-empty-pattern */
import { useQuery } from 'react-query'
import { get } from 'framework/api/http'

const getCarrierProducts = async (reqData: any) => {
  const data = reqData.queryKey[1]
  const res = await get(`/CarrierProduct`)
  return res.data
}

export const useGetCarrierProducts = (
  { ...reqData },
  onSuccess: any,
  onError: any,
) => {
  return useQuery(['reqData', reqData], getCarrierProducts, {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: false,
    onSuccess,
    onError,
  })
}

export default useGetCarrierProducts
