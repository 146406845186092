import { FC } from 'react'
import CN from 'classnames'

export interface TextAreaProps {
  className?: string | undefined
  labelValue?: string | undefined
  value?: any
  onChange?: (e: any) => void | undefined
  isError?: boolean
  placeholder?: string
  isDisabled?: boolean
  rows?: number
}

export const TextArea: FC<TextAreaProps> = ({
  className,
  value,
  labelValue,
  onChange,
  isError,
  isDisabled,
  placeholder,
  rows,
}: TextAreaProps) => {
  return (
    <div className={CN('flex flex-col w-full', {}, className)}>
      {labelValue && (
        <span
          className={CN({
            'text-N-700 pb-2': !isError,
            'text-R-500 pb-2': isError,
          })}>
          {labelValue}
        </span>
      )}
      <textarea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        className={CN(
          'py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm',
          { 'border-R-200': isError },
        )}
        disabled={isDisabled}
      />
    </div>
  )
}

TextArea.defaultProps = {
  className: undefined,
  value: undefined,
  labelValue: undefined,
  onChange: undefined,
  isError: false,
  placeholder: undefined,
  isDisabled: false,
  rows: 4,
}

export default TextArea
