import React from 'react'

import { dateToReadableString } from 'utils/dateToReadableString'

export const columns = [
  {
    customWidth: 120,
    Header: () => 'Principle',
    accessor: 'principle',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.principle}</div>
    ),
  },
  {
    customWidth: 150,
    Header: () => 'File Name',
    accessor: 'fileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.fileName?.includes('/')
          ? row.original.fileName?.split('/')[1]
          : row.original.fileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'WMS Order',
    accessor: 'whOrderNo',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.whOrderNo}</div>
    ),
  },
  {
    customWidth: 180,
    Header: 'Customer Reference',
    accessor: 'customerReference',
    Cell: ({ row }) => (
      <div className='min-w-[120px]'>{row.original.customerReference}</div>
    ),
  },
  {
    customWidth: 180,
    Header: 'File Receipt Date',
    accessor: 'fileReceiptDate',
    Cell: ({ row }) => (
      <div className='min-w-[140px]'>
        {dateToReadableString(row.original.fileReceiptDate)}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'EN File',
    accessor: 'enFileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.enFileName?.includes('/')
          ? row.original.enFileName?.split('/')[1]
          : row.original.enFileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'EN Date',
    accessor: 'endate',

    Cell: ({ row }) => (
      <div className='min-w-[140px]'>
        {row.original.enFileDate
          ? dateToReadableString(row.original.enFileDate)
          : ''}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'RV File',
    accessor: 'rvFileName',
    Cell: ({ row }) => (
      <div className='min-w-[150px]'>
        {row.original.rvFileName?.includes('/')
          ? row.original.rvFileName?.split('/')[1]
          : row.original.rvFileName}
      </div>
    ),
  },
  {
    customWidth: 180,
    Header: 'RV Date',
    accessor: 'rvFileDate',
    Cell: ({ row }) => (
      <div className='w-[140px]'>
        {row.original.rvFileDate
          ? dateToReadableString(row.original.rvFileDate)
          : ''}
      </div>
    ),
  },
]

export default columns
