import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeletePartnerInterface {
  partnerId?: string
}

export const useDeletePartner = () => {
  const mutate = useMutation(({ partnerId }: DeletePartnerInterface) => {
    return deleteAPI(`/Partner/${partnerId}`)
  })

  return mutate
}

export default useDeletePartner
