/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import CN from 'classnames'
import { useGetStateListByCountryCode } from 'framework/api/methods'

import { Button } from 'components/atoms/Button'
import { Drawer } from 'components/atoms/Drawer'
import { Input } from 'components/atoms/Input'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'
import { isEmail } from 'utils'

export interface AddPartnerProps {
  className?: string | undefined
  [x: string]: any
}

export const AddPartner: FC<AddPartnerProps> = ({
  className,
  isActive,
  useCreatePartner,
  onCloseClick,
  countryList,
  notify,
  getPartnerTableList,
}: AddPartnerProps) => {
  const AddPartnerClasses = CN(
    'AddPartner flex flex-col p-6 relative h-full overflow-y-auto overscroll-y-auto styled-scroll',
    className,
    {},
  )

  const [stateList, setStateList] = useState([])

  const [addPartnerDetails, setAddPartnerDetails] = useState<any>({
    status: true,
    partnerName: '',
    partnerCode: '',
    contactPerson: '',
    contactEmail: '',
    supportEmail: '',
    phoneNo: '',
    streetAddress: '',
    suburb: '',
    state: '',
    postalCode: '',
    country: { label: 'Australia', value: 'AU' },
  })

  /** API Call for Login function using react-query */
  const { mutate: createPartnerMutate, isLoading: createPartnerIsLoading } =
    useCreatePartner()

  /** Process the Login submission */
  async function addPartner() {
    createPartnerMutate(addPartnerDetails, {
      onSuccess: ({ data: successData }: any) => {
        notify(successData.message, 'success')
        onCloseClick()
        getPartnerTableList()
      },
      onError: ({ response: { data: errData } }: any) => {
        notify(errData.message || 'Error', 'error')
      },
    })
  }

  const [isErrorField, setIsErrorField] = useState<any>({
    partnerName: false,
    partnerCode: false,
    contactPerson: false,
    contactEmail: false,
    supportEmail: false,
    state: false,
  })

  const validateInputs = () => {
    const {
      partnerName,
      partnerCode,
      contactPerson,
      contactEmail,
      supportEmail,
      state,
    } = addPartnerDetails

    setIsErrorField({
      ...isErrorField,
      partnerName: partnerName ? false : true,
      partnerCode: partnerCode ? false : true,
      contactPerson: contactPerson ? false : true,
      contactEmail: isEmail(contactEmail) ? false : true,
      supportEmail: isEmail(supportEmail) ? false : true,
      state: state ? false : true,
    })

    const isAllValidFields =
      partnerName &&
      partnerCode &&
      contactPerson &&
      state &&
      isEmail(contactEmail) &&
      isEmail(supportEmail)
    return isAllValidFields
  }

  const handleAddClick = () => {
    const valid = validateInputs()

    if (valid) {
      addPartner()
    }
  }

  /** API for  Get state list by country code */
  const { mutate: GetStateListByCountryCodeMutate } =
    useGetStateListByCountryCode()

  /** Process the Get state list by country code */
  async function GetStateListByCountryCode() {
    GetStateListByCountryCodeMutate(
      { code: addPartnerDetails?.country?.value },
      {
        onSuccess: ({ data: getStateListData }: any) => {
          const states = getStateListData.states.map((state: any) => {
            return {
              value: state.code,
              label: state.name,
            }
          })
          setStateList(states)
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error...', 'error')
        },
      },
    )
  }

  useEffect(() => {
    addPartnerDetails?.country?.value && GetStateListByCountryCode()
  }, [addPartnerDetails?.country?.value])

  return (
    <Drawer
      isActive={isActive}
      onOverlayClick={() => console.log('overlay Clicked')}
      onCloseClick={onCloseClick}
      title='Add New Partner'>
      <div className={AddPartnerClasses}>
        <div className='flex items-center w-full'>
          <Input
            className='pr-2'
            labelValue='Partner Name'
            isRequired
            type='text'
            value={addPartnerDetails.partnerName}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                partnerName: e.target.value,
              })
            }}
            isError={isErrorField.partnerName}
          />
          <Input
            className='pl-2'
            labelValue='Partner Code'
            isRequired
            type='text'
            value={addPartnerDetails.partnerCode}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                partnerCode: e.target.value,
              })
            }}
            isError={isErrorField.partnerCode}
          />
        </div>
        <div className='flex items-center w-full pt-5'>
          <Input
            className='pr-2'
            labelValue='Contact Person'
            isRequired
            type='text'
            value={addPartnerDetails.contactPerson}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                contactPerson: e.target.value,
              })
            }}
            isError={isErrorField.contactPerson}
          />
          <Input
            className='pl-2'
            labelValue='Contact Email'
            isRequired
            type='email'
            value={addPartnerDetails.contactEmail}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                contactEmail: e.target.value,
              })
            }}
            isError={isErrorField.contactEmail}
          />
        </div>
        <div className='flex items-center w-full pt-5'>
          <Input
            className='pr-2'
            labelValue='Support Email'
            isRequired
            type='email'
            value={addPartnerDetails.supportEmail}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                supportEmail: e.target.value,
              })
            }}
            isError={isErrorField.supportEmail}
          />
          <Input
            className='pl-2'
            labelValue='Phone No'
            type='text'
            value={addPartnerDetails.phoneNo}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                phoneNo: e.target.value,
              })
            }}
          />
        </div>
        <div className='flex items-center w-full pt-5'>
          <Input
            className=''
            labelValue='Street Address'
            type='text'
            value={addPartnerDetails.streetAddress}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                streetAddress: e.target.value,
              })
            }}
          />
        </div>
        <div className='flex items-center w-full pt-5'>
          <Input
            className='pr-2'
            labelValue='Suburb'
            type='text'
            value={addPartnerDetails.suburb}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                suburb: e.target.value,
              })
            }}
          />

          <div className='flex flex-col w-full pl-2'>
            <div className='flex'>
              <span className='pb-2 text-N-700'>State</span>
              <span className='text-R-500 ml-[2px]'>*</span>
            </div>

            <SimpleSelect
              value={addPartnerDetails.state}
              onChange={(e: any) => {
                setAddPartnerDetails({
                  ...addPartnerDetails,
                  state: e,
                })
              }}
              options={stateList}
              isError={isErrorField.state}
              placeholder='Select State'
            />
          </div>
        </div>
        <div className='flex items-center w-full pt-5'>
          <Input
            className='pr-2'
            labelValue='Postal Code'
            type='text'
            value={addPartnerDetails.postalCode}
            onChange={(e: any) => {
              setAddPartnerDetails({
                ...addPartnerDetails,
                postalCode: e.target.value,
              })
            }}
            placeholder='138576'
          />
          <div className='flex flex-col w-full pl-2'>
            <span className='pb-2 text-N-700'>Country</span>
            <SimpleSelect
              value={addPartnerDetails.country}
              onChange={(e: any) => {
                setAddPartnerDetails({
                  ...addPartnerDetails,
                  country: e,
                  state: '',
                })
              }}
              options={countryList}
            />
          </div>
        </div>
        <div className='flex items-start justify-start w-full pt-10'>
          <div className='flex flex-col w-full'>
            <span className='pb-2 text-N-700'>Status</span>
            <ToggleSwitch
              text={addPartnerDetails?.status ? 'Active' : 'Inactive'}
              setValue={addPartnerDetails?.status}
              onToggleClick={(e: any) => {
                setAddPartnerDetails({
                  ...addPartnerDetails,
                  status: e.target.checked,
                })
              }}
            />
          </div>
        </div>
      </div>
      <div className='drawer-footer flex items-center w-full h-[67px] sticky bottom-0 px-5 border-t border-N-200 shadow-sm'>
        <Button
          isLoading={createPartnerIsLoading}
          disabled={createPartnerIsLoading}
          onClick={() => {
            handleAddClick()
          }}>
          Save Partner
        </Button>
        <Button
          className='ml-5 w-[152px]'
          appearance='dull'
          onClick={onCloseClick}>
          Close
        </Button>
      </div>
    </Drawer>
  )
}

AddPartner.defaultProps = {
  className: undefined,
}

export default AddPartner
