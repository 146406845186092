import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetWarehouseTableInterface {
  take?: number
  skip?: number
  name?: string
  isActive?: any
}

export const useGetWarehouseTable = () => {
  const mutate = useMutation(
    ({ take, skip, name, isActive }: GetWarehouseTableInterface) => {
      return get(
        `/Warehouse?take=${take}&skip=${skip}&Name=${name}&isActive=${isActive}`,
      )
    },
  )

  return mutate
}

export default useGetWarehouseTable
