import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface UpdateIntegrationInterface {
  partnerId?: string
  wooCommerce?: []
  shopify?: []
  pushApi?: []
}

export const useUpdateIntegration = () => {
  const mutate = useMutation(
    ({
      partnerId,
      wooCommerce,
      shopify,
      pushApi,
    }: UpdateIntegrationInterface) => {
      return postAPI('/facade/UpsertIntegration', {
        partnerId: partnerId,
        wooCommerce: wooCommerce,
        shopify: shopify,
        pushAPI: pushApi,
      })
    },
  )

  return mutate
}

export default useUpdateIntegration
