import { FC } from 'react'
import BdlLogo from 'assets/images/logo.svg'
import BdlLogoMini from 'assets/images/logo-mini.svg'

export interface LogoProps {
  className?: string | undefined
  appearance?: 'default' | 'mini' | undefined
  onClick?: (e: any) => void | undefined
  [x: string]: any
}

export const Logo: FC<LogoProps> = ({ appearance }: LogoProps) => {
  return (
    <>
      {appearance === 'default' && (
        <div className='flex flex-col w-[166px]'>
          <img src={BdlLogo} alt='Logo' />
        </div>
      )}
      {appearance === 'mini' && (
        <div className='flex flex-col w-[43px]'>
          <img src={BdlLogoMini} alt='Logo' />
        </div>
      )}
    </>
  )
}

Logo.defaultProps = {
  className: undefined,
  appearance: 'default',
}

export default Logo
