/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react'
import CN from 'classnames'

export interface PopUpAlertProps {
  [x: string]: any
  cancelButtonText?: string | undefined
  className?: string | undefined
  confirmButtonText?: string | undefined
  customIcon?: 'warning' | 'delete' | 'logout' | 'package'
  isActive?: boolean | undefined
  isCustomStyles?: boolean | undefined
  message?: string | undefined
  onCancelClick?: () => void | undefined
  onConfirmClick?: () => void | undefined
  onOverlayClick?: () => void | undefined
  title?: string | undefined
  customStyleCN?: any | undefined
  iconStyle?: any | undefined
  iconBgStyle?: any | undefined
}

export const PopUpAlert: FC<PopUpAlertProps> = ({
  cancelButtonText,
  className,
  confirmButtonText,
  customIcon,
  isActive,
  isCustomStyles,
  message,
  onCancelClick,
  onConfirmClick,
  onOverlayClick,
  title,
  customStyleCN,
  iconStyle,
  iconBgStyle,
}: PopUpAlertProps) => {
  const handleOverlayClick = (event: any) => {
    if (onOverlayClick) {
      event.stopPropagation()
      onOverlayClick()
    }
  }

  const handleOnCancelClick = (event: any) => {
    if (onCancelClick) {
      event.stopPropagation()
      onCancelClick()
    }
  }

  const handleOnConfirmClick = (event: any) => {
    if (onConfirmClick) {
      event.stopPropagation()
      onConfirmClick()
    }
  }

  const disableOverlayClick = (event: any) => {
    event.stopPropagation()
  }

  const PopUpAlertClasses = !isCustomStyles
    ? CN(
        'PopUpAlertClasses fixed bg-black bg-opacity-50 w-full h-full z-40 top-0 right-0 bottom-0 left-0 flex items-center justify-center',
        className,
        {
          hidden: !isActive,
        },
      )
    : className

  return (
    <div
      className={PopUpAlertClasses}
      onClick={(e: any) => handleOverlayClick(e)}>
      <div
        className='flex w-[512px] h-[228px] bg-white rounded-md flex-col items-center p-6'
        onClick={(e: any) => disableOverlayClick(e)}>
        <div
          className={
            iconBgStyle
              ? iconBgStyle
              : 'h-12 w-12 rounded-full bg-R-100 flex items-center justify-center'
          }>
          <i
            className={CN(iconStyle ? iconStyle : 'text-R-500', {
              'ri-delete-bin-6-line': customIcon === 'delete',
              'ri-logout-circle-r-line': customIcon === 'logout',
              'ri-error-warning-line text-2xl': customIcon === 'warning',
              'ri-archive-fill text-2xl': customIcon === 'package',
            })}></i>
        </div>

        <h2 className='text-lg text-N-900 pt-5 font-semibold'>{title}</h2>
        <p className='text-sm text-N-500'>{message}</p>
        <div className='flex w-full pt-6 items-center justify-center'>
          {onCancelClick && (
            <button
              onClick={(e: any) => handleOnCancelClick(e)}
              className='rounded-md border border-N-200 w-[226px] h-8 hover:bg-N-50 active:bg-N-100 text-sm font-semibold'>
              {cancelButtonText}
            </button>
          )}
          {onConfirmClick && (
            <button
              onClick={(e: any) => handleOnConfirmClick(e)}
              className={CN(
                customStyleCN
                  ? customStyleCN
                  : 'rounded-md w-[226px] h-8 bg-R-500 hover:bg-R-600 active:bg-R-700 text-white text-sm font-semibold',
                {
                  'ml-3': onCancelClick,
                },
              )}>
              {confirmButtonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

PopUpAlert.defaultProps = {
  cancelButtonText: undefined,
  className: undefined,
  confirmButtonText: undefined,
  customIcon: 'delete',
  isActive: undefined,
  message: undefined,
  onCancelClick: undefined,
  onConfirmClick: undefined,
  onOverlayClick: undefined,
  title: undefined,
}

export default PopUpAlert
