import { dateToAEST } from 'utils'

export const columns = [
  {
    id: 'customerOrderNo',
    customWidth: 160,
    Header: () => 'Order #',
    accessor: 'partnerOrder.customerOrderNo',
    Cell: ({
      row: {
        original: { partnerOrder: data },
      },
    }) => <div className='line-clamp-2'>{data.customerOrderNo}</div>,
  },
  {
    customWidth: 128,
    Header: () => 'WMS Order #',
    accessor: 'orderNo',
    Cell: ({ row }) => <div>{row.original.orderNo}</div>,
  },
  {
    id: 'lineOne',
    customWidth: 376,
    Header: 'Delivery Address',
    accessor: 'partnerOrder.shippingAddress.lineOne',
    Cell: ({
      row: {
        original: {
          partnerOrder: { shippingAddress },
        },
      },
    }) => (
      <div className='line-clamp-2'>{`${shippingAddress?.lineOne}, ${shippingAddress?.city}, ${shippingAddress?.postCode}`}</div>
    ),
  },
  {
    id: 'suburb',
    customWidth: 212,
    Header: () => 'Suburb',
    accessor: 'partnerOrder.shippingAddress.suburb',
    Cell: ({
      row: {
        original: {
          partnerOrder: { shippingAddress },
        },
      },
    }) => <div>{shippingAddress.suburb}</div>,
  },
  {
    customWidth: 212,
    Header: () => 'Date Created',
    accessor: 'createdOn',
    Cell: ({ row }) => (
      <div className='line-clamp-2'>{dateToAEST(row.original.createdOn)}</div>
    ),
  },
]

export default columns
