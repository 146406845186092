/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useEffect, useState } from 'react'
import IconICS from 'assets/images/icon-ics.svg'
import IconPaperless from 'assets/images/paperless-icon.svg'
import CN from 'classnames'
import {
  useActivateWarehouseIntegration,
  useDeactivateWarehouseIntegration,
  useExtractLocation,
  useRegenerateKey,
} from 'framework/api/methods'

import { PopUpAlert } from 'components/atoms'
import { Button } from 'components/atoms/Button'
import { SimpleSelect } from 'components/atoms/Select'
import { ToggleSwitch } from 'components/atoms/ToggleSwitch'

export interface IntegrationPanelProps {
  // IntegrationData?: any
  [x: string]: any
}

export const WarehouseIntegrationPanel: FC<IntegrationPanelProps> = ({
  newIntegrations,
  setNewIntegrations,
  indexValue,
  setDeletePartnerIntegrationPopOutIsOpen,
  warehouseId,
  notify,
}: IntegrationPanelProps) => {
  const [integrationPanelData, setIntegrationPanelData] =
    useState(newIntegrations)
  const [showDetails, setShowDetails] = useState(true)

  useEffect(() => {
    setNewIntegrations(integrationPanelData)
  }, [integrationPanelData])

  useEffect(() => {
    setIntegrationPanelData(newIntegrations)
  }, [newIntegrations])

  const { mutate: activateWarehouseIntegrationMutate } =
    useActivateWarehouseIntegration()

  const ActivateIntegration = () => {
    /** Call Mutate with Created Objects */
    activateWarehouseIntegrationMutate(
      {
        warehouseId,
        integrationId: integrationPanelData?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  const { mutate: DeactivateWarehouseIntegrationMutate } =
    useDeactivateWarehouseIntegration()

  const DeactivateIntegration = () => {
    /** Call Mutate with Created Objects */
    DeactivateWarehouseIntegrationMutate(
      {
        warehouseId,
        integrationId: integrationPanelData?.id,
      },
      {
        onSuccess: ({ data: successData }: any) => {
          notify(successData.message, 'success')
        },
        onError: ({ response: { data: errData } }: any) => {
          notify(errData.message || 'Error', 'error')
        },
      },
    )
  }

  return (
    <>
      <div
        className={CN(
          'flex w-full bg-N-50 mt-4 items-center justify-between p-4 border-b border-N-200 rounded-t-lg',
          {
            'rounded-b-lg': !showDetails,
          },
        )}>
        <div className='flex items-center'>
          {integrationPanelData.name === 'IcsWMS' && (
            <img src={IconICS} alt='IconICS' />
          )}

          {integrationPanelData.name === 'PaperlessWMS' && (
            <img src={IconPaperless} alt='PaperlessWMS' />
          )}

          <p className='text-lg font-bold pl-2 pr-2'>
            {integrationPanelData.name === 'PaperlessWMS' && 'Paperless'}
            {integrationPanelData.name === 'IcsWMS' && 'ICS'}
          </p>

          <div className='bg-N-300 rounded-[100px]'>
            <p className='text-xs font-semibold px-[7px]'>
              {integrationPanelData.version}
            </p>
          </div>
        </div>
        <div className='flex items-center'>
          <ToggleSwitch
            text={integrationPanelData.isActive ? 'Active' : 'Deactive'}
            setValue={integrationPanelData.isActive}
            appearance='mini'
            isDisabled={integrationPanelData.type === 'new'}
            onToggleClick={(e: any) => {
              setIntegrationPanelData({
                ...integrationPanelData,
                isActive: e.target.checked,
              })

              if (integrationPanelData.type === 'existing') {
                e.target.checked
                  ? ActivateIntegration()
                  : DeactivateIntegration()
              }
            }}
          />
          <div className='flex items-center justify-center w-7 h-7 bg-R-50 ml-6 mr-7 cursor-pointer'>
            <i
              onClick={() => {
                setDeletePartnerIntegrationPopOutIsOpen(true)
                // setSelectedIndexOfIntegration(indexValue)
              }}
              className='ri-delete-bin-6-line text-R-500'></i>
          </div>
          <div
            className='flex items-center justify-center cursor-pointer'
            onClick={() => {
              setShowDetails(!showDetails)
            }}>
            <i
              className={CN('', {
                'ri-arrow-up-s-line': showDetails,
                'ri-arrow-down-s-line': !showDetails,
              })}></i>
          </div>
        </div>
      </div>
      {showDetails && (
        <div
          className={CN(
            'flex flex-col w-full bg-N-50 items-start justify-center p-4 border-b border-N-200 transition-height rounded-b-lg',
            {},
          )}>
          {integrationPanelData.baseUrl !== undefined && (
            <div className='flex flex-col w-full pt-6'>
              <span className='text-N-700'>Base URL</span>
              <input
                type='text'
                value={integrationPanelData.baseUrl}
                onChange={(e: any) => {
                  setIntegrationPanelData({
                    ...integrationPanelData,
                    baseUrl: e.target.value,
                  })
                }}
                className='py-[9px] px-4 w-full outline-none border border-N-200 rounded-md text-sm mt-2'
              />
            </div>
          )}

          {integrationPanelData.apiKey !== undefined &&
            newIntegrations?.name === 'IcsWMS' && (
              <div className='flex flex-col w-full pt-6'>
                <span className='text-N-700'>API Key</span>
                <div className='flex w-full'>
                  <input
                    value={integrationPanelData.apiKey}
                    onChange={(e: any) => {
                      setIntegrationPanelData({
                        ...integrationPanelData,
                        apiKey: e.target.value,
                      })
                    }}
                    className='py-2 px-4 w-full outline-none border border-N-200 rounded-md text-base mt-2'
                  />
                </div>
              </div>
            )}

          {integrationPanelData.token !== undefined &&
            newIntegrations?.name === 'PaperlessWMS' && (
              <div className='flex flex-col w-full pt-6'>
                <span className='text-N-700'>Token</span>
                <div className='flex w-full'>
                  <input
                    value={integrationPanelData.token}
                    onChange={(e: any) => {
                      setIntegrationPanelData({
                        ...integrationPanelData,
                        token: e.target.value,
                      })
                    }}
                    className='py-2 px-4 w-full outline-none border border-N-200 rounded-md text-base mt-2'
                  />
                </div>
              </div>
            )}
        </div>
      )}
    </>
  )
}

WarehouseIntegrationPanel.defaultProps = {
  IntegrationData: undefined,
}

export default WarehouseIntegrationPanel
