import { useMutation } from 'react-query'
import { postAPI } from 'framework/api/http'

interface StockOrderRetryInterface {
  stockOrderId?: string
}

export const useStockOrderRetry = () => {
  const mutate = useMutation(({ stockOrderId }: StockOrderRetryInterface) => {
    return postAPI(`/StockOrder/${stockOrderId}/RetryOrder`, {})
  })

  return mutate
}

export default useStockOrderRetry
