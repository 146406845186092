export const columns = [
  {
    customWidth: 140,
    Header: () => 'Product Code',
    accessor: 'warehouseSKU',
    Cell: ({ row }) => <div>{row.original.warehouseSKU}</div>,
  },
  {
    customWidth: 311,
    Header: () => 'Product Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  {
    customWidth: 63,
    Header: 'Qty1',
    accessor: 'availableQty',
    Cell: ({ row }) => <div>{row.original.availableQty}</div>,
  },
  {
    customWidth: 104,
    Header: () => 'Shipment Qty',
    accessor: 'ShipmentQty',
    isAction: true,
  },
]

export default columns
