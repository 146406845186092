/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query'
import { get } from 'framework/api/http'

interface GetPartnerListInterface {
  id?: number
}

export const useGetPartnerList = () => {
  const mutate = useMutation(({ id }: GetPartnerListInterface) => {
    return get(`/facade/dropdown/ListPartner`)
  })

  return mutate
}

export default useGetPartnerList
