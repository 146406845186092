import { useMutation } from 'react-query'
import { deleteAPI } from 'framework/api/http'

interface DeleteCutoffTimeInterface {
  cutoffTimeID?: string
}

export const useDeleteCutoffTime = () => {
  const mutate = useMutation(({ cutoffTimeID }: DeleteCutoffTimeInterface) => {
    return deleteAPI(`/CutoffTime/${cutoffTimeID}`)
  })

  return mutate
}

export default useDeleteCutoffTime
