export const columns = [
  {
    customWidth: 120,
    Header: () => '',
    accessor: 'action',
    isProductImage: true,
    Cell: () => true,
  },
  {
    customWidth: 218,
    Header: () => 'Role Name',
    accessor: 'name',
    Cell: ({ row }) => <div>{row.original.name}</div>,
  },
  // {
  //   customWidth: 268,
  //   Header: () => 'Role Description',
  //   accessor: 'description',
  //   Cell: ({ row }) => <div>N/A</div>,
  // },
]

export default columns
